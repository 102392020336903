import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'postcards',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      design: z.boolean(),
      print: z.boolean(),
      mailing: z.enum(['first', 'bulk']).optional(),
    }),
  },
  {
    name: 'Postcards',
    tenant: TenantId.BEYOND_RE_MARKETING,
    images: [],
    short: '',
  },
);
