import registerNep from '~/tenants/nep/register';
import registerMpx from '~/tenants/mpx/register';
import registerBre from '~/tenants/bre/register';
import registerDemo from '~/tenants/demo/register';
import registerTwt from '~/tenants/twt/register';
import registerMpi from '~/tenants/mpi/register';
import registerPic from '~/tenants/pic/register';

export function registerTenants() {
  registerBre();
  registerNep();
  registerMpx();
  registerDemo();
  registerTwt();
  registerMpi();
  registerPic();
}
