import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DEMO_PHOTOGRAPHY_OPTIONS } from '~/tenants/nep/performable/photos/NepPhotographyJob';
import { NepOrderContext, NepOrderType } from '~/tenants/nep/model/NepOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import NepPhotosTwilight from '~/tenants/nep/performable/photos/NepPhotosTwilight';
import { TenantPerformableAddOns, TenantPerformableConfiguration } from '~/tenants/common/performable';
import NepPhotosCommunity from '~/tenants/nep/performable/photos/NepPhotosCommunity';

export default function NepPhotographyForm({ context, persona }: TenantPerformableFormProps<NepOrderContext>) {
  const commercial = context.metadata.type === NepOrderType.COMMERCIAL;

  return (
    <>
      <TenantPerformableConfiguration>
        <FormHorizontal name="option" label="Photo Package">
          <ZodFieldSelect
            name="option"
            options={Object.entries(DEMO_PHOTOGRAPHY_OPTIONS).map(([id, pkg]) => ({
              value: id,
              label: `Up to ${pkg.photos} photos (${pkg.from}-${pkg.to} sqft)`,
              disabled: context.metadata.sqft > pkg.to || context.metadata.sqft < pkg.from,
            }))}
          />
        </FormHorizontal>
      </TenantPerformableConfiguration>

      <TenantPerformableAddOns persona={persona}>
        <NepPhotosTwilight commercial={commercial} />
        {!commercial && <NepPhotosCommunity />}
      </TenantPerformableAddOns>
    </>
  );
}
