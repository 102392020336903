import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { useRef } from 'react';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { NepOrderType } from '~/tenants/nep/model/NepOrder';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function NepOrderForm() {
  // TODO: better way to handle this
  const [_, type] = useZodFormFieldSingle('type');
  const preselected = useRef(type !== undefined);

  return (
    <FormGroup>
      {preselected.current && <ZodFieldHidden name="type" />}
      {!preselected.current && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              {
                label: 'Residential',
                value: NepOrderType.RESIDENTIAL,
              },
              { label: 'Commercial', value: NepOrderType.COMMERCIAL },
            ]}
          />
        </FormHorizontal>
      )}
      <FormHorizontal name="sqft" label="Square Feet">
        <ZodFieldInput name="sqft" type="number" autoFocus />
      </FormHorizontal>
      <FormHorizontal name="acres" label="Acres">
        <ZodFieldInput name="acres" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}
