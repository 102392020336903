import { TenantPackageAddOnGeneric, TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';
import { TwtPackageFloorplanInclude } from '~/tenants/twt/package/common';
import React from 'react';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { format_money } from '~/components/Money';
import { TWT_RENTAL_10, TWT_RENTAL_20 } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { PackageFormNested, usePackageFormContext } from '~/components/performable/PackageForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtPhotosNeighborhood from '~/tenants/twt/performable/photo/TwtPhotosNeighborhood';
import { PersonaType } from '~/tenants/common/TenantPersona';

export function TwtRentalIncludes({ aerial = false }: { aerial?: boolean }) {
  const context = usePackageFormContext();
  const { metadata } = context.orderContext as TwtOrderContext;

  return (
    <TenantPackageIncludes>
      <TenantPackageInclude name="20 Total Photos (Interior, Exterior & Vignettes)" image="tenant/twt/highlight/1.jpg">
        Highlight special features of your rental property such as barista bars and outdoor recreational spaces
      </TenantPackageInclude>
      <TwtPackageFloorplanInclude />
      {aerial && (
        <TenantPackageInclude name="Aerial Photos" image="tenant/twt/aerial/mini.jpg">
          A bird’s eye view to enhance the marketing of your property
        </TenantPackageInclude>
      )}
    </TenantPackageIncludes>
  );
}

export function TwtRentalPhotos() {
  return (
    <PackageFormNested performable={TwtPhotoConfig}>
      <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />
      <TenantPackageAddOnGeneric
        title="Extra Interior Photos"
        image="tenant/twt/highlight/2.jpg"
        persona={PersonaType.CUSTOMER}
        description="Add extra photos for a more detailed marketing of your short term rental."
      >
        <ZodFieldSelect
          options={[
            { label: 'No Extra Photos', value: '20' },
            { label: `10 Extra Photos: ${format_money(TWT_RENTAL_10)}`, value: '30' },
            { label: `20 Extra Photos: ${format_money(TWT_RENTAL_20)} `, value: '40' },
          ]}
          name="photos"
        />
      </TenantPackageAddOnGeneric>
      <TwtPhotosNeighborhood persona={PersonaType.CUSTOMER} />
    </PackageFormNested>
  );
}
