import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'zillow',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Zillow 3D',
    short: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/matterport/1.jpg',
    images: [],
  },
);
