import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceLiteralNumberOptional,
  coerceLiteralNumberRequired,
  FIRST_VERSION_TIMESTAMP,
  zodNativeEnum,
  zodTMoney,
} from '~/lib/zod';
import z from 'zod';

export enum MpxTwilightType {
  REAL = 'REAL',
  VIRTUAL = 'VIRTUAL',
}

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
        instructions: z.string().optional(),
        community: z.union([coerceLiteralNumberOptional(36), coerceLiteralNumberOptional(60)]),
        twilight: zodNativeEnum(MpxTwilightType, true),
        staging: z.coerce.number().optional(),
      })
      .and(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal('unit'),
            unit: z.string().min(1),
          }),
          z.object({
            type: z.literal('sqft'),
          }),
          z.object({
            type: z.literal('photos'),
            photos: z.union([
              coerceLiteralNumberRequired(10),
              coerceLiteralNumberRequired(20),
              coerceLiteralNumberRequired(24),
              coerceLiteralNumberRequired(36),
              coerceLiteralNumberRequired(48),
              coerceLiteralNumberRequired(60),
            ]),
          }),
          z.object({
            type: z.literal('custom'),
            revenue: zodTMoney().default('0'),
            expense: zodTMoney().default('0'),
          }),
          z.object({
            type: z.literal('exterior'),
          }),
        ]),
      ),
  },
  {
    name: 'Photography',
    short:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et nulla ornare, tempus erat non, consectetur nunc.',
    tenant: TenantId.METROPLEX_360,
    images: [],
  },
);
