import { FormHorizontal } from '~/components/form/layout';
import FormGroup from '~/components/form/FormGroup';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import TwtAerialAddOns from '~/tenants/twt/performable/aerial/TwtAerialAddOns';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext, TwtOrderType } from '~/tenants/twt/model/TwtOrder';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export default function TwtAerialForm({ persona, context }: TenantPerformableFormProps<TwtOrderContext>) {
  const options = [
    { label: 'Full Photos (10-15 Photos)', value: TwtAerialType.PHOTO_FULL },
    { label: 'Video + Photos (10-15 Photos)', value: TwtAerialType.PHOTO_VIDEO },
    { label: 'Video Only', value: TwtAerialType.VIDEO },
  ];

  if (context.metadata.type !== TwtOrderType.COMMERCIAL) {
    options.unshift({ label: 'Mini Photos (5 Photos)', value: TwtAerialType.PHOTO_MINI });
  }

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type">
        <ZodFieldSelect name="type" options={options} />
      </FormHorizontal>
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>
      <TenantPerformableAddOns persona={persona}>
        <TwtAerialAddOns persona={persona} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
