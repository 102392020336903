import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { Money } from '~/components/Money';
import ModalButton from '~/components/interactive/ModalButton';
import Card from '~/components/layout/Card';
import React, { useState } from 'react';
import { useZodFormFieldMultiple } from '~/components/zod/ZodForm';
import classNames from 'classnames';
import Button from '~/components/interactive/Button';
import CloseText from '~/components/interactive/CloseText';

function Image(props: { src: string; onClick?: () => void; ring?: boolean }) {
  const [_name, selected, setSelected] = useZodFormFieldMultiple('license');

  return (
    <div
      className={classNames('transition hover:scale-105 cursor-pointer rounded-theme overflow-hidden', {
        'ring-2 ring-theme-highlight': selected.includes(props.src) && props.ring,
        'opacity-70 hover:opacity-100': !selected.includes(props.src),
      })}
      onClick={props.onClick}
    >
      <LegacyResponsiveImage src={`grid/${props.src}`} round className="object-cover" />
    </div>
  );
}

const images: Record<string, Record<string, string[]>> = {
  '06357': {
    'Niantic Boardwalk + Downtown': [],
  },
  '02891': {
    'Main Street, Westerly, RI': [],
    'Watch Hill, RI': [],
    'Westerly Beaches': [],
    'Westerly Yacht Club': [],
  },
  '06320': {
    'Bank Street Downtown, New London': [],
    'Fort Trumbull New London, CT': [],
    'Ocean Beach + Alewife Cove New London': [],
    'US Coast Guard Academy': [],
  },
  '06340': {
    'Fort Griswold + Thames River': [],
    'Groton Long Point': [],
  },
  '06355': {
    'Mystic Seaport, CT': [],
    'Old Mystic Village': [],
  },
};

for (let i = 1; i <= 49; i += 1) {
  images['06357']['Niantic Boardwalk + Downtown'].push(
    `community/06357/Niantic Boardwalk + Downtown-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 16; i += 1) {
  images['02891']['Main Street, Westerly, RI'].push(
    `community/02891/Main Street, Westerly, RI-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 45; i += 1) {
  images['02891']['Watch Hill, RI'].push(`community/02891/Watch Hill, RI-${i.toString().padStart(3, '0')}.jpg`);
}

for (let i = 1; i <= 17; i += 1) {
  images['02891']['Westerly Beaches'].push(`community/02891/Westerly Beaches-${i.toString().padStart(3, '0')}.jpg`);
}

for (let i = 1; i <= 5; i += 1) {
  images['02891']['Westerly Yacht Club'].push(
    `community/02891/Westerly Yacht Club-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 93; i += 1) {
  images['06320']['Bank Street Downtown, New London'].push(
    `community/06320/Bank Street Downtown, New London-${i.toString().padStart(2, '0')}.jpg`,
  );
}

for (let i = 1; i <= 3; i += 1) {
  images['06320']['Fort Trumbull New London, CT'].push(
    `community/06320/Fort Trumbull New London, CT-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 19; i += 1) {
  images['06320']['Ocean Beach + Alewife Cove New London'].push(
    `community/06320/Ocean Beach + Alewife Cove New London-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 3; i += 1) {
  images['06320']['US Coast Guard Academy'].push(
    `community/06320/US Coast Guard Academy-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 27; i += 1) {
  if (i === 11) {
    continue;
  }

  images['06340']['Fort Griswold + Thames River'].push(
    `community/06340/Fort Griswold + Thames River-${i.toString().padStart(3, '0')}.jpg`,
  );
}

for (let i = 1; i <= 8; i += 1) {
  images['06340']['Groton Long Point'].push(`community/06340/Groton Long Point-${i.toString().padStart(3, '0')}.jpg`);
}

for (let i = 1; i <= 44; i += 1) {
  images['06355']['Mystic Seaport, CT'].push(`community/06355/Mystic Seaport, CT-${i.toString().padStart(3, '0')}.jpg`);
}

for (let i = 1; i <= 6; i += 1) {
  images['06355']['Old Mystic Village'].push(`community/06355/Old Mystic Village-${i.toString().padStart(3, '0')}.jpg`);
}

function Gallery() {
  const [preview, setPreview] = useState<string>();
  const [_name, selected, setSelected] = useZodFormFieldMultiple('license');
  const button = `${preview && selected.includes(preview) ? 'Remove' : 'Add'} Photo`;

  return (
    <div className="relative rounded-theme overflow-hidden">
      {preview && (
        <div className="p-inner space-y-3 flex flex-col items-center justify-between absolute w-full h-full z-100 bg-white overflow-hidden rounded-theme">
          <div className="flex-1 flex items-center w-full">
            <LegacyResponsiveImage src={`watermark/${preview}`} round className="max-h-80 w-full" />
          </div>
          <div className="flex w-full justify-between space-x-unrelated">
            <Button type="button" variant="outline" onClick={() => setPreview(undefined)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selected.includes(preview)) {
                  setSelected(selected.filter((s) => s != preview));
                } else {
                  setSelected([...selected, preview]);
                }

                setPreview(undefined);
              }}
            >
              {button}
            </Button>
          </div>
        </div>
      )}
      <div className="p-inner text-sm border-theme-separator border-b flex items-center justify-between space-x-related">
        <span>Scroll through the following images and click to preview and select.</span>
        <CloseText />
      </div>
      <div className="overflow-y-scroll h-96">
        {Object.entries(images).map(([postal, groups]) =>
          Object.entries(groups).map(([name, photos]) => (
            <>
              <div className="bg-gray-100 p-2 font-medium sticky top-0 z-50 border-b border-theme-separator">
                {postal} - {name}
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 p-inner">
                {photos.map((src) => (
                  <Image src={src} onClick={() => setPreview(src)} ring />
                ))}
              </div>
            </>
          )),
        )}
      </div>
    </div>
  );
}

function Selected() {
  const [name, selected, setSelected] = useZodFormFieldMultiple('license');

  if (selected.length === 0) {
    return <div className="italic text-gray-500">No photos selected</div>;
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 max-w-xs">
      {selected.map((src, index) => (
        <>
          <input type="hidden" name={`${name}[${index}]`} value={src} />
          <Image key={src} src={src} onClick={() => setSelected(selected.filter((s) => s != src))} />
        </>
      ))}
    </div>
  );
}

export function DemoPhotosCommunityLicense() {
  return (
    <div>
      <div className="flex items-center leading-none select-none">
        <label className="text-base font-medium cursor-pointer w-full">Licensed Community Photos</label>
        <p className="font-bold text-base">$10.00/photo</p>
      </div>
      <div className="pt-2 max-sm:pr-4">
        <p className="text-sm text-gray-600">
          A high resolution example of directed shots that showcase your desired, local sites.
        </p>
      </div>
      <div className="flex items-center justify-between pt-2">
        <Selected />
        <div>
          <ModalButton text="Browse" variant="outline">
            <Card flush>
              <Gallery />
            </Card>
          </ModalButton>
        </div>
      </div>
    </div>
  );
}

export function DemoPhotosCommunityCustom(props: { name?: string }) {
  return (
    <div className="border-theme-separator border-b pb-6">
      <div className="flex items-center leading-none select-none">
        <div className="w-8 flex-shrink-0">
          <ZodFieldCheckbox id="community" name="community" />
        </div>
        <label className="text-base font-medium cursor-pointer w-full" htmlFor="community">
          {props.name ?? 'Custom Community Photos'}
        </label>
        <p className="font-bold text-base">
          <Money>60</Money>
        </p>
      </div>
      <div className="pl-8 pt-2 max-sm:pr-4">
        <p className="text-sm text-gray-600">
          Looking for a special shot and don't see it in our licensed gallery? We can take a custom photo for you.
        </p>
      </div>
    </div>
  );
}

export default function DemoPhotosCommunity() {
  return (
    <div className="flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6">
      <div className="rounded-lg overflow-hidden flex-shrink-0">
        <LegacyResponsiveImage src="card/public/community.jpg" width={200} />
      </div>
      <div className="space-y-6">
        <DemoPhotosCommunityCustom />
        <DemoPhotosCommunityLicense />
      </div>
    </div>
  );
}
