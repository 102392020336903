import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { Pic_PHOTOGRAPHY_OPTIONS } from '~/tenants/pic/performable/photos/PicPhotographyJob';
import { PicOrderContext, PicOrderType } from '~/tenants/pic/model/PicOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import PicPhotosTwilight from '~/tenants/pic/performable/photos/PicPhotosTwilight';
import { TenantPerformableAddOns, TenantPerformableConfiguration } from '~/tenants/common/performable';
import PicPhotosCommunity from '~/tenants/pic/performable/photos/PicPhotosCommunity';

export default function PicPhotographyForm({ context, persona }: TenantPerformableFormProps<PicOrderContext>) {
  const commercial = context.metadata.type === PicOrderType.COMMERCIAL;

  return (
    <>
      <TenantPerformableConfiguration>
        <FormHorizontal name="option" label="Photo Package">
          <ZodFieldSelect
            name="option"
            options={Object.entries(Pic_PHOTOGRAPHY_OPTIONS).map(([id, pkg]) => ({
              value: id,
              label: `Up to ${pkg.photos} photos (${pkg.from}-${pkg.to} sqft)`,
              disabled:
                context.metadata.type !== PicOrderType.DRAFT &&
                (context.metadata.sqft > pkg.to || context.metadata.sqft < pkg.from),
            }))}
          />
        </FormHorizontal>
      </TenantPerformableConfiguration>

      <TenantPerformableAddOns persona={persona}>
        <PicPhotosTwilight commercial={commercial} />
        {!commercial && <PicPhotosCommunity />}
      </TenantPerformableAddOns>
    </>
  );
}
