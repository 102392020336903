import { usePackageFormContext } from '~/components/performable/PackageForm';
import { TenantPackageInclude } from '~/tenants/common/package';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import React from 'react';
import { MpxOrderContext } from '~/tenants/mpx/model/MpxOrder';

export function MpxPackagePhotoInclude() {
  const context = usePackageFormContext<MpxOrderContext>();
  const job = context.orderContext.jobs.find((j) => j.performable_id === 'photos');
  const photos = job?.performable_id === 'photos' ? job.metadata.photos : 0;

  //const name = `${photos} Listing Photos`;
  const name = 'Photography';

  return <TenantPackageInclude name={name}>{TwtPhotoConfig.short}</TenantPackageInclude>;
}
