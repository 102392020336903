import BreJob from '~/tenants/bre/model/BreJob';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';

export default class BreAerialJob extends BreJob<typeof BreAerialConfig> {
  get performable() {
    return BreAerialConfig;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreAerialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: 10,
    };
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
  }

  onsite(): number {
    let onsite = 0;

    if (this.metadata.photos) {
      onsite += this.metadata.photos <= 5 && this.metadata.tod !== 'twilight' ? 15 : 30;
    }

    if (this.metadata.video) {
      onsite += 30;
    }

    if (this.metadata.fpv) {
      onsite += this.metadata.fpv === 'outside' ? 30 : 60;
    }

    return onsite;
  }

  revenueLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: new Big(125),
      });
    }

    if (this.metadata.rush) {
      lines.push({
        id: 'rush',
        description: 'Rush Fee',
        amount: new Big(150),
      });
    }

    if (this.metadata.video) {
      lines.push({
        id: 'video',
        description: 'Video',
        amount: new Big(299),
      });
    }

    if (this.metadata.photos) {
      lines.push({
        id: 'photos',
        description: this.metadata.tod === 'twilight' ? 'Twilight Photos' : 'Photos',
        amount: new Big(this.metadata.photos <= 5 && this.metadata.tod !== 'twilight' ? 150 : 299),
      });
    }

    if (this.metadata.fpv) {
      if (this.metadata.fpv === 'outside') {
        lines.push({
          id: 'fpv',
          description: `First-Person View (Outside)`,
          amount: new Big(299),
        });
      } else {
        lines.push({
          id: 'fpv',
          description: `First-Person View (Inside + Outside)`,
          amount: new Big(599),
        });
      }
    }

    if (this.metadata.video && this.metadata.photos && this.metadata.photos > 5) {
      lines.push({
        id: 'combo',
        description: 'Aerial Combo Discount',
        amount: new Big(-98),
        discount: true,
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.photos && this.metadata.photos > 5 && this.metadata.video) {
      lines.push({
        id: 'combo',
        description: 'Photos + Video',
        amount: new Big(250),
      });
    } else if (this.metadata.photos) {
      lines.push({
        id: 'photos',
        description: this.metadata.tod === 'twilight' ? 'Twilight Photos' : 'Photos',
        amount: new Big(this.metadata.photos <= 5 && this.metadata.tod !== 'twilight' ? 98 : 194),
      });
    } else if (this.metadata.video) {
      lines.push({
        id: 'video',
        description: 'Video',
        amount: new Big(150),
      });
    }

    if (this.metadata.fpv) {
      if (this.metadata.fpv === 'outside') {
        lines.push({
          id: 'fpv',
          description: `First-Person View (Outside)`,
          amount: new Big(150),
        });
      } else {
        lines.push({
          id: 'fpv',
          description: `First-Person View (Inside + Outside)`,
          amount: new Big(300),
        });
      }
    }

    return lines;
  }
}
