import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BrePhotosConfig, { BrePhotosType } from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

interface BrePhotosTier {
  photos: number;
  revenue: number;
  expense: number;
  onsite: number;
}

const TIER_RENTAL: BrePhotosTier[] = [
  {
    photos: 8,
    revenue: 120,
    expense: 75,
    onsite: 30,
  },
  {
    photos: 15,
    revenue: 200,
    expense: 130,
    onsite: 45,
  },
];

const TIER_STANDARD: BrePhotosTier[] = [
  {
    photos: 15,
    revenue: 200,
    expense: 130,
    onsite: 45,
  },
  {
    photos: 25,
    revenue: 250,
    expense: 163,
    onsite: 45,
  },
  {
    photos: 35,
    revenue: 300,
    expense: 195,
    onsite: 60,
  },
  {
    photos: 45,
    revenue: 375,
    expense: 244,
    onsite: 75,
  },
];

export default class BrePhotosJob extends BreJob<typeof BrePhotosConfig> {
  get configurable(): boolean {
    return true;
  }

  onsite(): number {
    if (this.metadata.type === BrePhotosType.KEYWE) {
      return 15;
    }

    if (this.metadata.type === BrePhotosType.FRONT) {
      return 15;
    }

    if (this.metadata.type === BrePhotosType.EXTERIOR) {
      return 30;
    }

    if (this.metadata.type === BrePhotosType.STANDARD) {
      let tiers: BrePhotosTier[] = [];

      if (this.order.metadata.type === BreOrderType.LISTING) {
        tiers = TIER_STANDARD;
      }

      if (this.order.metadata.type === BreOrderType.RENTAL) {
        tiers = TIER_RENTAL;
      }

      for (const tier of tiers) {
        if (this.metadata.photos <= tier.photos) {
          return tier.onsite;
        }
      }
    }

    return 0;
  }

  expenseLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    let amount = new Big(0);
    let photos = 0;

    if (this.metadata.type === BrePhotosType.EXTERIOR) {
      amount = new Big(78);
      photos = 8;
    } else if (this.metadata.type === BrePhotosType.FRONT) {
      amount = new Big(50);
      photos = 3;
    } else {
      let tiers: BrePhotosTier[] = [];

      if (this.metadata.type === BrePhotosType.STANDARD) {
        if (this.order.metadata.type === BreOrderType.LISTING) {
          tiers = TIER_STANDARD;
        }

        if (this.order.metadata.type === BreOrderType.RENTAL) {
          tiers = TIER_RENTAL;
        }

        for (const tier of tiers) {
          if (this.metadata.photos <= tier.photos) {
            amount = new Big(tier.expense);
            photos = tier.photos;
          }
        }
      }
    }

    if (amount.gt(0)) {
      lines.push({
        amount,
        description: photos > 0 ? `Up to ${photos} Photos` : 'Photos',
        id: 'photos',
      });
    }

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        amount: new Big(20).mul(this.metadata.poi.length),
        description: `Points of Interest (${this.metadata.poi.length})`,
        id: 'poi',
      });
    }

    if (this.metadata.twilight) {
      lines.push({
        amount: new Big(195),
        description: 'Twilight',
        id: 'twilight',
      });
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    let amount = new Big(0);
    let photos = 0;

    if (this.metadata.type === BrePhotosType.EXTERIOR) {
      amount = new Big(120);
      photos = 8;
    } else if (this.metadata.type === BrePhotosType.FRONT) {
      amount = new Big(75);
      photos = 3;
    } else {
      let tiers: BrePhotosTier[] = [];

      if (this.metadata.type === BrePhotosType.STANDARD) {
        if (this.order.metadata.type === BreOrderType.LISTING) {
          tiers = TIER_STANDARD;
        }

        if (this.order.metadata.type === BreOrderType.RENTAL) {
          tiers = TIER_RENTAL;
        }

        for (const tier of tiers) {
          if (this.metadata.photos <= tier.photos) {
            amount = new Big(tier.revenue);
            photos = tier.photos;
          }
        }
      }
    }

    if (amount.gt(0)) {
      lines.push({
        amount,
        description: photos > 0 ? `Up to ${photos} Photos` : 'Photos',
        id: 'photos',
      });
    }

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        amount: new Big(25).mul(this.metadata.poi.length),
        description: `Points of Interest (${this.metadata.poi.length})`,
        id: 'poi',
      });
    }

    if (this.metadata.twilight) {
      lines.push({
        amount: new Big(300),
        description: 'Twilight',
        id: 'twilight',
      });
    }

    return lines;
  }

  get performable() {
    return BrePhotosConfig;
  }
}
