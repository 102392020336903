import { TenantPackageConfig } from '~/tenants/common/registry';
import DemoPhotographyConfig from '~/tenants/demo/performable/photos/DemoPhotographyConfig';
import DemoFloorplansConfig from '~/tenants/demo/performable/floorplans/DemoFloorplansConfig';
import Demo3dConfig from '~/tenants/demo/performable/3d/Demo3dConfig';
import DemoAerialConfig from '~/tenants/demo/performable/aerial/DemoAerialConfig';
import { DemoOrderContext, DemoOrderType } from '~/tenants/demo/model/DemoOrder';

const DemoPackagePremiumConfig: TenantPackageConfig = {
  id: 'premium',
  name: 'Premium',
  description:
    'Got a gem on your hands? Then this is the package for you! Our biggest savings package includes Photos, Floorplans, Aerial Photography and a 3D scan!.',
  performables: [DemoPhotographyConfig, DemoFloorplansConfig, Demo3dConfig, DemoAerialConfig],
  percentage: 0.1,
  eligible: (context) => (context as DemoOrderContext).metadata.type !== DemoOrderType.COMMERCIAL,
};

export default DemoPackagePremiumConfig;
