import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'matterport',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      floorplan: coerceBoolean().optional(),
      outdoor: z.union([z.literal('front'), z.literal('back'), z.literal('both')]).optional(),
    }),
  },
  {
    name: '3D Matterport',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '3D',
    images: [],
  },
);
