import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'google',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      miles: z.coerce.number().optional(),
    }),
  },
  {
    name: 'Google Street View',
    short:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et nulla ornare, tempus erat non, consectetur nunc.',
    tenant: TenantId.METROPLEX_360,
    images: [],
  },
);
