import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function BreAerialForm() {
  return <ZodFieldHidden name="photos" value="10" />;
}

/*
POI only if video


Our FAA approved aerial imaging offers unique and stunning overhead views of your listing.

Our Aerial photos are shot in High Resolution using multiple exposures that are then “layered” over each other to form an extremely natural-looking and detail-rich images.

We can add boundary lines, arrows or other symbols to show proximity to well known locations from your property (for an extra fee)
 */

/*
Property to be photographed or filmed will be "Photo Ready" upon Photographers arrival, otherwise there will be a Same Day cancellation or delay fee.

Same-Day Cancellation = $125 (Less than 24 Hour Notice) or $175 (Less Than 3 Hour Notice).
Delay Fee = $50.00 per every 15 minutes up to 30 minutes
 */
