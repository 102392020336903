import React from 'react';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TwtRentalIncludes, TwtRentalPhotos } from '~/tenants/twt/package/rental/common';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function TwtRentalFloorplanForm() {
  return (
    <>
      <TwtRentalIncludes />
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <TwtRentalPhotos />
      </TenantPerformableAddOns>
    </>
  );
}
