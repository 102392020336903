import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { GeocodedAddressSchema } from '~/lib/model';
import Bre3dConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreEditingConfig from '~/tenants/bre/performable/editing/BreEditingConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig, { BrePhotosType } from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePrintBookletConfig from '~/tenants/bre/performable/print/booklet/BrePrintBookletConfig';
import BrePrintBrochuresConfig from '~/tenants/bre/performable/print/brochure/BrePrintBrochuresConfig';
import BrePrintBusinessCardsConfig from '~/tenants/bre/performable/print/card/BrePrintBusinessCardsConfig';
import BrePrintEnvelopesConfig from '~/tenants/bre/performable/print/envelope/BrePrintEnvelopesConfig';
import BrePrintFlyersConfig from '~/tenants/bre/performable/print/flyer/BrePrintFlyersConfig';
import BrePrintLetterheadConfig from '~/tenants/bre/performable/print/letterhead/BrePrintLetterheadConfig';
import BrePrintPostcardsConfig from '~/tenants/bre/performable/print/postcard/BrePrintPostcardsConfig';
import BrePrintSignConfig from '~/tenants/bre/performable/print/sign/BrePrintSignConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreCustomConfig from '~/tenants/bre/performable/custom/BreCustomConfig';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';

export enum BreOrderType {
  PRINT = 'print',
  LISTING = 'listing',
  RENTAL = 'rental',
  COMMERCIAL = 'commercial',
}

export enum BreOrderAccess {
  AGENT = 'agent',
  SELLER = 'seller',
  COMBO = 'combo',
}

export const BreOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      onsite: z
        .object({
          address: GeocodedAddressSchema,
          gateCode: z.string().optional(),
          comboCode: z.string().optional(),
          comboLocation: z.string().optional(),
          excludeWeekendFees: z.boolean().optional(),
          excludeTravelFees: z.boolean().optional(),
          accessToProperty: z.enum([BreOrderAccess.AGENT, BreOrderAccess.COMBO, BreOrderAccess.SELLER]).optional(),
        })
        .optional(),
      comments: z.string().optional(),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({
          type: z.literal(BreOrderType.COMMERCIAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(BreOrderType.LISTING),
          sqft: z.coerce.number(),
          beds: z.coerce.number().min(1),
          baths: z.coerce.number().min(1),
          built: z.coerce.number().optional(),
          colistNumber: z.string().optional(),
          colistEmail: z.string().optional(),
          colistPhone: z.string().optional(),
        }),
        z.object({
          type: z.literal(BreOrderType.PRINT),
        }),
        z.object({
          type: z.literal(BreOrderType.RENTAL),
          sqft: z.coerce.number(),
        }),
      ]),
    ),
};
export type BreOrderMetadata = ZodVersionedMetadata<typeof BreOrderSchema>;
export type BrePerformableConfig =
  | typeof Bre3dConfig
  | typeof BreAerialConfig
  | typeof BreCinematicConfig
  | typeof BreEditingConfig
  | typeof BreFloorplanConfig
  | typeof BrePhotosConfig
  | typeof BrePrintBookletConfig
  | typeof BrePrintBrochuresConfig
  | typeof BrePrintBusinessCardsConfig
  | typeof BrePrintEnvelopesConfig
  | typeof BrePrintFlyersConfig
  | typeof BrePrintLetterheadConfig
  | typeof BrePrintPostcardsConfig
  | typeof BrePrintSignConfig
  | typeof BrePortraitsConfig
  | typeof BreSocialConfig
  | typeof BreCustomConfig
  | typeof BreWebsiteConfig;

export interface BreOrderContext extends TenantOrderContext<BreOrderMetadata, BrePerformableConfig> {}
