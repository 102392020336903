import MpxJob from '~/tenants/mpx/model/MpxJob';
import { TenantJobLine } from '~/lib/model';
import MpxFloorplanConfig from '~/tenants/mpx/performable/floorplan/MpxFloorplanConfig';
import Big from 'big.js';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxFloorplanJob extends MpxJob<typeof MpxFloorplanConfig> {
  get performable() {
    return MpxFloorplanConfig;
  }

  onsite(): number {
    return 30;
  }

  get configurable(): boolean {
    return this.order.metadata.type !== MpxOrderType.ARCHITECTURAL;
  }

  get eligible(): boolean {
    return [MpxOrderType.MULTI_FAMILY, MpxOrderType.ARCHITECTURAL].includes(this.order.metadata.type);
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxFloorplanConfig)['schema']> {
    return { version: FIRST_VERSION_TIMESTAMP, type: '2D' };
  }

  revenueLines() {
    const lines: TenantJobLine[] = [];

    if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      lines.push({
        description: `${this.metadata.type} Floorplan`,
        amount: new Big(this.metadata.type === '2D' ? 45 : 85),
        taxable: true,
        id: 'floorplan',
      });
    } else if ('sqft' in this.order.metadata) {
      lines.push({
        description: 'Floorplan',
        amount: new Big(this.order.metadata.sqft < 10000 ? 349 : 749),
        taxable: true,
        id: 'floorplan',
      });
    }

    return lines;
  }
}
