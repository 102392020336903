import BreJob from '~/tenants/bre/model/BreJob';
import BrePrintPostcardsConfig from '~/tenants/bre/performable/print/postcard/BrePrintPostcardsConfig';

export default class BrePrintPostcardsJob extends BreJob<typeof BrePrintPostcardsConfig> {
  get performable() {
    return BrePrintPostcardsConfig;
  }

  isDelivery(): boolean {
    return !this.metadata.mailing;
  }
}
