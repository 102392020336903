import { TwtSimpleJob } from '~/tenants/twt/performable/TwtSimpleJob';
import { TenantJobLine, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import TwtZillowConfig from '~/tenants/twt/performable/zillow/TwtZillowConfig';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default class TwtZillowJob extends TwtSimpleJob<typeof TwtZillowConfig> {
  protected get config() {
    return {
      onsiteMinimum: 30,
      onsitePer1000: 10,
      expenseMinimum: 50,
      expensePer1000: 30,
    };
  }

  get performable() {
    return TwtZillowConfig;
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtZillowConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  get hidden() {
    return true;
  }

  get review() {
    return true;
  }

  get eligible(): boolean {
    return (
      this.order.metadata.type === TwtOrderType.FOR_SALE_LISTING && this.order.metadata.subtype !== TwtPropertyType.LAND
    );
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  protected revenueMinimum() {
    return this.orderHasPhotos ? 160 : 60;
  }

  protected revenuePer(thousands: number) {
    return (this.orderHasPhotos ? 0 : 75) * thousands;
  }

  expenseLines(): TenantJobLine[] {
    return [
      {
        amount: this.simpleExpense(),
        description: this.orderHasPhotos ? 'Zillow Showcase' : 'Zillow 3D Scan',
        id: 'scan',
      },
    ];
  }

  revenueLines(): TenantOrderLine[] {
    return [
      {
        amount: this.simpleRevenue().amount ?? new Big(0),
        taxable: true,
        description: this.orderHasPhotos ? 'Zillow Showcase' : 'Zillow 3D Scan',
        id: 'scan',
      },
    ];
  }
}
