import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';
import { MpiOrderType } from '~/tenants/mpi/model/MpiOrder';

export default function MpiOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard value={MpiOrderType.RESIDENTIAL} image="tenant/twt/residential/1.jpg" title="Residential">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pharetra diam vel neque varius vestibulum. Sed
        orci odio, malesuada ac nisl sit amet, vehicula porta justo.
      </OrderWizardTypeCard>
    </div>
  );
}
