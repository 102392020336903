import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import TwtSlideshowConfig from '~/tenants/twt/performable/slideshow/TwtSlideshowConfig';
import { DeliverableType } from '~common/model/Deliverable';

export class TwtSlideshowJob extends TwtJob<typeof TwtSlideshowConfig> {
  get performable() {
    return TwtSlideshowConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SLIDESHOW;
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtSlideshowConfig)['schema']> {
    return {
      version: schema_latest_version(TwtSlideshowConfig.schema),
    };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big(35),
      taxable: true,
      description: 'Video Slideshow',
      id: 'slideshow',
      discountable: true,
    });

    return lines;
  }
}
