import React, { useState } from 'react';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { Money } from '~/components/Money';
import {
  PicPhotographyRealTwilightPrice,
  PicPhotographyTwilightCommercialTime,
  PicPhotographyVirtualTwilightPrice,
} from '~/tenants/pic/performable/photos/PicPhotographyConfig';
import InputCheckbox from '~/components/input/InputCheckbox';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function PicPhotosTwilight(props: { commercial?: boolean }) {
  const [_virtualName, virtualValue, setVirtualValue] = useZodFormFieldSingle('virtual_twilight');
  const [showVirtual, setShowVirtual] = useState(virtualValue ? parseInt(virtualValue) > 0 : false);
  const [_realName, realValue, setRealValue] = useZodFormFieldSingle('real_twilight');

  return (
    <div className="flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6">
      <div className="rounded-lg overflow-hidden flex-shrink-0">
        <LegacyResponsiveImage src="card/public/twilight.jpg" width={200} />
      </div>
      <div className="space-y-4">
        <div>
          <div className="flex items-center leading-none select-none">
            <div className="w-8 flex-shrink-0">
              <ZodFieldCheckbox id="real_twilight" name="real_twilight" disabled={showVirtual} />
            </div>
            <label className="text-base font-medium cursor-pointer w-full" htmlFor="real_twilight">
              Authentic Twilight
            </label>
            <p className="font-bold text-base whitespace-nowrap">
              {props.commercial ? (
                <>+{PicPhotographyTwilightCommercialTime} Minutes</>
              ) : (
                <>
                  <Money>{PicPhotographyRealTwilightPrice}</Money>
                </>
              )}
            </p>
          </div>
          <div className="pl-8 pt-2 max-sm:pr-4">
            <p className="text-sm text-gray-600">
              <span className="font-medium">Up to 15 photos</span> are taken at dusk and use the natural light of the
              sunset to create a beautiful photo.
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center leading-none select-none">
            <div className="w-8 flex-shrink-0">
              <InputCheckbox
                id="show_virtual"
                checked={showVirtual && realValue !== 'true'}
                onChange={(show) => {
                  setRealValue('false');
                  setShowVirtual(show);

                  if (!show) {
                    setVirtualValue('');
                  }
                }}
                disabled={realValue === 'true'}
              />
            </div>
            <label className="text-base font-medium cursor-pointer w-full" htmlFor="show_virtual">
              Virtual Twilight
            </label>
            <p className="font-bold text-base">
              <Money>{PicPhotographyVirtualTwilightPrice}</Money>/photo
            </p>
          </div>
          <div className="pl-8 pt-2 space-y-4 max-sm:pr-4">
            <p className="text-sm text-gray-600">
              Photos are created by digitally enhancing a daytime photo to look like it was taken at dusk.
            </p>
            {showVirtual ? (
              <FormHorizontal name="virtual_twilight" label="# of Photos">
                <ZodFieldInput name="virtual_twilight" type="number" />
              </FormHorizontal>
            ) : (
              <ZodFieldHidden name="virtual_twilight" value="0" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
