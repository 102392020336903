import { TenantPackageConfig } from '~/tenants/common/registry';
import DemoPhotographyConfig from '~/tenants/demo/performable/photos/DemoPhotographyConfig';
import DemoFloorplansConfig from '~/tenants/demo/performable/floorplans/DemoFloorplansConfig';
import Demo3dConfig from '~/tenants/demo/performable/3d/Demo3dConfig';
import { DemoOrderContext, DemoOrderType } from '~/tenants/demo/model/DemoOrder';

const DemoPackageStandardConfig: TenantPackageConfig = {
  id: 'standard',
  name: 'Standard',
  description: 'Same as our basic package, but includes a whole house 3D scan (interior only).',
  performables: [DemoPhotographyConfig, DemoFloorplansConfig, Demo3dConfig],
  percentage: 0.07,
  eligible: (context) => (context as DemoOrderContext).metadata.type !== DemoOrderType.COMMERCIAL,
};

export default DemoPackageStandardConfig;
