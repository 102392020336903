import MpxAerialConfig from '~/tenants/mpx/performable/aerial/MpxAerialConfig';
import MpxJob from '~/tenants/mpx/model/MpxJob';
import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import MpxOrder, { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxAerialJob extends MpxJob<typeof MpxAerialConfig> {
  get performable() {
    return MpxAerialConfig;
  }

  onsite(): number {
    return 30;
  }

  get configurable(): boolean {
    return this.order.metadata.type !== MpxOrderType.COMMERCIAL;
  }

  get eligible(): boolean {
    if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      return this.order.jobs.some((j) => j.performable_id === 'photos');
    }

    return [MpxOrderType.MULTI_FAMILY, MpxOrderType.COMMERCIAL, MpxOrderType.BUILDERS].includes(
      this.order.metadata.type,
    );
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxAerialConfig)['schema']> {
    if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      return { version: FIRST_VERSION_TIMESTAMP, photos: 12 };
    }

    if ([MpxOrderType.BUILDERS, MpxOrderType.COMMERCIAL].includes(this.order.metadata.type)) {
      return { version: FIRST_VERSION_TIMESTAMP, photos: 10 };
    }

    return { version: FIRST_VERSION_TIMESTAMP, photos: 4 };
  }

  expenseLines(): TenantJobLine[] {
    const lines = super.expenseLines();

    const addon = this.order.jobs.some((j) => j.performable_id === 'matterport');

    let total = new Big(0);

    if (this.metadata.video) {
      total = total.plus(addon ? 95 : 125);
    }

    if (this.metadata.photos === 12) {
      total = total.plus(addon ? 75 : 100);
    } else {
      total = total.plus(addon ? 95 : 125);
    }

    let description = 'Aerial';

    if (this.metadata.photos && this.metadata.video) {
      description += ' Photos + Video';
    } else if (this.metadata.photos) {
      description += ' Photos';
    } else if (this.metadata.video) {
      description += ' Video';
    }

    lines.push({
      id: 'total',
      description,
      amount: total,
    });

    return lines;
  }

  revenueLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.tour && this.order.jobs.some((j) => j.performable_id === 'matterport')) {
      lines.push({
        id: 'tour',
        description: 'Drone/360 Tour',
        amount: new Big(195),
        taxable: true,
      });
    }

    if (this.order.metadata.type === MpxOrderType.BUILDERS) {
      if (this.metadata.photos) {
        lines.push({
          id: 'photos',
          description: 'Aerial Photography',
          amount: new Big(195),
          taxable: true,
        });
      }

      if (this.metadata.video) {
        let amount = new Big(995);
        let description = 'Aerial Video';

        if (this.metadata.community) {
          amount = new Big(1495);
          description = 'Aerial/Ground Video';
        }

        lines.push({
          id: 'video',
          description,
          amount,
          taxable: true,
        });
      }
    } else if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
      if (this.metadata.photos) {
        lines.push({
          id: 'photos',
          description: 'Aerial Photography',
          amount: new Big(350),
          taxable: true,
        });
      }
    } else if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      if (this.metadata.photos) {
        lines.push({
          id: 'photos',
          description: 'Aerial Photography',
          amount: new Big(this.metadata.photos === 12 ? 249 : 350),
          taxable: true,
        });
      }

      if (this.metadata.video) {
        let amount = new Big(999);
        let max = 60;
        let description = 'Aerial Video';

        if (this.metadata.community) {
          max = 90;
          amount = new Big(1499);
          description = 'Community Video';
        }

        if (this.metadata.video > max) {
          const overage = Math.ceil(this.metadata.video - max) / 20;

          amount = amount.plus(new Big(overage).times(100));
        }

        lines.push({
          id: 'video',
          description,
          amount,
          taxable: true,
        });
      }
    } else if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      if (this.metadata.photos) {
        lines.push({
          id: 'photos',
          description: 'Aerial Photography',
          amount: new Big(this.metadata.photos === 4 ? 135 : 165),
          taxable: true,
        });
      }

      if (this.metadata.video) {
        lines.push({
          id: 'video',
          description: 'Aerial Video',
          amount: new Big(349),
          taxable: true,
        });
      }
    }

    if (this.order.buyer.aerial_discount_flat) {
      lines.push({
        id: 'discount',
        description: 'Discount',
        discount: true,
        taxable: true,
        amount: new Big(this.order.buyer.aerial_discount_flat).times('-1'),
      });
    }

    return lines;
  }
}
