import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import FormGroup from '~/components/form/FormGroup';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { useZodFormFieldArray, useZodFormFieldSingle, ZodNestedForm } from '~/components/zod/ZodForm';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import Button from '~/components/interactive/Button';
import { v4 } from 'uuid';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { TenantOrderFormProps } from '~/tenants/common/form';
import { PersonaType } from '~/tenants/common/TenantPersona';

function MultiFamily() {
  const [units, add] = useZodFormFieldArray('units');

  return (
    <>
      <Button
        onClick={() => {
          add({ id: v4() });
        }}
      >
        Add Unit
      </Button>
      {units.map(({ name, remove }) => (
        <ZodNestedForm name={name} key={name}>
          <ZodFieldHidden name="id" />
          <FormHorizontal name="number" label="Unit # or Area Name">
            <ZodFieldInput name="number" />
          </FormHorizontal>
          <FormHorizontal name="beds" label="Bedrooms">
            <ZodFieldInput name="beds" />
          </FormHorizontal>
          <FormHorizontal name="sqft" label="Square Feet">
            <ZodFieldInput name="sqft" />
          </FormHorizontal>
          <Button onClick={remove}>Remove</Button>
        </ZodNestedForm>
      ))}
    </>
  );
}

export default function MpxOrderForm(props: TenantOrderFormProps) {
  const [_name, type] = useZodFormFieldSingle<MpxOrderType>('type');

  return (
    <FormGroup>
      {props.create && props.persona === PersonaType.OFFICE && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              { value: MpxOrderType.RESIDENTIAL, label: 'Residential Real-Estate / Realtors' },
              { value: MpxOrderType.MULTI_FAMILY, label: 'Multi-Family Apartment Management' },
              { value: MpxOrderType.BUILDERS, label: 'Home Builders & Developers' },
              { value: MpxOrderType.COMMERCIAL, label: 'Retail & Commercial Real-Estate' },
              { value: MpxOrderType.ARCHITECTURAL, label: 'Architects / CAD Professionals' },
              { value: MpxOrderType.INSURANCE, label: 'Insurance / XActimate' },
              { value: MpxOrderType.CUSTOM, label: 'Custom' },
            ]}
          />
        </FormHorizontal>
      )}
      {type &&
      [
        MpxOrderType.COMMERCIAL,
        MpxOrderType.BUILDERS,
        MpxOrderType.RESIDENTIAL,
        MpxOrderType.ARCHITECTURAL,
        MpxOrderType.INSURANCE,
      ].includes(type) ? (
        <FormHorizontal name="sqft" label="Square Feet">
          <ZodFieldInput name="sqft" />
        </FormHorizontal>
      ) : (
        <ZodFieldHidden name="sqft" value="" />
      )}
      {type === MpxOrderType.MULTI_FAMILY && <MultiFamily />}
    </FormGroup>
  );
}
