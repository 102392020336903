import { TenantPackageConfig } from '~/tenants/common/registry';
import NepPhotographyConfig from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import { NepOrderContext, NepOrderType } from '~/tenants/nep/model/NepOrder';

const NepPackageBasicConfig: TenantPackageConfig = {
  id: 'basic',
  name: 'Basic',
  description:
    'A great option for if you want a little more firepower than just Listing Photos! Includes floor plans and Listing Photos. Photo count depends on the square footage of the home. ',
  performables: [NepPhotographyConfig, NepFloorplansConfig],
  percentage: 0.05,
  eligible: (context) => (context as NepOrderContext).metadata.type !== NepOrderType.COMMERCIAL,
};

export default NepPackageBasicConfig;
