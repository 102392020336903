import { NepProviderId } from '~/tenants/nep/model/NepProvider';
import { ProviderStaticConfig } from '~/lib/model';

export const NEP_PROVIDERS: Record<NepProviderId, ProviderStaticConfig> = {
  [NepProviderId.SOPHIA]: {
    email: 'sophia@nephoto.com',
    first: 'Sophia',
    headshot: 'avatar.jpg',
    phone: '+15159749848',
    last: 'Bednarik',
    location: 'nl',
    start_address: {
      line1: "125 Eugene O'Neill Drive",
      line2: null,
      city: 'New London',
      state: 'CT',
      zip: '06320',
      long: -72.09381669999999,
      lat: 41.3539585,
    },
  },
};
