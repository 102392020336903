import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { MpiOrderType } from '~/tenants/mpi/model/MpiOrder';
import { useRef } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

export default function BreOrderForm() {
  const [_type, type] = useZodFormFieldSingle<MpiOrderType>('type');
  const preselected = useRef(type !== undefined);

  return (
    <FormGroup>
      {!preselected.current && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              {
                label: 'Listing',
                value: BreOrderType.LISTING,
              },
            ]}
          />
        </FormHorizontal>
      )}
      <FormHorizontal name="sqft" label="Square Feet">
        <ZodFieldInput name="sqft" type="number" />
      </FormHorizontal>
      <FormHorizontal name="beds" label="Beds">
        <ZodFieldInput name="beds" type="number" />
      </FormHorizontal>
      <FormHorizontal name="baths" label="Baths">
        <ZodFieldInput name="baths" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}
