import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export default function TwtOrderPayment() {
  return (
    <div className="space-y-related mb-unrelated">
      <h1 className="text-3xl font-bold text-gray-900">Special Instructions (optional)</h1>

      <ZodFieldTextarea name="special" placeholder="Any additional notes or details you want to share?" />
    </div>
  );
}
