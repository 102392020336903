import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'staging',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.coerce.number().min(1),
    }),
  },
  {
    name: 'Virtual Staging',
    thumbnail: 'card/public/staging.jpg',
    short: 'Digitally-rendered furnishings to help enhance your vacant property space.',
    tenant: TenantId.NE_PHOTO,
    images: [],
    group: 'Digital Media',
  },
);
