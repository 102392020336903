import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      rush: coerceBoolean().optional(),
      photos: z.coerce.number().optional(),
      video: coerceBoolean().optional(),
      tod: z.union([z.literal('sunlight'), z.literal('twilight')]).optional(),
      fpv: z.union([z.literal('outside'), z.literal('both')]).optional(),
      poi: z.array(z.object({ name: z.string(), location: z.string() })).optional(),
    }),
  },
  {
    name: 'Aerial',
    images: [],
    short: '',
    tenant: TenantId.BEYOND_RE_MARKETING,
  },
);
