import { LoaderFunctionArgs } from '@remix-run/cloudflare';
import { remix_schema_post } from '~/lib/request';
import { domainr_available } from '~/lib/vendor/domainr';
import * as v from 'valibot';

export const SCHEMA_API_DOMAIN_AVAILABLE = v.object({
  domain: v.pipe(
    v.string('Please enter a domain name.'),
    v.endsWith('.com', 'Only domains that end in .com are supported.'),
  ),
});

export interface ApiDomainAvailableResponse {
  available: boolean;
  domain: string;
}

export async function action(args: LoaderFunctionArgs): Promise<ApiDomainAvailableResponse> {
  const data = await remix_schema_post(args, SCHEMA_API_DOMAIN_AVAILABLE);

  let available = await domainr_available(data.domain);

  return {
    domain: data.domain,
    available,
  };
}
