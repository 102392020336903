import z from 'zod';
import { TenantCustomer } from '~/tenants/common/TenantPersona';

export const MpxCustomerSchema = z.object({
  // PDF instructions for jobs which are shared with the provider
  photo_pdf: z.string().optional(),
  aerial_pdf: z.string().optional(),
  matterport_pdf: z.string().optional(),

  // instructions for jobs which are shared with the provider
  photo_notes: z.string().optional(),
  aerial_notes: z.string().optional(),
  matterport_notes: z.string().optional(),

  aerial_discount_flat: z.number().optional(),

  matterport_expense_minimum: z.number().optional(),
  matterport_sqft: z.number().optional(),
  matterport_upload: z.string().optional(),
  matterport_pro2_expense: z.number().optional(),

  cancel_fee_flat: z.number().optional(),
  cancel_fee_24: z.number().optional(),

  builder: z.boolean().optional(),

  community_combo_discount_flat: z.number().optional(),
});

export type MpxCustomerData = z.output<typeof MpxCustomerSchema>;

export default class MpxCustomer extends TenantCustomer<MpxCustomerData> {}
