import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DEMO_FLOORPLANS_OPTIONS } from '~/tenants/nep/performable/floorplans/NepFloorplansJob';
import { NepOrderContext, NepOrderType } from '~/tenants/nep/model/NepOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';

export default function NepFloorplansForm({ context }: TenantPerformableFormProps<NepOrderContext>) {
  return (
    <FormHorizontal name="option" label="Option">
      <ZodFieldSelect
        name="option"
        options={Object.entries(DEMO_FLOORPLANS_OPTIONS).map(([id, pkg]) => ({
          value: id,
          label: `(${pkg.from}-${pkg.to} sqft)`,
          disabled:
            context.metadata.type === NepOrderType.RESIDENTIAL &&
            (context.metadata.sqft > pkg.to || context.metadata.sqft < pkg.from),
        }))}
      />
    </FormHorizontal>
  );
}
