import React from 'react';
import {
  NepPackage3dAddOns,
  NepPackage3dInclude,
  NepPackageAerialAddOns,
  NepPackageAerialInclude,
  NepPackageCommunityAddOn,
  NepPackageFloorplanAddOns,
  NepPackageFloorplanInclude,
  NepPackagePhotoAddOns,
  NepPackagePhotoInclude,
} from '~/tenants/nep/package/common';
import { TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function NepPackagePremiumForm() {
  return (
    <>
      <TenantPackageIncludes>
        <NepPackagePhotoInclude />
        <NepPackageFloorplanInclude />
        <NepPackage3dInclude />
        <NepPackageAerialInclude />
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <NepPackagePhotoAddOns />
        <NepPackageFloorplanAddOns />
        <NepPackage3dAddOns />
        <NepPackageAerialAddOns />
        <NepPackageCommunityAddOn aerial />
      </TenantPerformableAddOns>
    </>
  );
}
