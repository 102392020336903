import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum MpiPhotosType {
  STANDARD = 'standard',
  EXTERIOR = 'exterior',
  PARTIAL = 'partial',
}

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      })
      .and(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(MpiPhotosType.STANDARD),
          }),
          z.object({
            type: z.literal(MpiPhotosType.EXTERIOR),
          }),
          z.object({
            type: z.literal(MpiPhotosType.PARTIAL),
          }),
        ]),
      ),
  },
  {
    name: 'Photography',
    images: [],
    short:
      'Our most popular service! Includes 30-34 photos, delivered in 24 hours or less! Includes labor, editing and licensing fees.',
    tenant: TenantId.MPI,
  },
);
