import BreJob from '~/tenants/bre/model/BreJob';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';

export default class BreCinematicJob extends BreJob<typeof BreCinematicConfig> {
  get performable() {
    return BreCinematicConfig;
  }

  onsite(): number {
    let onsite = 0;

    return onsite;
  }

  revenueLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.narration) {
      let amount = new Big(this.metadata.narration === 90 ? 125 : 250);

      if (this.metadata.narration === 90) {
        lines.push({
          id: 'narration',
          description: `Voice Narration (${this.metadata.narration} seconds)`,
          amount,
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.narration) {
      let amount = new Big(this.metadata.narration === 90 ? 75 : 175);

      if (this.metadata.narration === 90) {
        lines.push({
          id: 'narration',
          description: `Voice Narration (${this.metadata.narration} seconds)`,
          amount,
        });
      }
    }

    return lines;
  }
}
