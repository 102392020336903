import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { MpxOrderContext, MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';

export default function MpxFloorplanForm({ context }: TenantPerformableFormProps<MpxOrderContext>) {
  if (context.metadata.type === MpxOrderType.ARCHITECTURAL) {
    return <ZodFieldHidden name="type" value="2D" />;
  }

  return (
    <FormHorizontal label="Type">
      <ZodFieldSelect
        name="type"
        options={[
          { label: '2D', value: '2D' },
          { label: '3D', value: '3D' },
        ]}
      />
    </FormHorizontal>
  );
}
