import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { PicOrderContext } from '~/tenants/pic/model/PicOrder';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';

export default function PicVirtualStagingForm(_props: TenantPerformableFormProps<PicOrderContext>) {
  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldInput name="photos" type="number" />
      </FormHorizontal>
    </TenantPerformableConfiguration>
  );
}
