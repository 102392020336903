import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export enum PicAerialPhotographyOptionId {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      option: z.nativeEnum(PicAerialPhotographyOptionId),
      community: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Aerial Photography',
    tenant: TenantId.NE_PHOTO,
    thumbnail: 'tenant/twt/aerial/mini.jpg',
    short: 'High resolution aerial imagery to showcase your property within FAA guidelines.',
    images: [],
    group: 'Digital Media',
  },
);
