import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export enum Nep3dScanOptionId {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
}

export default createPerformableConfig(
  '3d',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      option: z.nativeEnum(Nep3dScanOptionId),
      matterport: coerceBoolean().optional(),
    }),
  },
  {
    name: '3D Scan',
    thumbnail: 'tenant/nep/public/matterport.jpg',
    short: '3D rendering to virtually showcase your property’s interior area and size.',
    tenant: TenantId.NE_PHOTO,
    images: [],
    group: 'Digital Media',
  },
);
