import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';

export default function TwtOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard
        value={TwtOrderType.FOR_SALE_LISTING}
        image="tenant/twt/residential/1.jpg"
        title="Residential"
      >
        Homes for sale including single family homes, condominiums, townhomes, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={TwtOrderType.SHORT_TERM_RENTAL}
        image="tenant/twt/highlight/1.jpg"
        title="Short Term Rental"
      >
        Airbnb, VRBO, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={TwtOrderType.LONG_TERM_RENTAL}
        image="tenant/twt/highlight/2.jpg"
        title="Long Term Rental"
      >
        Lease listings including single family homes, condominiums, townhomes, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard value={TwtOrderType.COMMERCIAL} image="tenant/twt/commercial/1.jpg" title="Commercial">
        Professional marketing materials for a company website, designer portfolio, or a specific property.
      </OrderWizardTypeCard>
    </div>
  );
}
