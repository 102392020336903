import z from 'zod';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { TenantCustomer } from '~/tenants/common/TenantPersona';
import { DisplayData } from '~/lib/model';

export const MpiCustomerSchema = {
  [FIRST_VERSION_TIMESTAMP]: z.object({
    version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
  }),
};

export type MpiCustomerMetadata = ZodVersionedMetadata<typeof MpiCustomerSchema>;

export default class MpiCustomer extends TenantCustomer<MpiCustomerMetadata> {
  get info(): DisplayData[] {
    const info = super.info;

    return info;
  }
}
