import DemoJob from '~/tenants/demo/model/DemoJob';
import Demo3dConfig, { Demo3dScanOptionId } from '~/tenants/demo/performable/3d/Demo3dConfig';
import { addressToArea, addressToStreet, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { DemoOrderType } from '~/tenants/demo/model/DemoOrder';
import {
  TenantSubscriptionMetadata,
  TenantSubscriptionStatus,
  TenantSubscriptionType,
} from '~/tenants/common/TenantPersona';
import { DateTime } from 'luxon';

interface Demo3dScanOptions {
  onsite: number; // TODO: need official numbers
  amount: number;
  from: number;
  to: number;
}

export const DEMO_3D_OPTIONS: Record<Demo3dScanOptionId, Demo3dScanOptions> = {
  [Demo3dScanOptionId.ONE]: {
    onsite: 30,
    amount: 250,
    from: 0,
    to: 1000,
  },
  [Demo3dScanOptionId.TWO]: {
    onsite: 45,
    amount: 275,
    from: 1001,
    to: 2000,
  },
  [Demo3dScanOptionId.THREE]: {
    onsite: 60,
    amount: 285,
    from: 2001,
    to: 3000,
  },
  [Demo3dScanOptionId.FOUR]: {
    onsite: 60,
    amount: 300,
    from: 3001,
    to: 4000,
  },
  [Demo3dScanOptionId.FIVE]: {
    onsite: 90,
    amount: 350,
    from: 4001,
    to: 5000,
  },
  [Demo3dScanOptionId.SIX]: {
    onsite: 90,
    amount: 400,
    from: 5001,
    to: 6000,
  },
  [Demo3dScanOptionId.SEVEN]: {
    onsite: 120,
    amount: 450,
    from: 6001,
    to: 7000,
  },
  [Demo3dScanOptionId.EIGHT]: {
    onsite: 120,
    amount: 500,
    from: 7001,
    to: 8000,
  },
};

export default class Demo3dJob extends DemoJob<typeof Demo3dConfig> {
  get performable() {
    return Demo3dConfig;
  }

  onsite(): number {
    return DEMO_3D_OPTIONS[this.metadata.option].onsite;
  }

  defaultValue(): ZodVersionedMetadata<(typeof Demo3dConfig)['schema']> {
    if (this.order.metadata.type === DemoOrderType.DRAFT) {
      return {
        option: Demo3dScanOptionId.ONE,
        version: schema_latest_version(Demo3dConfig.schema),
      };
    }

    let option: undefined | Demo3dScanOptionId;

    for (const [key, value] of Object.entries(DEMO_3D_OPTIONS)) {
      if (this.order.metadata.sqft >= value.from && this.order.metadata.sqft <= value.to) {
        // TODO: better typing
        option = key as any as Demo3dScanOptionId;
        break;
      }
    }

    if (!option) {
      throw new Error('No option found for sqft range');
    }

    return {
      option,
      version: schema_latest_version(Demo3dConfig.schema),
    };
  }

  get configurable() {
    return false;
  }

  get hourly() {
    return this.order.metadata.type === DemoOrderType.COMMERCIAL;
  }

  get multiple() {
    return this.order.metadata.type !== DemoOrderType.COMMERCIAL;
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === DemoOrderType.COMMERCIAL) {
      return [];
    }

    const option = DEMO_3D_OPTIONS[this.metadata.option];

    return [
      {
        amount: new Big(option.amount),
        description: this.performable.name,
        discountable: true,
        id: this.performable.id,
      },
    ];
  }

  subscription(): TenantSubscriptionMetadata {
    return {
      type: TenantSubscriptionType.MATTERPORT,
      name: this.order.address
        ? `${addressToStreet(this.order.address)}, ${addressToArea(this.order.address)}`
        : 'Matterport',
      status: TenantSubscriptionStatus.ACTIVE,
      created: DateTime.now().toJSDate().toISOString(),
      order_id: this.order.id,
      job_id: this.id,
      months: 6,
      cost: 24,
      expires: DateTime.now().plus({ months: 6 }).toJSDate().toISOString(),
      renew: false,
      payments: {},
      url: 'https://example.com',
    };
  }
}
