import MpxJob from '~/tenants/mpx/model/MpxJob';
import { TenantJobLine } from '~/lib/model';
import MpxGoogleConfig from '~/tenants/mpx/performable/street/MpxStreetConfig';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxStreetJob extends MpxJob<typeof MpxGoogleConfig> {
  get performable() {
    return MpxGoogleConfig;
  }

  onsite(): number {
    return 30;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxGoogleConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  get eligible(): boolean {
    return [MpxOrderType.BUILDERS, MpxOrderType.COMMERCIAL, MpxOrderType.MULTI_FAMILY].includes(
      this.order.metadata.type,
    );
  }

  revenueLines() {
    const lines: TenantJobLine[] = [];

    let amount = new Big(0);
    let intervals = 0;
    let additional = new Big(0);

    if ([MpxOrderType.BUILDERS, MpxOrderType.COMMERCIAL].includes(this.order.metadata.type)) {
      amount = new Big(295);
      additional = new Big(50);
      intervals = 8;
    } else if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      amount = new Big(1495);
      additional = new Big(95);
      intervals = 4;
    }

    if (amount.gt(0)) {
      lines.push({
        description: 'Google: Street View Road Capture',
        amount,
        taxable: true,
        id: 'capture',
      });

      if (this.metadata.miles && intervals > 0 && additional.gt(0)) {
        const fraction = 1 / intervals;

        if (this.metadata.miles > fraction) {
          const segments = Math.ceil(this.metadata.miles / fraction) - 1;

          lines.push({
            description: 'Google: Additional Mileage',
            amount: new Big(segments).times(additional),
            taxable: true,
            id: 'mileage',
          });
        }
      }
    }

    return lines;
  }
}
