import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { useRef } from 'react';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MpiOrderType } from '~/tenants/mpi/model/MpiOrder';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function MpiOrderForm({ persona }: { persona: PersonaType }) {
  const [_type, type] = useZodFormFieldSingle<MpiOrderType>('type');
  const preselected = useRef(type !== undefined);

  return (
    <FormGroup>
      {preselected.current && <ZodFieldHidden name="type" />}
      {!preselected.current && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              {
                label: 'Residential',
                value: MpiOrderType.RESIDENTIAL,
              },
            ]}
          />
        </FormHorizontal>
      )}
      <FormHorizontal name="sqft" label="Square Feet">
        <ZodFieldInput name="sqft" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}
