import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { FormHorizontal } from '~/components/form/layout';
import React from 'react';

export default function MpxPhotosTwilight() {
  return (
    <FormHorizontal name="twilight" label="Twilight">
      <ZodFieldSelect
        name="twilight"
        options={[
          {
            value: '',
            label: 'No Twilight',
          },
          {
            value: 'REAL',
            label: 'Real Twilight',
          },
          {
            value: 'VIRTUAL',
            label: 'Virtual Twilight',
          },
        ]}
      />
    </FormHorizontal>
  );
}
