import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpxOrderContext, MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { FormHorizontal } from '~/components/form/layout';
import FormGroup from '~/components/form/FormGroup';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { InputSelectOption } from '~/components/input/InputSelect';
import { PersonaType } from '~/tenants/common/TenantPersona';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import MpxPhotosTwilight from '~/tenants/mpx/performable/photos/MpxPhotosTwilight';
import MpxPhotosStaging from '~/tenants/mpx/performable/photos/MpxPhotosStaging';

export default function MpxPhotosForm({ context, create, persona }: TenantPerformableFormProps<MpxOrderContext>) {
  const typeValue = useZodFormFieldSingleValue('type');
  const unitValue = useZodFormFieldSingleValue('unit');

  const options: InputSelectOption[] = [];

  if ([MpxOrderType.COMMERCIAL, MpxOrderType.RESIDENTIAL].includes(context.metadata.type)) {
    options.push({ value: 'photos', label: 'Interior + Exterior' });
  } else if (context.metadata.type === MpxOrderType.MULTI_FAMILY) {
    options.push({ value: 'unit', label: 'Unit Interior' });
  } else if (context.metadata.type === MpxOrderType.BUILDERS) {
    options.push({ value: 'sqft', label: 'Interior + Exterior' });
  }

  if ([MpxOrderType.BUILDERS, MpxOrderType.RESIDENTIAL].includes(context.metadata.type)) {
    options.push({ value: 'exterior', label: 'Exterior Only' });
  }

  if (persona === PersonaType.OFFICE) {
    options.push({ value: 'custom', label: 'Custom' });
  }

  const typeInput = (
    <FormHorizontal label="Type" name="type">
      <ZodFieldSelect name="type" options={options} />
    </FormHorizontal>
  );

  let content = <div></div>;

  if (typeValue === 'custom') {
    content = (
      <>
        <FormHorizontal label="Revenue">
          <ZodFieldInput name="revenue" type="number" />
        </FormHorizontal>
        <FormHorizontal label="Expense">
          <ZodFieldInput name="expense" type="number" />
        </FormHorizontal>
      </>
    );
  } else if (typeValue === 'unit' && context.metadata.type === MpxOrderType.MULTI_FAMILY) {
    let options = context.metadata.units
      .map((u) => ({
        value: u.id,
        label: `#${u.number} - ${u.beds} Beds, ${u.sqft} Sqft`,
      }))
      .filter((option) => {
        const units = context.jobs.map((j) =>
          j.performable_id === 'photos' && j.metadata.type === 'unit' ? j.metadata.unit : '',
        );

        return !units.includes(option.value) || option.value === unitValue;
      });

    if (options.length === 0) {
      // TODO: prevent unit removal if jobs are associated
      content = (
        <div>
          There are no remaining units available to assign this job.
          <ZodFieldHidden name="unit" value="" />
        </div>
      );
    } else {
      content = (
        <FormHorizontal label="Unit">
          <ZodFieldSelect name="unit" options={options} />
        </FormHorizontal>
      );
    }
  } else if (typeValue !== 'exterior' && context.metadata.type === MpxOrderType.RESIDENTIAL) {
    content = (
      <FormHorizontal label="Photos" name="Photos">
        <ZodFieldSelect
          name="photos"
          options={[
            { value: '24', label: '24 Photos' },
            { value: '36', label: '36 Photos' },
          ]}
        />
      </FormHorizontal>
    );
  } else if (context.metadata.type === MpxOrderType.COMMERCIAL && typeValue !== 'exterior') {
    content = (
      <FormHorizontal label="Photos" name="Photos">
        <ZodFieldSelect
          name="photos"
          options={[
            { value: '10', label: '10 Photos' },
            { value: '20', label: '20 Photos' },
          ]}
        />
      </FormHorizontal>
    );
  }

  let community = <ZodFieldHidden name="community" value="" />;
  let twilight = <ZodFieldHidden name="twilight" value="" />;
  let staging = <ZodFieldHidden name="staging" value="" />;

  if ([MpxOrderType.MULTI_FAMILY, MpxOrderType.BUILDERS].includes(context.metadata.type)) {
    // only show once for entire order
    const hideCommunity = context.jobs.some(
      (job) =>
        job.performable_id === 'photos' &&
        job.metadata.community &&
        job.metadata.type === 'unit' &&
        (create || job.metadata.unit !== unitValue),
    );

    if (!hideCommunity) {
      community = (
        <FormHorizontal label="Community Photos" name="community">
          <ZodFieldSelect
            name="community"
            options={[
              { value: '', label: 'None' },
              { value: '36', label: '36 Photos' },
              { value: '60', label: '60 Photos' },
            ]}
          />
        </FormHorizontal>
      );
    }
  } else if ([MpxOrderType.RESIDENTIAL, MpxOrderType.BUILDERS].includes(context.metadata.type)) {
    twilight = <MpxPhotosTwilight />;
  }

  if (
    typeValue !== 'exterior' &&
    [MpxOrderType.BUILDERS, MpxOrderType.RESIDENTIAL, MpxOrderType.CUSTOM, MpxOrderType.MULTI_FAMILY].includes(
      context.metadata.type,
    )
  ) {
    staging = <MpxPhotosStaging />;
  }

  return (
    <FormGroup>
      {typeInput}
      {content}
      {community}
      {twilight}
      {staging}
    </FormGroup>
  );
}
