import { registerTenant, TenantId } from '~/tenants/common/registry';
import NepPhotographyForm from '~/tenants/nep/performable/photos/NepPhotographyForm';
import NepPhotographyConfig from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import { NepOrder, NepOrderSchema } from '~/tenants/nep/model/NepOrder';
import Nep3dConfig from '~/tenants/nep/performable/3d/Nep3dConfig';
import Nep3dJob from '~/tenants/nep/performable/3d/Nep3dJob';
import NepAerialConfig from '~/tenants/nep/performable/aerial/NepAerialConfig';
import NepAerialJob from '~/tenants/nep/performable/aerial/NepAerialJob';
import NepBrochuresConfig from '~/tenants/nep/performable/brochures/NepBrochuresConfig';
import NepBrochuresJob from '~/tenants/nep/performable/brochures/NepBrochuresJob';
import NepEddmConfig from '~/tenants/nep/performable/eddm/NepEddmConfig';
import NepEddmJob from '~/tenants/nep/performable/eddm/NepEddmJob';
import NepPhotographyJob from '~/tenants/nep/performable/photos/NepPhotographyJob';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import NepFloorplansJob from '~/tenants/nep/performable/floorplans/NepFloorplansJob';
import NepVirtualStagingConfig from '~/tenants/nep/performable/staging/NepVirtualStagingConfig';
import NepVirtualStagingJob from '~/tenants/nep/performable/staging/NepVirtualStagingJob';
import Nep3dForm from '~/tenants/nep/performable/3d/Nep3dForm';
import NepVirtualStagingForm from '~/tenants/nep/performable/staging/NepVirtualStagingForm';
import NepFloorplansForm from '~/tenants/nep/performable/floorplans/NepFloorplansForm';
import NepEddmForm from '~/tenants/nep/performable/eddm/NepEddmForm';
import NepBrochuresForm from '~/tenants/nep/performable/brochures/NepBrochuresForm';
import NepAerialForm from '~/tenants/nep/performable/aerial/NepAerialForm';
import { TimeZone } from '~/lib/enum';
import NepPackageBasicConfig from '~/tenants/nep/package/basic/NepPackageBasicConfig';
import NepPackageBasicForm from '~/tenants/nep/package/basic/NepPackageBasicForm';
import NepPackageStandardForm from '~/tenants/nep/package/standard/NepPackageStandardForm';
import NepPackageStandardConfig from '~/tenants/nep/package/standard/NepPackageStandardConfig';
import NepPackagePremiumConfig from '~/tenants/nep/package/premium/NepPackagePremiumConfig';
import NepPackagePremiumForm from '~/tenants/nep/package/premium/NepPackagePremiumForm';
import NepOrderWizardType from '~/tenants/nep/forms/NepOrderWizardType';
import NepOrderForm from '~/tenants/nep/forms/NepOrderForm';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import NepProvider from '~/tenants/nep/model/NepProvider';
import { NEP_PROVIDERS } from '~/tenants/nep/data';
import NepCustomer from '~/tenants/nep/model/NepCustomer';

export default function registerNep() {
  registerTenant(TenantId.NE_PHOTO, NepOrderSchema, {
    name: 'NE Photo',
    legal: 'NE Media, LLC.',
    phone: '+18602225445',
    background: '#222E66',
    domain: 'order.nephoto.com',
    email: 'orders@nephoto.com',
    emailEnable: true,
    nylasProviderInvite: true,
    nylasGrantId: 'a2ec9c1e-27e3-42fc-915c-6948e22ac99b',
    azureApplicationId: '40c92277-d3dd-4184-934a-3533d1d60e95',
    rainforestEnable: true,
    rainforestMerchantId: 'mid_2lWOvfxNu9kBN0PWVNeBs8I45qU',
    rainforestMerchantApplicationId: 'app_2lWOvgjqs7OXXB13UaQZnYknrAs',
    api: '8e63e17d-9456-48a7-aaea-a60e40bae485',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    logoRaster: 'https://order.nephoto.com/media/public/logo.png',
    logoVector: 'https://order.nephoto.com/media/public/logo.svg',
    orderNumberMinimum: 50000,
    orderNumberPadding: 7,
    // TODO: make typescript happy
    orderClass: NepOrder as any,
    customerClass: NepCustomer,
    locations: [
      {
        timezone: TimeZone.US_EASTERN,
        slug: 'nl',
        name: 'New London',
        address: {
          line1: "125 Eugene O'Neill Drive",
          line2: null,
          city: 'New London',
          state: 'CT',
          zip: '06320',
          long: -72.09381669999999,
          lat: 41.3539585,
        },
      },
    ],
    customerSchema: {
      [FIRST_VERSION_TIMESTAMP]: z.object({ version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP) }),
    },
    providers: NEP_PROVIDERS,
    providerClass: NepProvider,
    scheduleEndTime: '20:00',
    scheduleStartTime: '08:00',
    forms: {
      wizardType: NepOrderWizardType,
      orderConfigure: NepOrderForm,
      provider: EmptyForm,
    },

    packages: [
      {
        config: NepPackageBasicConfig,
        form: NepPackageBasicForm,
      },
      {
        config: NepPackageStandardConfig,
        form: NepPackageStandardForm,
      },
      {
        config: NepPackagePremiumConfig,
        form: NepPackagePremiumForm,
      },
    ],
    performables: {
      [NepPhotographyConfig.id]: {
        config: NepPhotographyConfig,
        form: NepPhotographyForm,
        job: NepPhotographyJob,
      },
      [NepFloorplansConfig.id]: {
        config: NepFloorplansConfig,
        form: NepFloorplansForm,
        job: NepFloorplansJob,
      },
      [Nep3dConfig.id]: {
        config: Nep3dConfig,
        form: Nep3dForm,
        job: Nep3dJob,
      },
      [NepAerialConfig.id]: {
        config: NepAerialConfig,
        form: NepAerialForm,
        job: NepAerialJob,
      },
      [NepVirtualStagingConfig.id]: {
        config: NepVirtualStagingConfig,
        form: NepVirtualStagingForm,
        job: NepVirtualStagingJob,
      },
      [NepBrochuresConfig.id]: {
        config: NepBrochuresConfig,
        form: NepBrochuresForm,
        job: NepBrochuresJob,
      },
      [NepEddmConfig.id]: {
        config: NepEddmConfig,
        form: NepEddmForm,
        job: NepEddmJob,
      },
    },
  });
}
