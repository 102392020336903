import MpxJob from '~/tenants/mpx/model/MpxJob';
import { TenantJobLine } from '~/lib/model';
import MpxVideoConfig from '~/tenants/mpx/performable/video/MpxVideoConfig';
import Big from 'big.js';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxVideoJob extends MpxJob<typeof MpxVideoConfig> {
  get performable() {
    return MpxVideoConfig;
  }

  get eligible(): boolean {
    return this.order.metadata.type === MpxOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxVideoConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines() {
    const lines: TenantJobLine[] = [];

    if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
      lines.push({
        description: 'Videography',
        amount: new Big(899),
        taxable: true,
        id: 'video',
      });
    }

    return lines;
  }
}
