import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'floorplan',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.union([z.literal('2D'), z.literal('3D')]),
    }),
  },
  {
    name: 'Floorplan',
    short:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et nulla ornare, tempus erat non, consectetur nunc.',
    tenant: TenantId.METROPLEX_360,
    images: [],
  },
);
