import { PicOrderType } from '~/tenants/pic/model/PicOrder';
import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';

export default function PicOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard value={PicOrderType.RESIDENTIAL} image="tenant/twt/residential/1.jpg" title="Residential">
        Real Estate Listing Photography service for Single Family Homes, Condos, Townhouses and Apartments.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard value={PicOrderType.COMMERCIAL} image="tenant/twt/commercial/1.jpg" title="Commercial">
        Deliverables include photography captured onsite with basic image processing applied. Priced per hour. Best
        service for businesses, hotels, AirBNB, Rentals
      </OrderWizardTypeCard>
    </div>
  );
}
