import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TWT_REEL_VOICEOVER } from '~/tenants/twt/performable/reel/TwtReelJob';
import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';

export default function TwtVideoForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <TenantPackageAddOnCheckbox
      name="voiceover"
      persona={persona}
      image="tenant/twt/cameo/thumbnail.jpeg"
      title="Agent Cameo / Voiceover"
      cost={TWT_REEL_VOICEOVER}
      description="Add a personal touch to your video with an on screen cameo or a descriptive voiceover."
    />
  );
}
