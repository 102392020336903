import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DemoOrderContext } from '~/tenants/demo/model/DemoOrder';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';

export default function DemoVirtualStagingForm(_props: TenantPerformableFormProps<DemoOrderContext>) {
  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldInput name="photos" type="number" />
      </FormHorizontal>
    </TenantPerformableConfiguration>
  );
}
