import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum NepEddmQuantity {
  QTY_250 = '250',
  QTY_500 = '500',
  QTY_1000 = '1000',
  QTY_2500 = '2500',
}

export enum NepEddmOptionId {
  NINE = '9',
  TWELVE = '12',
}

export default createPerformableConfig(
  'eddm',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      quantity: z.nativeEnum(NepEddmQuantity),
      option: z.nativeEnum(NepEddmOptionId),
      postage: coerceBoolean().optional(),
    }),
  },
  {
    name: 'EDDM',
    thumbnail: 'tenant/nep/public/eddm.jpg',
    short:
      'Every Door Direct Mail services your selected mailing area with custom made mailers to help advertise your team, project or services.',
    tenant: TenantId.NE_PHOTO,
    images: [],
    group: 'Print',
  },
);
