import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export enum PicFloorPlansOptionId {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
}

export default createPerformableConfig(
  'floorplans',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      option: z.nativeEnum(PicFloorPlansOptionId),
    }),
  },
  {
    name: 'Floorplan',
    tenant: TenantId.NE_PHOTO,
    thumbnail: 'tenant/twt/floorplan/standard2.png',
    short: '2D floor-plan rendering to measure your property’s taxable square footage.',
    images: [],
    group: 'Digital Media',
  },
);
