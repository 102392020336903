import { TenantOrder } from '../../common/TenantOrder';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import MpxAerialConfig from '~/tenants/mpx/performable/aerial/MpxAerialConfig';
import MpxMatterportConfig from '~/tenants/mpx/performable/matterport/MpxMatterportConfig';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { MpxCustomerData } from '~/tenants/mpx/model/MpxCustomerData';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import { AppointmentCancel } from '~/lib/enum';
import Big from 'big.js';
import MpxStreetConfig from '~/tenants/mpx/performable/street/MpxStreetConfig';
import MpxZillowConfig from '~/tenants/mpx/performable/zillow/MpxZillowConfig';
import MpxMicrositeConfig from '~/tenants/mpx/performable/microsite/MpxMicrositeConfig';
import MpxVideoConfig from '~/tenants/mpx/performable/video/MpxVideoConfig';
import MpxFloorplanConfig from '~/tenants/mpx/performable/floorplan/MpxFloorplanConfig';
import { MpxLocation } from '~/tenants/mpx/data';
import { format_whole_number } from '~/components/Money';

export enum MpxOrderType {
  CUSTOM = 'custom',
  COMMERCIAL = 'commercial',
  INSURANCE = 'insurance',
  ARCHITECTURAL = 'architectural',
  BUILDERS = 'builders',
  RESIDENTIAL = 'residential',
  MULTI_FAMILY = 'multi_family',
}

export const MpxOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({ type: z.literal(MpxOrderType.CUSTOM) }),
        z.object({
          type: z.literal(MpxOrderType.RESIDENTIAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(MpxOrderType.ARCHITECTURAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(MpxOrderType.INSURANCE),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(MpxOrderType.COMMERCIAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(MpxOrderType.BUILDERS),
          sqft: z.coerce.number(),
          builder: z.string().optional(),
          community: z.string().optional(),
          lot: z.string().optional(),
        }),
        z.object({
          type: z.literal(MpxOrderType.MULTI_FAMILY),
          units: z
            .object({
              id: z.string(),
              number: z.coerce.string(),
              sqft: z.coerce.number(),
              beds: z.coerce.number(),
            })
            .array()
            .min(1),
        }),
      ]),
    ),
};

export type MpxOrderMetadata = ZodVersionedMetadata<typeof MpxOrderSchema>;

export type MpxPerformableConfig =
  | typeof MpxPhotosConfig
  | typeof MpxAerialConfig
  | typeof MpxFloorplanConfig
  | typeof MpxVideoConfig
  | typeof MpxMatterportConfig
  | typeof MpxMicrositeConfig
  | typeof MpxZillowConfig
  | typeof MpxStreetConfig;

export type MpxOrderContext = TenantOrderContext<MpxOrderMetadata, MpxPerformableConfig, MpxCustomerData>;

export default class MpxOrder extends TenantOrder<MpxOrderContext> {
  info(): Array<DisplayData> {
    const info = super.info();

    if (this.context.metadata.type === MpxOrderType.BUILDERS) {
      if (this.context.metadata.community) {
        info.push({ name: 'Community', value: this.context.metadata.community, invoice: true });
      }

      if (this.context.metadata.builder) {
        info.push({ name: 'Builder', value: this.context.metadata.builder, invoice: true });
      }
    }

    if ('sqft' in this.context.metadata) {
      info.push({ name: 'Sqft', value: format_whole_number(this.context.metadata.sqft), invoice: true });
    }

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    for (const appointment of this.context.appointments) {
      const distance = appointment.address?.distance ?? 0;
      let travel = 0;

      if (this.context.location === MpxLocation.DALLAS) {
        if (distance >= 46) {
          travel = 30;
        } else if (distance >= 26) {
          travel = 20;
        }
      } else if (distance > 75) {
        travel = 30;
      } else if (distance >= 51) {
        travel = 20;
      }

      if (travel > 0) {
        lines.push({
          id: `travel-${appointment.id}`,
          description: 'Travel Fee',
          amount: new Big(travel),
        });
      }

      if (appointment.canceled === AppointmentCancel.ONSITE) {
        lines.push({
          id: `cancel-${appointment.id}`,
          description: 'Appointment Cancellation Fee',
          amount: new Big(30),
        });
      }
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    for (const appointment of this.context.appointments) {
      if (appointment.canceled === AppointmentCancel.ONSITE) {
        const jobs = this.jobs.filter((j) => appointment.job_ids.includes(j.id));
        let amount = jobs.reduce((acc, job) => acc.plus(job.revenue()), new Big(0));

        if (amount.lt(75)) {
          // enforce $75 minimum
          amount = new Big(75);
        }

        lines.push({
          id: `cancel-${appointment.id}`,
          description: 'Appointment Cancelation Fee',
          amount,
        });
      }
    }

    return lines;
  }
}
