import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { FormHorizontal } from '~/components/form/layout';
import React from 'react';

export default function MpxPhotosStaging() {
  return (
    <FormHorizontal label="Virtual Staging">
      <ZodFieldInput name="staging" type="number" />
    </FormHorizontal>
  );
}
