import { TenantPackageConfig } from '~/tenants/common/registry';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import MpxZillowConfig from '~/tenants/mpx/performable/zillow/MpxZillowConfig';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';

const MpxZillowBundleConfig: TenantPackageConfig = {
  id: 'zillow',
  name: 'Photo + Zillow 3D Home Bundle',
  performables: [MpxPhotosConfig, MpxZillowConfig],
  eligible: (order) => {
    return order.metadata.type === MpxOrderType.RESIDENTIAL;
  },
  description: 'Save by bundling photos and zillow 3d home!',
};

export default MpxZillowBundleConfig;
