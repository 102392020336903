import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpxOrderContext, MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { FormHorizontal } from '~/components/form/layout';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';

export default function MpxAerialForm({ context }: TenantPerformableFormProps<MpxOrderContext>) {
  const videoValue = useZodFormFieldSingleValue('video');

  let photos = <ZodFieldHidden name="photos" value="" />;
  let video = <ZodFieldHidden name="video" value="" />;
  let community = <ZodFieldHidden name="community" value="" />;
  let tour = <ZodFieldHidden name="tour" value="" />;

  if (context.metadata.type === MpxOrderType.RESIDENTIAL) {
    photos = (
      <FormHorizontal label="Photos">
        <ZodFieldSelect
          options={[
            { label: 'No Photos', value: '' },
            { label: '4 Photos', value: '4' },
            { label: 'Up to 20 Photos', value: '20' },
          ]}
          name="photos"
        />
      </FormHorizontal>
    );

    video = (
      <FormHorizontal label="Video">
        <ZodFieldCheckbox name="video" value="60" />
      </FormHorizontal>
    );
  } else if (context.metadata.type === MpxOrderType.MULTI_FAMILY) {
    photos = (
      <FormHorizontal label="Photos">
        <ZodFieldSelect
          options={[
            { label: 'No Photos', value: '' },
            { label: '12 Photos', value: '12' },
            { label: '24 Photos', value: '24' },
          ]}
          name="photos"
        />
      </FormHorizontal>
    );

    video = (
      <FormHorizontal label="Video">
        <ZodFieldCheckbox name="video" value="60" />
      </FormHorizontal>
    );

    if (videoValue) {
      community = (
        <FormHorizontal label="Community Video">
          <ZodFieldCheckbox name="community" />
        </FormHorizontal>
      );
    }
  } else if (context.metadata.type === MpxOrderType.BUILDERS) {
    photos = (
      <FormHorizontal label="Photos">
        <ZodFieldCheckbox name="photos" value="10" />
      </FormHorizontal>
    );

    video = (
      <FormHorizontal label="Video">
        <ZodFieldCheckbox name="video" value="60" />
      </FormHorizontal>
    );

    if (videoValue) {
      community = (
        <FormHorizontal label="Ground Video">
          <ZodFieldCheckbox name="community" />
        </FormHorizontal>
      );
    }

    if (context.jobs.some((job) => job.performable_id === 'matterport')) {
      tour = (
        <FormHorizontal label="Drone / 360 Tour">
          <ZodFieldCheckbox name="tour" />
        </FormHorizontal>
      );
    }
  } else if (context.metadata.type === MpxOrderType.COMMERCIAL) {
    photos = <ZodFieldHidden name="photos" value="10" />;
  }

  return (
    <>
      {photos}
      {video}
      {community}
      {tour}
    </>
  );
}
