import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function MpxStreetForm() {
  return (
    <FormHorizontal label="Miles">
      <ZodFieldInput name="miles" type="number" />
    </FormHorizontal>
  );
}
