import MpxPhotosConfig, { MpxTwilightType } from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import MpxJob from '~/tenants/mpx/model/MpxJob';
import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';

import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { MpxLocation } from '~/tenants/mpx/data';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxPhotosJob extends MpxJob<typeof MpxPhotosConfig> {
  get performable() {
    return MpxPhotosConfig;
  }

  onsite(): number {
    return 30;
  }

  get eligible(): boolean {
    return ![MpxOrderType.ARCHITECTURAL, MpxOrderType.INSURANCE].includes(this.order.metadata.type);
  }

  get configurable(): boolean {
    return true;
  }

  get multiple(): boolean {
    return this.order.metadata.type === MpxOrderType.MULTI_FAMILY;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxPhotosConfig)['schema']> {
    if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: 'photos',
        photos: this.order.metadata.sqft > 2000 ? 36 : 24,
      };
    }

    if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: 'photos',
        photos: 10,
      };
    }

    if (this.order.metadata.type === MpxOrderType.BUILDERS) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: 'sqft',
      };
    }

    if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY && this.order.metadata.units.length > 0) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: 'unit',
        unit: this.order.metadata.units[0].id,
      };
    }

    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'custom',
      revenue: '0.00',
      expense: '0.00',
    };
  }

  expenseLines(): TenantJobLine[] {
    const lines = super.expenseLines();

    const addon = this.order.jobs.some((j) => j.performable_id === 'matterport');

    let total = new Big(0);

    if (this.order.metadata.type === MpxOrderType.RESIDENTIAL || this.order.metadata.type === MpxOrderType.BUILDERS) {
      if (addon) {
        total = new Big(50);
      } else {
        total = new Big(this.order.location === MpxLocation.DALLAS ? 60 : 70);
      }
    } else if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      if (this.order.metadata.units.length === 1) {
        if (this.order.metadata.units[0].sqft >= 2000) {
          // MF Single Apt >= 2000 sqft
          total = new Big(addon ? 50 : this.order.location === MpxLocation.DALLAS ? 60 : 70);
        } else {
          // MF Single Apt < 2000 sqft
          total = new Big(addon ? 40 : this.order.location === MpxLocation.DALLAS ? 50 : 60);
        }
      } else if (this.order.metadata.units.some((u) => u.sqft >= 2000)) {
        // MF Multi Apt >= 2000 sqft
        total = new Big(addon ? 50 : this.order.location === MpxLocation.DALLAS ? 55 : 60);
      } else {
        // MF Multi Apt < 2000 sqft
        total = new Big(addon ? 40 : this.order.location === MpxLocation.DALLAS ? 45 : 50);
      }

      if (this.metadata.community) {
        total = new Big(addon ? 50 : 75);
      }
    } else if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
      total = new Big(addon ? 50 : 75);
    }

    lines.push({
      id: 'total',
      description: 'Photos',
      amount: total,
    });

    return lines;
  }

  revenueLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.community) {
      if (this.metadata.community === 36) {
        lines.push({
          id: 'community',
          description: 'Community Photos (36)',
          amount: new Big(399),
          taxable: true,
        });
      } else {
        lines.push({
          id: 'community',
          description: 'Community Photos (60)',
          amount: new Big(599),
          taxable: true,
        });
      }
    }

    if (this.metadata.staging && this.metadata.staging > 0) {
      let amount = new Big(75);

      if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
        amount = new Big(39);
      }

      lines.push({
        id: 'staging',
        description: `Virtual Staging (${this.metadata.staging})`,
        amount: amount.times(this.metadata.staging),
        taxable: true,
      });
    }

    if (this.metadata.twilight) {
      if (this.metadata.twilight === MpxTwilightType.REAL) {
        lines.push({
          id: 'rtwilight',
          description: 'Twilight Photography',
          amount: new Big(150),
          taxable: true,
        });
      } else if (this.metadata.twilight === MpxTwilightType.VIRTUAL) {
        lines.push({
          id: 'vtwilight',
          description: 'Virtual Twilight Photos (3)',
          amount: new Big(49),
          taxable: true,
        });
      }
    }

    let amount = new Big(0);
    let description = 'Photos';

    if (this.metadata.type === 'exterior') {
      lines.push({
        id: 'exterior',
        description: 'Exterior Only Photos',
        amount: new Big(75),
        taxable: true,
      });
    } else if (this.metadata.type === 'custom') {
      amount = new Big(this.metadata.revenue);
    } else if (
      this.metadata.type === 'photos' &&
      [MpxOrderType.COMMERCIAL, MpxOrderType.RESIDENTIAL].includes(this.order.metadata.type)
    ) {
      if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
        if (this.metadata.photos === 10) {
          amount = new Big(250);
        } else if (this.metadata.photos === 20) {
          amount = new Big(350);
        }
      } else {
        if (this.metadata.photos === 24) {
          amount = new Big(175);
        } else if (this.metadata.photos === 36) {
          amount = new Big(225);
        }
      }

      description = `Photos (${this.metadata.photos})`;
    } else if (this.metadata.type === 'unit' && this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      for (const unit of this.order.metadata.units) {
        if (unit.id === this.metadata.unit) {
          let photos = 16;

          if (unit.beds >= 3) {
            amount = new Big(225);
            photos = 24;
          } else if (unit.beds >= 2) {
            amount = new Big(195);
            photos = 20;
          } else {
            amount = new Big(155);
          }

          description = `Photos (${photos}): Unit #${unit.number}`;

          break;
        }
      }
    } else if (this.metadata.type === 'sqft' && this.order.metadata.type === MpxOrderType.BUILDERS) {
      if (this.order.metadata.sqft < 2000) {
        amount = new Big(199);
        description = 'Photos (36)';
      } else if (this.order.metadata.sqft < 4000) {
        amount = new Big(239);
        description = 'Photos (48)';
      } else {
        amount = new Big(299);
        description = 'Photos (60)';
      }
    }

    if (amount.gt(0)) {
      lines.push({
        id: 'photos',
        description,
        amount,
        taxable: true,
      });
    }

    return lines;
  }
}
