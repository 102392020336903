import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { DemoOrderType } from '~/tenants/demo/model/DemoOrder';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

function Type() {
  const [_, type] = useZodFormFieldSingle('type');

  if (type) {
    return <ZodFieldHidden name="type" />;
  }

  return (
    <FormHorizontal name="type" label="Type">
      <ZodFieldSelect
        options={[
          {
            label: 'Draft',
            value: DemoOrderType.DRAFT,
          },
          {
            label: 'Residential',
            value: DemoOrderType.RESIDENTIAL,
          },
          {
            label: 'Commercial',
            value: DemoOrderType.COMMERCIAL,
          },
        ]}
        name="type"
      />
    </FormHorizontal>
  );
}

export default function DemoOrderForm() {
  const [_, type] = useZodFormFieldSingle('type');

  return (
    <FormGroup>
      <Type />

      {type && type !== DemoOrderType.DRAFT && (
        <>
          <FormHorizontal name="sqft" label="Square Feet">
            <ZodFieldInput name="sqft" type="number" autoFocus />
          </FormHorizontal>
          <FormHorizontal name="acres" label="Acres">
            <ZodFieldInput name="acres" type="number" />
          </FormHorizontal>
        </>
      )}
    </FormGroup>
  );
}
