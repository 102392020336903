import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DEMO_AERIAL_OPTIONS } from '~/tenants/nep/performable/aerial/NepAerialJob';
import { NepOrderContext, NepOrderType } from '~/tenants/nep/model/NepOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TenantPerformableAddOns, TenantPerformableConfiguration } from '~/tenants/common/performable';
import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { Money } from '~/components/Money';

export default function NepAerialForm({ context, persona }: TenantPerformableFormProps<NepOrderContext>) {
  return (
    <>
      <TenantPerformableConfiguration>
        <FormHorizontal name="option" label="Option">
          <ZodFieldSelect
            name="option"
            options={Object.entries(DEMO_AERIAL_OPTIONS).map(([id, pkg]) => ({
              value: id,
              label: `${pkg.acres - 1}-${pkg.acres} acres`,
              disabled:
                context.metadata.type === NepOrderType.RESIDENTIAL &&
                (context.metadata.acres <= pkg.acres - 1 || context.metadata.acres > pkg.acres),
            }))}
          />
        </FormHorizontal>
      </TenantPerformableConfiguration>
      <TenantPerformableAddOns persona={persona}>
        <div className="flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6">
          <div className="rounded-lg overflow-hidden flex-shrink-0">
            <LegacyResponsiveImage src="card/public/community.jpg" width={200} />
          </div>
          <div>
            <div className="flex items-center leading-none select-none">
              <div className="w-8 flex-shrink-0">
                <ZodFieldCheckbox id="aerial" name="community" />
              </div>
              <label className="text-base font-medium cursor-pointer w-full" htmlFor="aerial">
                Custom Community Photos
              </label>
              <p className="font-bold text-base">
                <Money>60</Money>
              </p>
            </div>
            <div className="pl-8 pt-2 max-sm:pr-4">
              <p className="text-sm text-gray-600">
                A high resolution example of directed shots that showcase your desired, local sites.
              </p>
            </div>
          </div>
        </div>
      </TenantPerformableAddOns>
    </>
  );
}
