import { registerTenant, TenantId } from '~/tenants/common/registry';
import { TimeZone } from '~/lib/enum';
import MpxOrder, { MpxOrderSchema, MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import MpxPhotosJob from '~/tenants/mpx/performable/photos/MpxPhotosJob';
import MpxOrderForm from '~/tenants/mpx/ux/MpxOrderForm';
import MpxPhotosForm from '~/tenants/mpx/performable/photos/MpxPhotosForm';
import MpxAerialJob from '~/tenants/mpx/performable/aerial/MpxAerialJob';
import MpxAerialConfig from '~/tenants/mpx/performable/aerial/MpxAerialConfig';
import MpxAerialForm from '~/tenants/mpx/performable/aerial/MpxAerialForm';
import MpxMatterportConfig from '~/tenants/mpx/performable/matterport/MpxMatterportConfig';
import MpxMatterportJob from '~/tenants/mpx/performable/matterport/MpxMatterportJob';
import MpxMatterportForm from '~/tenants/mpx/performable/matterport/MpxMatterportForm';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import MpxProvider from '~/tenants/mpx/model/MpxProvider';
import { MPX_PROVIDER_DATA } from '~/tenants/mpx/data';
import MpxStreetConfig from '~/tenants/mpx/performable/street/MpxStreetConfig';
import MpxStreetJob from '~/tenants/mpx/performable/street/MpxStreetJob';
import MpxStreetForm from '~/tenants/mpx/performable/street/MpxStreetForm';
import MpxMicrositeConfig from '~/tenants/mpx/performable/microsite/MpxMicrositeConfig';
import MpxMicrositeJob from '~/tenants/mpx/performable/microsite/MpxMicrositeJob';
import MpxMicrositeForm from '~/tenants/mpx/performable/microsite/MpxMicrositeForm';
import MpxVideoJob from '~/tenants/mpx/performable/video/MpxVideoJob';
import MpxVideoConfig from '~/tenants/mpx/performable/video/MpxVideoConfig';
import MpxZillowConfig from '~/tenants/mpx/performable/zillow/MpxZillowConfig';
import MpxZillowJob from '~/tenants/mpx/performable/zillow/MpxZillowJob';
import MpxZillowForm from '~/tenants/mpx/performable/zillow/MpxZillowForm';
import MpxFloorplanConfig from '~/tenants/mpx/performable/floorplan/MpxFloorplanConfig';
import MpxFloorplanJob from '~/tenants/mpx/performable/floorplan/MpxFloorplanJob';
import MpxFloorplanForm from '~/tenants/mpx/performable/floorplan/MpxFloorplanForm';
import MpxWizardForm from '~/tenants/mpx/ux/MpxWizardForm';
import MpxMatterportBundleConfig from '~/tenants/mpx/package/matterport/MpxMatterportBundleConfig';
import MpxZillowBundleConfig from '~/tenants/mpx/package/zillow/MpxZillowBundleConfig';
import MpxZillowBundleForm from '~/tenants/mpx/package/zillow/MpxZillowBundleForm';
import MpxMatterportBundleForm from '~/tenants/mpx/package/matterport/MpxMatterportBundleForm';
import MpxCustomer from '~/tenants/mpx/model/MpxCustomerData';

export default function registerMpx() {
  registerTenant(TenantId.METROPLEX_360, MpxOrderSchema, {
    name: 'Metroplex 360',
    domain: 'mpx.photology.co',
    logoRaster: '/tenants/mpx/logo.png',
    legal: 'TBD',
    email: 'orders@metroplex360.com',
    phone: '+18885573103',
    providerClass: MpxProvider,
    providers: MPX_PROVIDER_DATA,
    scheduleStartTime: '07:00',
    scheduleEndTime: '22:00',
    customerClass: MpxCustomer,
    locations: [
      {
        slug: 'dfw',
        name: 'Dallas',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '5729 lebanon rd frisco tx',
          city: 'Frisco',
          state: 'TX',
          zip: '75034',
          lat: 33.123267,
          long: -96.8262442,
        },
      },
      {
        slug: 'elp',
        name: 'EL Paso',
        timezone: TimeZone.US_MOUNTAIN,
        address: {
          line1: '5324 Quail Run',
          city: 'Frisco',
          state: 'TX',
          zip: '75034',
          lat: 33.123267,
          long: -96.8262442,
        },
      },
    ],
    // TODO: make typescript happy
    orderClass: MpxOrder,
    //nylas: 'fae1d94e-7ea6-4c81-9507-e107c1179c0d',
    packages: [
      {
        config: MpxMatterportBundleConfig,
        form: MpxMatterportBundleForm,
      },
      {
        config: MpxZillowBundleConfig,
        form: MpxZillowBundleForm,
      },
    ],
    customerSchema: {
      [FIRST_VERSION_TIMESTAMP]: z.object({ version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP) }),
    },
    forms: {
      orderConfigure: MpxOrderForm,
      wizardType: MpxWizardForm,
      provider: EmptyForm,
    },
    performables: {
      [MpxPhotosConfig.id]: {
        config: MpxPhotosConfig,
        job: MpxPhotosJob,
        form: MpxPhotosForm,
      },
      [MpxMicrositeConfig.id]: {
        config: MpxMicrositeConfig,
        job: MpxMicrositeJob,
        form: MpxMicrositeForm,
      },
      [MpxStreetConfig.id]: {
        config: MpxStreetConfig,
        job: MpxStreetJob,
        form: MpxStreetForm,
      },
      [MpxVideoConfig.id]: {
        config: MpxVideoConfig,
        job: MpxVideoJob,
        form: EmptyForm,
      },
      [MpxZillowConfig.id]: {
        config: MpxZillowConfig,
        job: MpxZillowJob,
        form: MpxZillowForm,
      },
      [MpxFloorplanConfig.id]: {
        config: MpxFloorplanConfig,
        job: MpxFloorplanJob,
        form: MpxFloorplanForm,
      },
      [MpxAerialConfig.id]: {
        config: MpxAerialConfig,
        job: MpxAerialJob,
        form: MpxAerialForm,
      },
      [MpxMatterportConfig.id]: {
        config: MpxMatterportConfig,
        job: MpxMatterportJob,
        form: MpxMatterportForm,
      },
    },
  });
}
