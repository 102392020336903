import NepJob from '~/tenants/nep/model/NepJob';
import Nep3dConfig, { Nep3dScanOptionId } from '~/tenants/nep/performable/3d/Nep3dConfig';
import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { NepOrderType } from '~/tenants/nep/model/NepOrder';

interface Nep3dScanOptions {
  onsite: number; // TODO: need official numbers
  amount: number;
  from: number;
  to: number;
}

export const DEMO_3D_OPTIONS: Record<Nep3dScanOptionId, Nep3dScanOptions> = {
  [Nep3dScanOptionId.ONE]: {
    onsite: 30,
    amount: 250,
    from: 0,
    to: 1000,
  },
  [Nep3dScanOptionId.TWO]: {
    onsite: 45,
    amount: 275,
    from: 1001,
    to: 2000,
  },
  [Nep3dScanOptionId.THREE]: {
    onsite: 60,
    amount: 285,
    from: 2001,
    to: 3000,
  },
  [Nep3dScanOptionId.FOUR]: {
    onsite: 60,
    amount: 300,
    from: 3001,
    to: 4000,
  },
  [Nep3dScanOptionId.FIVE]: {
    onsite: 90,
    amount: 350,
    from: 4001,
    to: 5000,
  },
  [Nep3dScanOptionId.SIX]: {
    onsite: 90,
    amount: 400,
    from: 5001,
    to: 6000,
  },
  [Nep3dScanOptionId.SEVEN]: {
    onsite: 120,
    amount: 450,
    from: 6001,
    to: 7000,
  },
  [Nep3dScanOptionId.EIGHT]: {
    onsite: 120,
    amount: 500,
    from: 7001,
    to: 8000,
  },
};

export default class Nep3dJob extends NepJob<typeof Nep3dConfig> {
  get performable() {
    return Nep3dConfig;
  }

  onsite(): number {
    return DEMO_3D_OPTIONS[this.metadata.option].onsite;
  }

  defaultValue(): ZodVersionedMetadata<(typeof Nep3dConfig)['schema']> {
    let option: undefined | Nep3dScanOptionId;

    for (const [key, value] of Object.entries(DEMO_3D_OPTIONS)) {
      if (this.order.metadata.sqft >= value.from && this.order.metadata.sqft <= value.to) {
        // TODO: better typing
        option = key as any as Nep3dScanOptionId;
        break;
      }
    }

    if (!option) {
      throw new Error('No option found for sqft range');
    }

    return {
      option,
      version: schema_latest_version(Nep3dConfig.schema),
    };
  }

  get configurable() {
    return false;
  }

  get hourly() {
    return this.order.metadata.type === NepOrderType.COMMERCIAL;
  }

  get multiple() {
    return this.order.metadata.type !== NepOrderType.COMMERCIAL;
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === NepOrderType.COMMERCIAL) {
      return [];
    }

    const option = DEMO_3D_OPTIONS[this.metadata.option];

    return [
      {
        amount: new Big(option.amount),
        description: this.performable.name,
        discountable: true,
        id: this.performable.id,
      },
    ];
  }
}
