import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiPhotosConfig, { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiJob from '~/tenants/mpi/model/MpiJob';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default class MpiPhotosJob extends MpiJob<typeof MpiPhotosConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiPhotosConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: MpiPhotosType.STANDARD,
    };
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  get configurable(): boolean {
    return true;
  }

  onsite(): number {
    return 60;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    lines.push({
      amount: new Big(220),
      description: 'Photos',
      id: 'photos',
    });

    return lines;
  }

  get performable() {
    return MpiPhotosConfig;
  }
}
