import React, { useEffect, useState } from 'react';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { Money } from '~/components/Money';
import NepPhotographyConfig, {
  NepPhotographyRealTwilightPrice,
  NepPhotographyVirtualTwilightPrice,
} from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import InputCheckbox from '~/components/input/InputCheckbox';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { PackageFormNested, usePackageFormContext } from '~/components/performable/PackageForm';
import { NepOrderContext } from '~/tenants/nep/model/NepOrder';
import { DEMO_PHOTOGRAPHY_OPTIONS } from '~/tenants/nep/performable/photos/NepPhotographyJob';
import { DEMO_FLOORPLANS_OPTIONS } from '~/tenants/nep/performable/floorplans/NepFloorplansJob';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import { TenantPackageInclude } from '~/tenants/common/package';
import Nep3dConfig from '~/tenants/nep/performable/3d/Nep3dConfig';
import { DEMO_3D_OPTIONS } from '~/tenants/nep/performable/3d/Nep3dJob';
import { DEMO_AERIAL_OPTIONS } from '~/tenants/nep/performable/aerial/NepAerialJob';
import NepAerialConfig from '~/tenants/nep/performable/aerial/NepAerialConfig';
import NepPhotosTwilight from '~/tenants/nep/performable/photos/NepPhotosTwilight';
import {
  NepPhotosCommunityCustom,
  NepPhotosCommunityLicense,
} from '~/tenants/nep/performable/photos/NepPhotosCommunity';

export function useNepPackagePhotoOption() {
  const { orderContext } = usePackageFormContext<NepOrderContext>();

  const photos = Object.entries(DEMO_PHOTOGRAPHY_OPTIONS).find(
    ([_id, pkg]) => orderContext.metadata.sqft <= pkg.to && orderContext.metadata.sqft >= pkg.from,
  );

  if (!photos) {
    throw new Error('No photography package found for sqft ' + orderContext.metadata.sqft);
  }

  return { id: photos[0], ...photos[1] };
}

export function useNepPackage3dOption() {
  const { orderContext, package: pkg } = usePackageFormContext<NepOrderContext>();

  const scan = Object.entries(DEMO_3D_OPTIONS).find(
    ([_id, pkg]) => orderContext.metadata.sqft <= pkg.to && orderContext.metadata.sqft >= pkg.from,
  );

  if (!scan) {
    throw new Error('No 3d package found for sqft ' + orderContext.metadata.sqft);
  }

  return scan[0];
}

export function useNepPackageAerialOption() {
  const { orderContext } = usePackageFormContext<NepOrderContext>();

  const aerial = Object.entries(DEMO_AERIAL_OPTIONS).find(([_id, pkg]) => orderContext.metadata.acres <= pkg.acres);

  if (!aerial) {
    throw new Error('No aerial package found for sqft ' + orderContext.metadata.sqft);
  }

  return aerial[0];
}

export function useNepPackageFloorplanOption() {
  const { orderContext } = usePackageFormContext<NepOrderContext>();

  const floorplans = Object.entries(DEMO_FLOORPLANS_OPTIONS).find(
    ([_id, pkg]) => orderContext.metadata.sqft <= pkg.to && orderContext.metadata.sqft >= pkg.from,
  );

  if (!floorplans) {
    throw new Error('No floorplans package found for sqft ' + orderContext.metadata.sqft);
  }

  return floorplans[0];
}

function NepPackageCommunityHidden(props: { enable: boolean }) {
  const [_name, value, setValue] = useZodFormFieldSingle('community');

  useEffect(() => {
    if (props.enable.toString() !== value) {
      setValue(props.enable.toString());
    }
  }, [props.enable]);

  return <ZodFieldHidden name="community" value={value} />;
}

export function NepPackageCommunityAddOn(props: { aerial?: boolean }) {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6">
      <div className="rounded-lg overflow-hidden flex-shrink-0">
        <LegacyResponsiveImage src="card/public/community.jpg" width={200} />
      </div>
      <div className="space-y-4">
        <PackageFormNested performable={NepPhotographyConfig}>
          <NepPhotosCommunityCustom />
          <NepPhotosCommunityLicense />
        </PackageFormNested>
        {props.aerial && (
          <PackageFormNested performable={NepAerialConfig}>
            <div>
              <div className="flex items-center leading-none select-none">
                <div className="w-8 flex-shrink-0">
                  <ZodFieldCheckbox id="aerial" name="community" />
                </div>
                <label className="text-base font-medium cursor-pointer w-full" htmlFor="aerial">
                  Aerial Community Photos
                </label>
                <p className="font-bold text-base">
                  <Money>60</Money>
                </p>
              </div>
              <div className="pl-8 pt-2 max-sm:pr-4">
                <p className="text-sm text-gray-600">
                  A high resolution example of directed shots that showcase your desired, local sites.
                </p>
              </div>
            </div>
          </PackageFormNested>
        )}
      </div>
    </div>
  );
}

export function NepPackagePhotoInclude() {
  const { photos } = useNepPackagePhotoOption();

  return (
    <TenantPackageInclude name={`${photos} Listing Photos`} image="tenant/nep/public/photos.jpg">
      {NepPhotographyConfig.short}
    </TenantPackageInclude>
  );
}

export function NepPackageFloorplanInclude() {
  return (
    <TenantPackageInclude name={NepFloorplansConfig.name} image="tenant/nep/public/floorplan.jpg">
      {NepFloorplansConfig.short}
    </TenantPackageInclude>
  );
}

export function NepPackageAerialInclude() {
  return (
    <TenantPackageInclude name={NepAerialConfig.name} image="tenant/nep/public/aerial.jpg">
      {NepAerialConfig.short}
    </TenantPackageInclude>
  );
}

export function NepPackage3dInclude() {
  return (
    <TenantPackageInclude name="3D Scan" image="tenant/nep/public/matterport.jpg">
      {Nep3dConfig.short}
    </TenantPackageInclude>
  );
}

export function NepPackagePhotoAddOns() {
  const { id } = useNepPackagePhotoOption();

  return (
    <PackageFormNested performable={NepPhotographyConfig}>
      <NepPhotosTwilight />
      <ZodFieldHidden name="option" value={id} />
    </PackageFormNested>
  );
}

export function NepPackage3dAddOns() {
  const option = useNepPackage3dOption();

  return (
    <PackageFormNested performable={Nep3dConfig}>
      <ZodFieldHidden name="option" value={option} />
    </PackageFormNested>
  );
}

export function NepPackageAerialAddOns() {
  const option = useNepPackageAerialOption();

  return (
    <PackageFormNested performable={NepAerialConfig}>
      <ZodFieldHidden name="option" value={option} />
    </PackageFormNested>
  );
}

export function NepPackageFloorplanAddOns() {
  const option = useNepPackageFloorplanOption();

  return (
    <PackageFormNested performable={NepFloorplansConfig}>
      <ZodFieldHidden name="option" value={option} />
    </PackageFormNested>
  );
}
