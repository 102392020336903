import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { TwtOrderContext, TwtOrderType } from '~/tenants/twt/model/TwtOrder';
import TwtAerialConfig from '~/tenants/twt/performable/aerial/TwtAerialConfig';

const TwtRentalAerialConfig: TenantPackageConfig = {
  id: 'rental_aerial',
  name: 'Photos + Floor Plan + Aerial',
  description:
    'Capture your property with HDR photography, a stylized floor plan, and aerial images/video all organized into a customized microsite.',
  performables: [TwtPhotoConfig, TwtFloorplanConfig, TwtAerialConfig],
  eligible: (context) =>
    (context as TwtOrderContext).metadata.type === TwtOrderType.LONG_TERM_RENTAL ||
    (context as TwtOrderContext).metadata.type === TwtOrderType.SHORT_TERM_RENTAL,
};

export default TwtRentalAerialConfig;
