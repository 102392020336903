import DemoFloorplansConfig, {
  DemoFloorPlansOptionId,
} from '~/tenants/demo/performable/floorplans/DemoFloorplansConfig';
import DemoJob from '~/tenants/demo/model/DemoJob';
import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { DemoOrderType } from '~/tenants/demo/model/DemoOrder';
import { DEMO_COMMERCIAL_COST, DEMO_COMMERCIAL_MINIMUM_MINUTES } from '~/tenants/demo/vars';
import Demo3dConfig, { Demo3dScanOptionId } from '~/tenants/demo/performable/3d/Demo3dConfig';

export interface DemoFloorPlansOptions {
  onsite: number; // TODO: need official numbers
  revenue: number;
  from: number;
  to: number;
}

export const DEMO_FLOORPLANS_OPTIONS: Record<DemoFloorPlansOptionId, DemoFloorPlansOptions> = {
  [DemoFloorPlansOptionId.ONE]: {
    onsite: 10,
    revenue: 60,
    from: 0,
    to: 1000,
  },
  [DemoFloorPlansOptionId.TWO]: {
    onsite: 10,
    revenue: 80,
    from: 1001,
    to: 2000,
  },
  [DemoFloorPlansOptionId.THREE]: {
    onsite: 15,
    revenue: 100,
    from: 2001,
    to: 3000,
  },
  [DemoFloorPlansOptionId.FOUR]: {
    onsite: 15,
    revenue: 120,
    from: 3001,
    to: 4000,
  },
  [DemoFloorPlansOptionId.FIVE]: {
    onsite: 20,
    revenue: 140,
    from: 4001,
    to: 5000,
  },
  [DemoFloorPlansOptionId.SIX]: {
    onsite: 20,
    revenue: 160,
    from: 5001,
    to: 6000,
  },
  [DemoFloorPlansOptionId.SEVEN]: {
    onsite: 30,
    revenue: 180,
    from: 6001,
    to: 7000,
  },
  [DemoFloorPlansOptionId.EIGHT]: {
    onsite: 45,
    revenue: 200,
    from: 7001,
    to: 8000,
  },
};

export default class DemoFloorplansJob extends DemoJob<typeof DemoFloorplansConfig> {
  get performable() {
    return DemoFloorplansConfig;
  }

  onsite(): number {
    return DEMO_FLOORPLANS_OPTIONS[this.metadata.option].onsite;
  }

  defaultValue(): ZodVersionedMetadata<(typeof DemoFloorplansConfig)['schema']> {
    let option: undefined | DemoFloorPlansOptionId = undefined;

    if (this.order.metadata.type === DemoOrderType.DRAFT) {
      return {
        option: DemoFloorPlansOptionId.ONE,
        version: schema_latest_version(Demo3dConfig.schema),
      };
    }

    for (const [key, value] of Object.entries(DEMO_FLOORPLANS_OPTIONS)) {
      if (this.order.metadata.sqft >= value.from && this.order.metadata.sqft <= value.to) {
        // TODO: better typing
        option = key as any as DemoFloorPlansOptionId;
        break;
      }
    }

    if (!option) {
      throw new Error('No option found for sqft range');
    }

    return {
      option,
      version: schema_latest_version(DemoFloorplansConfig.schema),
    };
  }

  get configurable() {
    return false;
  }

  get multiple() {
    return this.order.metadata.type !== DemoOrderType.COMMERCIAL;
  }

  get hourly() {
    return this.order.metadata.type === DemoOrderType.COMMERCIAL;
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === DemoOrderType.COMMERCIAL) {
      return [];
    }

    const option = DEMO_FLOORPLANS_OPTIONS[this.metadata.option];

    let amount = new Big(option.revenue);

    return [
      {
        amount,
        description: this.performable.name,
        id: this.performable.id,
        discountable: true,
      },
    ];
  }
}
