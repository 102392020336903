import React from 'react';
import { TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';
import { MpxPackagePhotoInclude } from '~/tenants/mpx/package/common';
import MpxZillowConfig from '~/tenants/mpx/performable/zillow/MpxZillowConfig';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { PackageFormNested } from '~/components/performable/PackageForm';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import MpxPhotosTwilight from '~/tenants/mpx/performable/photos/MpxPhotosTwilight';
import MpxPhotosStaging from '~/tenants/mpx/performable/photos/MpxPhotosStaging';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function MpxZillowBundleForm() {
  return (
    <>
      <TenantPackageIncludes>
        <MpxPackagePhotoInclude />
        <TenantPackageInclude name={MpxZillowConfig.name}>{MpxZillowConfig.short}</TenantPackageInclude>
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <PackageFormNested performable={MpxPhotosConfig}>
          <ZodFieldHidden name="type" />
          <ZodFieldHidden name="photos" />
          <MpxPhotosTwilight />
          <MpxPhotosStaging />
        </PackageFormNested>
        <PackageFormNested performable={MpxZillowConfig}>
          <FormHorizontal label="Floorplan">
            <ZodFieldCheckbox name="floorplan" />
          </FormHorizontal>
        </PackageFormNested>
      </TenantPerformableAddOns>
    </>
  );
}
