import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum BreSocialType {
  BASIC = 'basic',
  CLASSIC = 'classic',
  PREMIER = 'premier',
}

export default createPerformableConfig(
  'social',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      narration: coerceBoolean().optional(),
      type: z.nativeEnum(BreSocialType),
    }),
  },
  {
    name: 'Social Media Video',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
  },
);
