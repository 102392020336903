import DemoBrochuresConfig, {
  DemoBrochureShipping,
  DemoBrochuresOptionId,
  DemoBrochuresQuantity,
} from '~/tenants/demo/performable/brochures/DemoBrochuresConfig';
import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import DemoJob from '~/tenants/demo/model/DemoJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { DemoOrderType } from '~/tenants/demo/model/DemoOrder';

interface DemoBrochuresOptions {
  revenue: Record<DemoBrochuresQuantity, number>;
  pages: number;
  width: number;
  height: number;
}

export const DEMO_BROCHURES_OPTIONS: Record<DemoBrochuresOptionId, DemoBrochuresOptions> = {
  [DemoBrochuresOptionId.TWO]: {
    revenue: {
      [DemoBrochuresQuantity.QTY_25]: 95,
      [DemoBrochuresQuantity.QTY_50]: 125,
      [DemoBrochuresQuantity.QTY_100]: 175,
      [DemoBrochuresQuantity.QTY_250]: 225,
    },
    pages: 2,
    width: 8.5,
    height: 11,
  },
  [DemoBrochuresOptionId.FOUR]: {
    revenue: {
      [DemoBrochuresQuantity.QTY_25]: 105,
      [DemoBrochuresQuantity.QTY_50]: 150,
      [DemoBrochuresQuantity.QTY_100]: 200,
      [DemoBrochuresQuantity.QTY_250]: 300,
    },
    pages: 4,
    width: 11,
    height: 17,
  },
  [DemoBrochuresOptionId.EIGHT]: {
    revenue: {
      [DemoBrochuresQuantity.QTY_25]: 170,
      [DemoBrochuresQuantity.QTY_50]: 240,
      [DemoBrochuresQuantity.QTY_100]: 360,
      [DemoBrochuresQuantity.QTY_250]: 525,
    },
    pages: 8,
    width: 11,
    height: 17,
  },
  [DemoBrochuresOptionId.TWELVE]: {
    revenue: {
      [DemoBrochuresQuantity.QTY_25]: 225,
      [DemoBrochuresQuantity.QTY_50]: 300,
      [DemoBrochuresQuantity.QTY_100]: 500,
      [DemoBrochuresQuantity.QTY_250]: 750,
    },
    pages: 12,
    width: 11,
    height: 17,
  },
  [DemoBrochuresOptionId.SIXTEEN]: {
    revenue: {
      [DemoBrochuresQuantity.QTY_25]: 300,
      [DemoBrochuresQuantity.QTY_50]: 360,
      [DemoBrochuresQuantity.QTY_100]: 640,
      [DemoBrochuresQuantity.QTY_250]: 850,
    },
    pages: 16,
    width: 11,
    height: 17,
  },
};

export default class DemoBrochuresJob extends DemoJob<typeof DemoBrochuresConfig> {
  get performable() {
    return DemoBrochuresConfig;
  }

  get eligible() {
    return this.order.metadata.type !== DemoOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof DemoBrochuresConfig)['schema']> {
    return {
      option: DemoBrochuresOptionId.TWO,
      version: schema_latest_version(DemoBrochuresConfig.schema),
      quantity: DemoBrochuresQuantity.QTY_25,
    };
  }

  revenueLines(): TenantOrderLine[] {
    let amount = new Big(DEMO_BROCHURES_OPTIONS[this.metadata.option].revenue[this.metadata.quantity]);

    const lines: TenantOrderLine[] = [
      {
        amount,
        description: this.performable.name,
        id: this.performable.id,
      },
    ];

    if (this.metadata.shipping === DemoBrochureShipping.RUSH) {
      lines.push({
        amount: new Big('55'),
        description: 'Rush Shipping',
        id: 'rush',
      });
    } else if (this.metadata.shipping === DemoBrochureShipping.STANDARD) {
      lines.push({
        amount: new Big('22'),
        description: 'Standard Shipping',
        id: 'standard',
      });
    }

    return lines;
  }
}
