import BreJob from '~/tenants/bre/model/BreJob';
import BrePrintBrochuresConfig from '~/tenants/bre/performable/print/brochure/BrePrintBrochuresConfig';

export default class BrePrintBrochuresJob extends BreJob<typeof BrePrintBrochuresConfig> {
  get performable() {
    return BrePrintBrochuresConfig;
  }

  isDelivery(): boolean {
    return true;
  }
}
