import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import FormGroup from '~/components/form/FormGroup';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';

export default function MpxWizardForm() {
  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type">
        <ZodFieldSelect
          name="type"
          options={[
            { value: MpxOrderType.RESIDENTIAL, label: 'Residential Real-Estate / Realtors' },
            { value: MpxOrderType.MULTI_FAMILY, label: 'Multi-Family Apartment Management' },
            { value: MpxOrderType.BUILDERS, label: 'Home Builders & Developers' },
            { value: MpxOrderType.COMMERCIAL, label: 'Retail & Commercial Real-Estate' },
            { value: MpxOrderType.ARCHITECTURAL, label: 'Architects / CAD Professionals' },
            { value: MpxOrderType.INSURANCE, label: 'Insurance / XActimate' },
            { value: MpxOrderType.CUSTOM, label: 'Custom' },
          ]}
        />
      </FormHorizontal>
    </FormGroup>
  );
}
