import { TenantPackageConfig } from '~/tenants/common/registry';
import NepPhotographyConfig from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import Nep3dConfig from '~/tenants/nep/performable/3d/Nep3dConfig';
import NepAerialConfig from '~/tenants/nep/performable/aerial/NepAerialConfig';
import { NepOrderContext, NepOrderType } from '~/tenants/nep/model/NepOrder';

const NepPackagePremiumConfig: TenantPackageConfig = {
  id: 'premium',
  name: 'Premium',
  description:
    'Got a gem on your hands? Then this is the package for you! Our biggest savings package includes Photos, Floorplans, Aerial Photography and a 3D scan!.',
  performables: [NepPhotographyConfig, NepFloorplansConfig, Nep3dConfig, NepAerialConfig],
  percentage: 0.1,
  eligible: (context) => (context as NepOrderContext).metadata.type !== NepOrderType.COMMERCIAL,
};

export default NepPackagePremiumConfig;
