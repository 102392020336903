import { TenantPerformableAddOns } from '~/tenants/common/performable';
import TwtFloorplanUpgrade from '~/tenants/twt/performable/floorplan/TwtFloorplanUpgrade';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtDetached from '~/tenants/twt/forms/TwtDetached';

export default function TwtFloorplanForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <>
      <TwtDetached />
      <TenantPerformableAddOns persona={persona}>
        <TwtFloorplanUpgrade persona={persona} />
      </TenantPerformableAddOns>
    </>
  );
}
