import { TenantOrder } from '../../common/TenantOrder';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import Demo3dJob from '~/tenants/demo/performable/3d/Demo3dJob';
import DemoAerialJob from '~/tenants/demo/performable/aerial/DemoAerialJob';
import DemoFloorplansJob from '~/tenants/demo/performable/floorplans/DemoFloorplansJob';
import DemoPhotographyJob from '~/tenants/demo/performable/photos/DemoPhotographyJob';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import Demo3dConfig from '~/tenants/demo/performable/3d/Demo3dConfig';
import DemoAerialConfig from '~/tenants/demo/performable/aerial/DemoAerialConfig';
import DemoBrochuresConfig from '~/tenants/demo/performable/brochures/DemoBrochuresConfig';
import DemoEddmConfig from '~/tenants/demo/performable/eddm/DemoEddmConfig';
import DemoFloorplansConfig from '~/tenants/demo/performable/floorplans/DemoFloorplansConfig';
import DemoPhotographyConfig from '~/tenants/demo/performable/photos/DemoPhotographyConfig';
import DemoVirtualStagingConfig from '~/tenants/demo/performable/staging/DemoVirtualStagingConfig';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { DEMO_COMMERCIAL_COST, DEMO_COMMERCIAL_HOURLY_RATE } from '~/tenants/demo/vars';

export enum DemoOrderType {
  DRAFT = 'draft',
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
}

const common = {
  sqft: z.coerce
    .number({
      required_error: 'Please provide the square footage of the property or unit.',
      invalid_type_error: 'Please provide the square footage of the property or unit.',
    })
    .min(1, { message: 'The square footage of the property or unit must be greater than 1.' }),
  acres: z.coerce
    .number({
      required_error: 'Please provide the size (in acres) of the lot.',
      invalid_type_error: 'Please provide the size (in acres) of the lot.',
    })
    .min(0, { message: 'The size (in acres) of the lost must be greater than 0.' }),
};

const types = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(DemoOrderType.DRAFT),
  }),
  z.object({
    type: z.literal(DemoOrderType.RESIDENTIAL),
    multifamily: z.boolean().optional(),
    ...common,
  }),
  z.object({
    type: z.literal(DemoOrderType.COMMERCIAL),
    ...common,
  }),
]);

export const DemoOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    })
    .and(types),
};

export type DemoOrderMetadata = ZodVersionedMetadata<typeof DemoOrderSchema>;

export type DemoPerformableConfig =
  | typeof Demo3dConfig
  | typeof DemoAerialConfig
  | typeof DemoBrochuresConfig
  | typeof DemoEddmConfig
  | typeof DemoFloorplansConfig
  | typeof DemoPhotographyConfig
  | typeof DemoVirtualStagingConfig;

export type DemoOrderContext = TenantOrderContext<DemoOrderMetadata, DemoPerformableConfig>;

export class DemoOrder extends TenantOrder<DemoOrderContext> {
  info(): Array<DisplayData> {
    let type = 'Draft';

    if (this.context.metadata.type === DemoOrderType.RESIDENTIAL) {
      type = 'Residential';
    } else {
      type = 'Commercial';
    }

    const info = [
      ...super.info(),
      {
        name: 'Type',
        value: type,
      },
    ];

    if (this.context.metadata.type !== DemoOrderType.DRAFT) {
      info.push({
        name: 'Square Feet',
        value: this.context.metadata.sqft.toString(),
      });
      info.push({
        name: 'Acres',
        value: this.context.metadata.acres.toString(),
      });
    }

    return info;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.context.metadata.type === DemoOrderType.RESIDENTIAL) {
      if (this.context.address?.distance) {
        if (this.context.address?.distance > 45) {
          lines.push({
            id: 'fuel',
            description: 'Flat Fuel Charge (>45 miles)',
            amount: new Big('25.00'),
          });
        } else {
          lines.push({
            id: 'fuel',
            description: 'Flat Fuel Charge (<=45 miles)',
            amount: new Big('15.00'),
          });
        }
      }

      const hasPhoto = this.jobs.find((p) => p instanceof DemoPhotographyJob);
      const hasFloorplans = this.jobs.find((p) => p instanceof DemoFloorplansJob);
      const has3d = this.jobs.find((p) => p instanceof Demo3dJob);
      const hasAerial = this.jobs.find((p) => p instanceof DemoAerialJob);

      let packageDiscount = new Big(0);
      let packageTotal = new Big(0);

      if (hasPhoto && hasFloorplans) {
        packageTotal = hasPhoto.revenue(true).plus(hasFloorplans.revenue(true));

        if (has3d) {
          packageTotal = packageTotal.plus(has3d.revenue(true));

          if (hasAerial) {
            packageTotal = packageTotal.plus(hasAerial.revenue(true));

            packageDiscount = new Big('0.10');
          } else {
            packageDiscount = new Big('0.07');
          }
        } else {
          packageDiscount = new Big('0.05');
        }
      }

      if (packageDiscount.gt(0)) {
        lines.push({
          id: 'package',
          description: 'Package Discount',
          discount: true,
          amount: packageDiscount.times(packageTotal).times(-1),
        });
      }
    } else if (this.context.metadata.type === DemoOrderType.COMMERCIAL) {
      lines.push({
        id: 'minimum',
        description: '1 Hour Minimum',
        amount: new Big(DEMO_COMMERCIAL_HOURLY_RATE),
      });

      const onsite = this.jobs.reduce((sum, job) => sum + job.onsite(), 0);
      const additional = onsite - 60;

      if (additional > 0) {
        lines.push({
          id: 'additional',
          description: 'Additional Time',
          amount: new Big(DEMO_COMMERCIAL_COST(additional)),
        });
      }
    }

    return lines;
  }
}
