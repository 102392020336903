import Heading, { HeadingProps } from '~/components/layout/Heading';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import ImageCard from '~/components/layout/ImageCard';

export default function OrderWizardTypeCard(
  props: {
    value: string;
    image: string;
  } & Pick<HeadingProps, 'title' | 'children'>,
) {
  const [_name, value, setValue] = useZodFormFieldSingle('type');

  return (
    <ImageCard
      image={props.image}
      selected={value === props.value}
      onClick={() => {
        setValue(props.value);
      }}
    >
      <Heading title={props.title}>{props.children}</Heading>
    </ImageCard>
  );
}
