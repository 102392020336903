import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { Pic_FLOORPLANS_OPTIONS } from '~/tenants/pic/performable/floorplans/PicFloorplansJob';
import { PicOrderContext, PicOrderType } from '~/tenants/pic/model/PicOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';

export default function PicFloorplansForm({ context }: TenantPerformableFormProps<PicOrderContext>) {
  return (
    <FormHorizontal name="option" label="Option">
      <ZodFieldSelect
        name="option"
        options={Object.entries(Pic_FLOORPLANS_OPTIONS).map(([id, pkg]) => ({
          value: id,
          label: `(${pkg.from}-${pkg.to} sqft)`,
          disabled:
            context.metadata.type === PicOrderType.RESIDENTIAL &&
            (context.metadata.sqft > pkg.to || context.metadata.sqft < pkg.from),
        }))}
      />
    </FormHorizontal>
  );
}
