import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function MpxMicrositeForm() {
  return (
    <FormHorizontal label="Custom Domain">
      <ZodFieldInput name="domain" />
    </FormHorizontal>
  );
}
