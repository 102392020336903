import BreJob from '~/tenants/bre/model/BreJob';
import BrePrintFlyersConfig from '~/tenants/bre/performable/print/flyer/BrePrintFlyersConfig';

export default class BrePrintFlyersJob extends BreJob<typeof BrePrintFlyersConfig> {
  get performable() {
    return BrePrintFlyersConfig;
  }

  isDelivery(): boolean {
    return true;
  }
}
