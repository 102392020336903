import React from 'react';
import PerformableConfiguration from '~/components/performable/PerformableConfiguration';
import { FormHorizontal } from '~/components/form/layout';
import Card from '~/components/layout/Card';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DEMO_3D_OPTIONS } from '~/tenants/demo/performable/3d/Demo3dJob';
import { DemoOrderContext, DemoOrderType } from '~/tenants/demo/model/DemoOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';

export default function Demo3dForm({ context }: TenantPerformableFormProps<DemoOrderContext>) {
  const [name, matterport, setMatterport] = useZodFormFieldSingle('matterport');

  return (
    <>
      <PerformableConfiguration>
        <FormHorizontal name="option" label="Photo Package">
          <ZodFieldSelect
            name="option"
            options={Object.entries(DEMO_3D_OPTIONS).map(([id, pkg]) => ({
              value: id,
              label: `${pkg.from}-${pkg.to} sqft`,
              disabled:
                context.metadata.type === DemoOrderType.RESIDENTIAL &&
                (context.metadata.sqft > pkg.to || context.metadata.sqft < pkg.from),
            }))}
          />
        </FormHorizontal>
      </PerformableConfiguration>

      <input type="hidden" name={name} value={matterport} />

      <div className="w-full grid grid-cols-2 gap-related mt-6">
        <Card
          selected={matterport !== 'true'}
          onClick={() => {
            setMatterport('false');
          }}
        >
          <div className="font-medium">Standard</div>
          <p className="text-sm font-thin">Shot using iGuide.</p>
        </Card>

        <Card
          selected={matterport === 'true'}
          onClick={() => {
            setMatterport('true');
          }}
        >
          <div className="font-medium">Matterport</div>
          <p className="text-sm font-thin">Shot using Matterport.</p>
        </Card>
      </div>
    </>
  );
}
