import DemoAerialConfig, { DemoAerialPhotographyOptionId } from '~/tenants/demo/performable/aerial/DemoAerialConfig';
import DemoJob from '~/tenants/demo/model/DemoJob';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { DemoOrderType } from '~/tenants/demo/model/DemoOrder';
import Demo3dConfig, { Demo3dScanOptionId } from '~/tenants/demo/performable/3d/Demo3dConfig';

interface DemoAerialPhotographyOptions {
  revenue: number;
  photos: number;
  acres: number;
}

export const DEMO_AERIAL_OPTIONS: Record<DemoAerialPhotographyOptionId, DemoAerialPhotographyOptions> = {
  [DemoAerialPhotographyOptionId.ONE]: {
    revenue: 175,
    photos: 10,
    acres: 1,
  },
  [DemoAerialPhotographyOptionId.TWO]: {
    revenue: 225,
    photos: 15,
    acres: 2,
  },
  [DemoAerialPhotographyOptionId.THREE]: {
    revenue: 275,
    photos: 20,
    acres: 3,
  },
  [DemoAerialPhotographyOptionId.FOUR]: {
    revenue: 325,
    photos: 25,
    acres: 4,
  },
};

export default class DemoAerialJob extends DemoJob<typeof DemoAerialConfig> {
  get performable() {
    return DemoAerialConfig;
  }

  onsite(): number {
    return 30;
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.community) {
      info.push({ name: 'Community Photos', value: 'Yes' });
    }

    return info;
  }

  get configurable() {
    return this.order.metadata.type !== DemoOrderType.COMMERCIAL;
  }

  get multiple() {
    return this.order.metadata.type !== DemoOrderType.COMMERCIAL;
  }

  get hourly() {
    return this.order.metadata.type === DemoOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof DemoAerialConfig)['schema']> {
    let option = DemoAerialPhotographyOptionId.ONE;

    if (this.order.metadata.type !== DemoOrderType.DRAFT) {
      for (const [key, value] of Object.entries(DEMO_AERIAL_OPTIONS)) {
        if (this.order.metadata.acres <= Math.min(4, value.acres)) {
          // TODO: better typing
          option = key as any as DemoAerialPhotographyOptionId;
          break;
        }
      }
    }

    return {
      option,
      version: schema_latest_version(DemoAerialConfig.schema),
    };
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === DemoOrderType.COMMERCIAL) {
      return [];
    }

    let amount = new Big(DEMO_AERIAL_OPTIONS[this.metadata.option].revenue);

    const lines: TenantOrderLine[] = [
      {
        amount,
        description: this.performable.name,
        discountable: true,
        id: this.performable.id,
      },
    ];

    if (this.metadata.community) {
      lines.push({
        amount: new Big(60),
        description: 'Community Photos',
        id: 'community',
      });
    }

    return lines;
  }
}
