import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodNativeEnum } from '~/lib/zod';
import z from 'zod';

export enum MpxMatterportDevice {
  PRO2 = 'PRO2',
  PRO3 = 'PRO3',
}

/**
 * Scan for floor plan only, don't worry about lights, reflections or tidiness.   DO follow regular scan pattern. no 360's required.
 */

/**
 * Capture with Pro2 unless Pro3 is specifically requested.  Our Classic hosting account can not host Pro3 models.
 */
export default createPerformableConfig(
  'matterport',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
        device: zodNativeEnum(MpxMatterportDevice),
        interior: coerceBoolean().optional(),
        exterior: coerceBoolean().optional(),
        model: coerceBoolean().optional(),
        google: coerceBoolean().optional(),
        staging: z.coerce.number().optional(),
        floorplan: coerceBoolean().optional(),
        video: coerceBoolean().optional(),
        cad: coerceBoolean().optional(),
        trueplan: coerceBoolean().optional(),
        unit: z.string().optional(),

        // delivery
        url: z.string().optional(),
      })
      .and(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal('unit'),
            unit: z.string().min(1),
          }),
          z.object({
            type: z.literal('sqft'),
          }),
        ]),
      ),
  },
  {
    name: 'Matterport',
    short:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et nulla ornare, tempus erat non, consectetur nunc.',
    tenant: TenantId.METROPLEX_360,
    images: [],
  },
);
