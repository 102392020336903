import { TenantOrder } from '../../common/TenantOrder';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import Pic3dJob from '~/tenants/pic/performable/3d/Pic3dJob';
import PicAerialJob from '~/tenants/pic/performable/aerial/PicAerialJob';
import PicFloorplansJob from '~/tenants/pic/performable/floorplans/PicFloorplansJob';
import PicPhotographyJob from '~/tenants/pic/performable/photos/PicPhotographyJob';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import Pic3dConfig from '~/tenants/pic/performable/3d/Pic3dConfig';
import PicAerialConfig from '~/tenants/pic/performable/aerial/PicAerialConfig';
import PicBrochuresConfig from '~/tenants/pic/performable/brochures/PicBrochuresConfig';
import PicEddmConfig from '~/tenants/pic/performable/eddm/PicEddmConfig';
import PicFloorplansConfig from '~/tenants/pic/performable/floorplans/PicFloorplansConfig';
import PicPhotographyConfig from '~/tenants/pic/performable/photos/PicPhotographyConfig';
import PicVirtualStagingConfig from '~/tenants/pic/performable/staging/PicVirtualStagingConfig';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { Pic_COMMERCIAL_COST, Pic_COMMERCIAL_HOURLY_RATE } from '~/tenants/pic/vars';

export enum PicOrderType {
  DRAFT = 'draft',
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
}

const common = {
  sqft: z.coerce
    .number({
      required_error: 'Please provide the square footage of the property or unit.',
      invalid_type_error: 'Please provide the square footage of the property or unit.',
    })
    .min(1, { message: 'The square footage of the property or unit must be greater than 1.' }),
  acres: z.coerce
    .number({
      required_error: 'Please provide the size (in acres) of the lot.',
      invalid_type_error: 'Please provide the size (in acres) of the lot.',
    })
    .min(0, { message: 'The size (in acres) of the lost must be greater than 0.' }),
};

const types = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PicOrderType.DRAFT),
  }),
  z.object({
    type: z.literal(PicOrderType.RESIDENTIAL),
    multifamily: z.boolean().optional(),
    ...common,
  }),
  z.object({
    type: z.literal(PicOrderType.COMMERCIAL),
    ...common,
  }),
]);

export const PicOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    })
    .and(types),
};

export type PicOrderMetadata = ZodVersionedMetadata<typeof PicOrderSchema>;

export type PicPerformableConfig =
  | typeof Pic3dConfig
  | typeof PicAerialConfig
  | typeof PicBrochuresConfig
  | typeof PicEddmConfig
  | typeof PicFloorplansConfig
  | typeof PicPhotographyConfig
  | typeof PicVirtualStagingConfig;

export type PicOrderContext = TenantOrderContext<PicOrderMetadata, PicPerformableConfig>;

export class PicOrder extends TenantOrder<PicOrderContext> {
  info(): Array<DisplayData> {
    let type = 'Draft';

    if (this.context.metadata.type === PicOrderType.RESIDENTIAL) {
      type = 'Residential';
    } else {
      type = 'Commercial';
    }

    const info = [
      ...super.info(),
      {
        name: 'Type',
        value: type,
      },
    ];

    if (this.context.metadata.type !== PicOrderType.DRAFT) {
      info.push({
        name: 'Square Feet',
        value: this.context.metadata.sqft.toString(),
      });
      info.push({
        name: 'Acres',
        value: this.context.metadata.acres.toString(),
      });
    }

    return info;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.context.metadata.type === PicOrderType.RESIDENTIAL) {
      if (this.context.address?.distance) {
        if (this.context.address?.distance > 45) {
          lines.push({
            id: 'fuel',
            description: 'Flat Fuel Charge (>45 miles)',
            amount: new Big('25.00'),
          });
        } else {
          lines.push({
            id: 'fuel',
            description: 'Flat Fuel Charge (<=45 miles)',
            amount: new Big('15.00'),
          });
        }
      }

      const hasPhoto = this.jobs.find((p) => p instanceof PicPhotographyJob);
      const hasFloorplans = this.jobs.find((p) => p instanceof PicFloorplansJob);
      const has3d = this.jobs.find((p) => p instanceof Pic3dJob);
      const hasAerial = this.jobs.find((p) => p instanceof PicAerialJob);

      let packageDiscount = new Big(0);
      let packageTotal = new Big(0);

      if (hasPhoto && hasFloorplans) {
        packageTotal = hasPhoto.revenue(true).plus(hasFloorplans.revenue(true));

        if (has3d) {
          packageTotal = packageTotal.plus(has3d.revenue(true));

          if (hasAerial) {
            packageTotal = packageTotal.plus(hasAerial.revenue(true));

            packageDiscount = new Big('0.10');
          } else {
            packageDiscount = new Big('0.07');
          }
        } else {
          packageDiscount = new Big('0.05');
        }
      }

      if (packageDiscount.gt(0)) {
        lines.push({
          id: 'package',
          description: 'Package Discount',
          discount: true,
          amount: packageDiscount.times(packageTotal).times(-1),
        });
      }
    } else if (this.context.metadata.type === PicOrderType.COMMERCIAL) {
      lines.push({
        id: 'minimum',
        description: '1 Hour Minimum',
        amount: new Big(Pic_COMMERCIAL_HOURLY_RATE),
      });

      const onsite = this.jobs.reduce((sum, job) => sum + job.onsite(), 0);
      const additional = onsite - 60;

      if (additional > 0) {
        lines.push({
          id: 'additional',
          description: 'Additional Time',
          amount: new Big(Pic_COMMERCIAL_COST(additional)),
        });
      }
    }

    return lines;
  }
}
