import { FormVertical } from '~/components/form/layout';
import ZodFieldCheckboxMultiple from '~/components/zod/ZodFieldCheckboxMultiple';
import InputCheckbox from '~/components/input/InputCheckbox';
import InputTextarea from '~/components/input/InputTextarea';
import { useZodFormFieldMultiple } from '~/components/zod/ZodForm';
import { useState } from 'react';
import Message from '~/components/Message';

const options = ['Patios', 'Garage', 'Casita', 'Guest House', 'Pool House'];

export default function TwtDetached() {
  const [name, detached] = useZodFormFieldMultiple('detached');
  const other = detached.find((d) => !options.includes(d));
  const [showOther, setShowOther] = useState(Boolean(other));

  return (
    <FormVertical label="Please select any additional detached structures you would like scanned:">
      <div className="space-y-2">
        {options.map((option, index) => (
          <div key={option} className="flex items-center space-x-2">
            <ZodFieldCheckboxMultiple id={`detached-${index}`} name="detached" value={option} />
            <label htmlFor={`detached-${index}`}>{option}</label>
          </div>
        ))}
        <div className="flex items-center space-x-2">
          <InputCheckbox
            id="detached-other"
            checked={showOther}
            onChange={() => {
              setShowOther(!showOther);
            }}
          />
          <label htmlFor="detached-other">Other</label>
        </div>
        {showOther && <InputTextarea name={`${name}[${detached.length}]`} defaultValue={other} />}
        {(detached.length > 0 || showOther) && (
          <Message type="warning">
            Additional detached structures will increase the square footage of your scan and therefore may increase the
            price of your order.
          </Message>
        )}
      </div>
    </FormVertical>
  );
}
