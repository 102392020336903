import { TenantPackageConfig } from '~/tenants/common/registry';
import NepPhotographyConfig from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import Nep3dConfig from '~/tenants/nep/performable/3d/Nep3dConfig';
import { NepOrderContext, NepOrderType } from '~/tenants/nep/model/NepOrder';

const NepPackageStandardConfig: TenantPackageConfig = {
  id: 'standard',
  name: 'Standard',
  description: 'Same as our basic package, but includes a whole house 3D scan (interior only).',
  performables: [NepPhotographyConfig, NepFloorplansConfig, Nep3dConfig],
  percentage: 0.07,
  eligible: (context) => (context as NepOrderContext).metadata.type !== NepOrderType.COMMERCIAL,
};

export default NepPackageStandardConfig;
