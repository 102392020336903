import { TenantPackageConfig } from '~/tenants/common/registry';
import DemoPhotographyConfig from '~/tenants/demo/performable/photos/DemoPhotographyConfig';
import DemoFloorplansConfig from '~/tenants/demo/performable/floorplans/DemoFloorplansConfig';
import { DemoOrderContext, DemoOrderType } from '~/tenants/demo/model/DemoOrder';

const DemoPackageBasicConfig: TenantPackageConfig = {
  id: 'basic',
  name: 'Basic',
  description:
    'A great option for if you want a little more firepower than just Listing Photos! Includes floor plans and Listing Photos. Photo count depends on the square footage of the home. ',
  performables: [DemoPhotographyConfig, DemoFloorplansConfig],
  percentage: 0.05,
  eligible: (context) => (context as DemoOrderContext).metadata.type !== DemoOrderType.COMMERCIAL,
};

export default DemoPackageBasicConfig;
