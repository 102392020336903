import React from 'react';
import {
  DemoPackage3dAddOns,
  DemoPackage3dInclude,
  DemoPackageCommunityAddOn,
  DemoPackageFloorplanAddOns,
  DemoPackageFloorplanInclude,
  DemoPackagePhotoAddOns,
  DemoPackagePhotoInclude,
} from '~/tenants/demo/package/common';
import { TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function DemoPackageStandardForm() {
  return (
    <>
      <TenantPackageIncludes>
        <DemoPackagePhotoInclude />
        <DemoPackageFloorplanInclude />
        <DemoPackage3dInclude />
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <DemoPackagePhotoAddOns />
        <DemoPackageFloorplanAddOns />
        <DemoPackage3dAddOns />
        <DemoPackageCommunityAddOn />
      </TenantPerformableAddOns>
    </>
  );
}
