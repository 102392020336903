import { NepOrderType } from '~/tenants/nep/model/NepOrder';
import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';

export default function NepOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard
        value={NepOrderType.RESIDENTIAL}
        image="tenant/nep/public/residential.jpg"
        title="Residential"
      >
        Real Estate Listing Photography service for Single Family Homes, Condos, Townhouses and Apartments.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard value={NepOrderType.COMMERCIAL} image="tenant/nep/public/commercial.jpg" title="Commercial">
        Deliverables include photography captured onsite with basic image processing applied. Priced per hour. Best
        service for businesses, hotels, AirBNB, Rentals
      </OrderWizardTypeCard>
    </div>
  );
}
