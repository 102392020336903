import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';

const TWT_FLOORPLAN_STANDARD_NAME = 'Standard';

export const TWT_FLOORPLAN_UPGRADE_PLUS_AMOUNT = 15;
const TWT_FLOORPLAN_UPGRADE_PLUS_NAME = 'Plus Upgrade';

export const TWT_FLOORPLAN_UPGRADE_3D_AMOUNT = 30;
const TWT_FLOORPLAN_UPGRADE_3D_NAME = 'Plus 3D Upgrade';

export default class TwtFloorplanJob extends TwtJob<typeof TwtFloorplanConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof TwtFloorplanConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite() {
    // floorplan is generated from matterport
    return this.orderHasMatterport ? 0 : this.orderHasPhotos ? 10 : 30;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.FLOORPLAN;
  }

  get submission(): boolean {
    return true;
  }

  get performable() {
    return TwtFloorplanConfig;
  }

  get configurable() {
    return !this.orderHasMatterport;
  }

  get media() {
    return { persona: PersonaType.OFFICE, microsite: MicrositeFileType.FLOORPLAN };
  }

  get eligible(): boolean {
    return this.order.metadata.subtype !== TwtPropertyType.LAND && !this.orderHasMatterport;
  }

  info(): DisplayData[] {
    const info = super.info();

    let type = TWT_FLOORPLAN_STANDARD_NAME;

    if (this.metadata.upgrade === 'plus') {
      type = TWT_FLOORPLAN_UPGRADE_PLUS_NAME;
    } else if (this.metadata.upgrade === '3d') {
      type = TWT_FLOORPLAN_UPGRADE_3D_NAME;
    }

    if (this.metadata.detached && this.metadata.detached.length > 0) {
      info.push({
        name: 'Detached Structures',
        value: this.metadata.detached.join(','),
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    return [
      ...info,
      { name: 'Type', value: type, provider: true, schedule: true },
    ];
  }

  expenseLines(): TenantOrderLine[] {
    if (this.orderHasMatterport) {
      return [];
    }

    const lines = super.expenseLines();

    let amount;

    if (this.orderHasPhotos) {
      if (this.sqft >= 7500) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(70);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(50);
        } else {
          amount = new Big(25);
        }
      } else if (this.sqft >= 5000) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(70);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(50);
        } else {
          amount = new Big(25);
        }
      } else {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(70);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(50);
        } else {
          amount = new Big(25);
        }
      }
    } else {
      if (this.sqft >= 7500) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(90);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(75);
        } else {
          amount = new Big(50);
        }
      } else if (this.sqft >= 5000) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(90);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(75);
        } else {
          amount = new Big(50);
        }
      } else {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(90);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(75);
        } else {
          amount = new Big(50);
        }
      }
    }

    if (amount.gt(0)) {
      let upgrade = TWT_FLOORPLAN_STANDARD_NAME;

      if (this.metadata.upgrade === 'plus') {
        upgrade = TWT_FLOORPLAN_UPGRADE_PLUS_NAME;
      } else if (this.metadata.upgrade === '3d') {
        upgrade = TWT_FLOORPLAN_UPGRADE_3D_NAME;
      }

      lines.push({
        amount,
        description: `2D Floorplan (${upgrade})`,
        id: 'floorplan',
      });
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    if (this.orderHasMatterport) {
      return [];
    }

    const lines = super.revenueLines();

    let amount;

    if (this.orderHasPhotos) {
      if (this.sqft >= 7500) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(254);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(189);
        } else {
          amount = new Big(114);
        }
      } else if (this.sqft >= 5000) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(199);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(129);
        } else {
          amount = new Big(54);
        }
      } else {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(179);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(114);
        } else {
          amount = new Big(39);
        }
      }
    } else {
      if (this.sqft >= 7500) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(284);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(224);
        } else {
          amount = new Big(149);
        }
      } else if (this.sqft >= 5000) {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(229);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(169);
        } else {
          amount = new Big(94);
        }
      } else {
        if (this.metadata.upgrade === '3d') {
          amount = new Big(174);
        } else if (this.metadata.upgrade === 'plus') {
          amount = new Big(114);
        } else {
          amount = new Big(74);
        }
      }
    }

    if (amount.gt(0)) {
      let upgrade = TWT_FLOORPLAN_STANDARD_NAME;

      if (this.metadata.upgrade === 'plus') {
        upgrade = TWT_FLOORPLAN_UPGRADE_PLUS_NAME;
      } else if (this.metadata.upgrade === '3d') {
        upgrade = TWT_FLOORPLAN_UPGRADE_3D_NAME;
      }

      lines.push({
        amount,
        description: `2D Floorplan (${upgrade})`,
        id: 'floorplan',
        taxable: true,
        discountable: true,
      });
    }

    return lines;
  }
}
