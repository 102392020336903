import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { MpiCustomerMetadata } from '~/tenants/mpi/model/MpiCustomer';
import { TenantOrder } from '~/tenants/common/TenantOrder';

export enum MpiOrderType {
  CUSTOM = 'custom',
  EVENT = 'event',
  COMMERCIAL = 'commercial',
  RESIDENTIAL = 'residential',
}

export const MpiOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({ type: z.literal(MpiOrderType.CUSTOM) }),
        z.object({
          type: z.literal(MpiOrderType.RESIDENTIAL),
          sqft: z.coerce.number(),
        }),
        z.object({ type: z.literal(MpiOrderType.EVENT) }),
        z.object({
          type: z.literal(MpiOrderType.COMMERCIAL),
          sqft: z.coerce.number(),
        }),
      ]),
    ),
};

export type MpiOrderMetadata = ZodVersionedMetadata<typeof MpiOrderSchema>;

export type MpiPerformableConfig = typeof MpiPhotosConfig;

export type MpiOrderContext = TenantOrderContext<MpiOrderMetadata, MpiPerformableConfig, MpiCustomerMetadata>;

export class MpiOrder extends TenantOrder<MpiOrderContext> {}
