import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'slideshow',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      //template: z.union([z.literal('bubbles'), z.literal('basic')]),
    }),
  },
  {
    name: 'Social Media Slideshow',
    short: 'DESCRIPTION HERE',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/video/thumbnail.png',
    images: [],
  },
);
