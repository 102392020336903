import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DEMO_PHOTOGRAPHY_OPTIONS } from '~/tenants/demo/performable/photos/DemoPhotographyJob';
import { DemoOrderContext, DemoOrderType } from '~/tenants/demo/model/DemoOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import DemoPhotosTwilight from '~/tenants/demo/performable/photos/DemoPhotosTwilight';
import { TenantPerformableAddOns, TenantPerformableConfiguration } from '~/tenants/common/performable';
import DemoPhotosCommunity from '~/tenants/demo/performable/photos/DemoPhotosCommunity';

export default function DemoPhotographyForm({ context, persona }: TenantPerformableFormProps<DemoOrderContext>) {
  const commercial = context.metadata.type === DemoOrderType.COMMERCIAL;

  return (
    <>
      <TenantPerformableConfiguration>
        <FormHorizontal name="option" label="Photo Package">
          <ZodFieldSelect
            name="option"
            options={Object.entries(DEMO_PHOTOGRAPHY_OPTIONS).map(([id, pkg]) => ({
              value: id,
              label: `Up to ${pkg.photos} photos (${pkg.from}-${pkg.to} sqft)`,
              disabled:
                context.metadata.type !== DemoOrderType.DRAFT &&
                (context.metadata.sqft > pkg.to || context.metadata.sqft < pkg.from),
            }))}
          />
        </FormHorizontal>
      </TenantPerformableConfiguration>

      <TenantPerformableAddOns persona={persona}>
        <DemoPhotosTwilight commercial={commercial} />
        {!commercial && <DemoPhotosCommunity />}
      </TenantPerformableAddOns>
    </>
  );
}
