import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';

export default function TwtProviderForm() {
  return (
    <FormGroup>
      <FormHorizontal name="elevated" label="Elevated">
        <ZodFieldCheckbox name="elevated" />
      </FormHorizontal>
    </FormGroup>
  );
}
