import { Big } from 'big.js';

export const Pic_COMMERCIAL_HOURLY_RATE = 400;
export const Pic_COMMERCIAL_MINIMUM_MINUTES = 0;
export const Pic_PROVIDER_A = 'provider-a';
export const Pic_PROVIDER_B = 'provider-b';
export const Pic_LOCATION = 'loc';

export function Pic_COMMERCIAL_COST(minutes: number) {
  return new Big(Math.ceil(minutes / 10) * 10).div(60).times(Pic_COMMERCIAL_HOURLY_RATE);
}
