import React from 'react';
import { TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';
import { MpxPackagePhotoInclude } from '~/tenants/mpx/package/common';
import MpxMatterportConfig from '~/tenants/mpx/performable/matterport/MpxMatterportConfig';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { PackageFormNested } from '~/components/performable/PackageForm';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import MpxPhotosTwilight from '~/tenants/mpx/performable/photos/MpxPhotosTwilight';
import MpxPhotosStaging from '~/tenants/mpx/performable/photos/MpxPhotosStaging';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function MpxMatterportBundleForm() {
  return (
    <>
      <TenantPackageIncludes>
        <MpxPackagePhotoInclude />
        <TenantPackageInclude name={MpxMatterportConfig.name}>{MpxMatterportConfig.short}</TenantPackageInclude>
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <PackageFormNested performable={MpxPhotosConfig}>
          <ZodFieldHidden name="type" />
          <ZodFieldHidden name="photos" />
          <MpxPhotosTwilight />
          <MpxPhotosStaging />
        </PackageFormNested>
        <PackageFormNested performable={MpxMatterportConfig}>
          <ZodFieldHidden name="type" />
          <ZodFieldHidden name="device" />

          <FormGroup>
            <FormHorizontal label="Floorplans" name="floorplan">
              <ZodFieldCheckbox name="floorplan" />
            </FormHorizontal>

            <FormHorizontal label="Walkthrough Video" name="video">
              <ZodFieldCheckbox name="video" />
            </FormHorizontal>
          </FormGroup>
        </PackageFormNested>
      </TenantPerformableAddOns>
    </>
  );
}
