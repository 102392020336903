import MpxJob from '~/tenants/mpx/model/MpxJob';
import { TenantJobLine } from '~/lib/model';
import MpxMicrositeConfig from '~/tenants/mpx/performable/microsite/MpxMicrositeConfig';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';

export default class MpxMicrositeJob extends MpxJob<typeof MpxMicrositeConfig> {
  get performable() {
    return MpxMicrositeConfig;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxMicrositeConfig)['schema']> {
    return { version: FIRST_VERSION_TIMESTAMP };
  }

  get eligible(): boolean {
    return this.order.metadata.type === MpxOrderType.RESIDENTIAL;
  }

  get configurable(): boolean {
    return true;
  }

  revenueLines() {
    const lines: TenantJobLine[] = [];

    lines.push({
      description: 'Single Page Property Website',
      amount: new Big(55),
      taxable: true,
      id: 'microsite',
    });

    if (this.metadata.domain) {
      lines.push({
        description: 'Custom Domain',
        amount: new Big(15),
        taxable: true,
        id: 'domain',
      });
    }

    return lines;
  }
}
