import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { FormHorizontal } from '~/components/form/layout';
import React from 'react';

export default function TwtStagingForm() {
  return (
    <FormHorizontal label="# of Photos">
      <ZodFieldInput type="number" name="photos" />
    </FormHorizontal>
  );
}
