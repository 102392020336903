import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';

export default function MpiPhotosForm() {
  return (
    <div>
      <ZodFieldHidden name="type" value={MpiPhotosType.STANDARD} />
    </div>
  );
}
