import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'flyers',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      design: coerceBoolean(),
      print: coerceBoolean(),
      copywriting: coerceBoolean(),
    }),
  },
  {
    name: 'Flyers',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
  },
);
