import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { TWT_REEL_VOICEOVER } from '~/tenants/twt/performable/reel/TwtReelJob';

export default function TwtReelForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <>
      <TenantPackageAddOnCheckbox
        name="voiceover"
        persona={persona}
        image="tenant/twt/cameo/thumbnail.jpeg"
        title="Agent Cameo / Voiceover"
        cost={TWT_REEL_VOICEOVER}
        description="Add a personal touch to your video with an on screen cameo or a descriptive voiceover."
      />
    </>
  );
}
