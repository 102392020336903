import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

interface BreMatterportTier {
  tags?: number;
  sqft: number;
  revenue: number;
  expense: number;
  onsite: number;
}

const TIER_RESIDENTIAL: BreMatterportTier[] = [
  {
    sqft: 1499,
    tags: 15,
    revenue: 300,
    expense: 195,
    onsite: 60,
  },
  {
    sqft: 1999,
    tags: 15,
    revenue: 350,
    expense: 228,
    onsite: 60,
  },
  {
    sqft: 2499,
    tags: 15,
    revenue: 400,
    expense: 260,
    onsite: 60,
  },
  {
    sqft: 2999,
    tags: 30,
    revenue: 450,
    expense: 293,
    onsite: 60,
  },
  {
    sqft: 3999,
    tags: 30,
    revenue: 500,
    expense: 325,
    onsite: 60,
  },
];

const TIER_COMMERCIAL: BreMatterportTier[] = [
  {
    sqft: 2499,
    revenue: 500,
    expense: 260,
    onsite: 60,
  },
  {
    sqft: 3999,
    revenue: 650,
    expense: 293,
    onsite: 60,
  },
  {
    sqft: 5999,
    revenue: 800,
    expense: 390,
    onsite: 90,
  },
  {
    sqft: 7999,
    revenue: 950,
    expense: 455,
    onsite: 105,
  },
  {
    sqft: 9999,
    revenue: 1050,
    expense: 520,
    onsite: 120,
  },
];

export default class BreMatterportJob extends BreJob<typeof BreMatterportConfig> {
  get configurable(): boolean {
    return true;
  }

  onsite(): number {
    let onsite = 0;

    if (this.order.metadata.type === BreOrderType.PRINT) {
      return onsite;
    }

    let tiers: BreMatterportTier[] = [];

    if (this.order.metadata.type === BreOrderType.COMMERCIAL) {
      tiers = TIER_COMMERCIAL;
    }

    if (this.order.metadata.type === BreOrderType.LISTING) {
      tiers = TIER_RESIDENTIAL;
    }

    for (const tier of tiers) {
      if (this.order.metadata.sqft <= tier.sqft) {
        onsite += tier.onsite;

        break;
      }
    }

    if (this.metadata.outdoor) {
      if (this.metadata.outdoor === 'both') {
        if (this.order.metadata.sqft < 8000) {
          onsite += 45;
        } else {
          onsite += 60;
        }
      } else {
        onsite += 30;
      }
    }

    return onsite;
  }

  expenseLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.order.metadata.type === BreOrderType.PRINT) {
      return lines;
    }

    let amount = new Big(0);

    let tiers: BreMatterportTier[] = [];

    if (this.order.metadata.type === BreOrderType.COMMERCIAL) {
      tiers = TIER_COMMERCIAL;
    }

    if (this.order.metadata.type === BreOrderType.LISTING) {
      tiers = TIER_RESIDENTIAL;
    }

    for (const tier of tiers) {
      if (this.order.metadata.sqft <= tier.sqft) {
        amount = new Big(tier.expense);

        break;
      }
    }

    if (amount.gt(0)) {
      lines.push({
        amount,
        description: 'Matterport',
        id: 'sqft',
      });
    }

    if (this.metadata.floorplan) {
      lines.push({
        amount: new Big(50),
        description: 'Floorplan',
        id: 'floorplan',
      });
    }

    if (this.metadata.outdoor) {
      let amount = new Big(125);
      let description = `Outdoor Matterport (${this.metadata.outdoor})`;

      if (this.metadata.outdoor === 'both') {
        if (this.order.metadata.sqft < 8000) {
          amount = new Big(150);
        } else {
          amount = new Big(300);
        }
      }

      lines.push({
        amount,
        description,
        id: 'outdoor',
      });
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.order.metadata.type === BreOrderType.PRINT) {
      return lines;
    }

    let amount = new Big(0);

    let tiers: BreMatterportTier[] = [];

    if (this.order.metadata.type === BreOrderType.COMMERCIAL) {
      tiers = TIER_COMMERCIAL;
    }

    if (this.order.metadata.type === BreOrderType.LISTING) {
      tiers = TIER_RESIDENTIAL;
    }

    for (const tier of tiers) {
      if (this.order.metadata.sqft <= tier.sqft) {
        amount = new Big(tier.revenue);

        break;
      }
    }

    if (amount.gt(0)) {
      lines.push({
        amount,
        description: 'Matterport',
        id: 'sqft',
      });
    }

    if (this.metadata.floorplan) {
      lines.push({
        amount: new Big(this.order.metadata.type === BreOrderType.COMMERCIAL ? 150 : 100),
        description: 'Floorplan',
        id: 'floorplan',
      });
    }

    if (this.metadata.outdoor) {
      let amount = new Big(200);
      let description = `Outdoor Matterport (${this.metadata.outdoor})`;

      if (this.metadata.outdoor === 'both') {
        if (this.order.metadata.sqft < 8000) {
          amount = new Big(259);
        } else {
          amount = new Big(450);
        }
      }

      lines.push({
        amount,
        description,
        id: 'outdoor',
      });
    }

    return lines;
  }

  get performable() {
    return BreMatterportConfig;
  }
}
