import { registerTenant, TenantId } from '~/tenants/common/registry';
import { BreOrderSchema } from '~/tenants/bre/model/BreOrderContext';
import { BreOrder } from '~/tenants/bre/model/BreOrder';
import { TimeZone } from '~/lib/enum';
import BreProvider from '~/tenants/bre/model/BreProvider';
import BreOrderForm from '~/tenants/bre/ux/BreOrderForm';
import z from 'zod';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import BreCustomer from '~/tenants/bre/model/BreCustomer';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreAerialForm from '~/tenants/bre/performable/aerial/BreAerialForm';
import { BRE_PROVIDER_DATA } from '~/tenants/bre/data';

export default function registerBre() {
  registerTenant(TenantId.BEYOND_RE_MARKETING, BreOrderSchema, {
    name: 'Beyond RE Marketing',
    domain: 'bre.photology.co',
    logoRaster: 'https://optimize.photology.co/tenant/bre/logo.png',
    legal: 'TBD',
    phone: '+15104409153',
    email: 'bre@photology.co',
    emailEnable: true,
    background: '#402040',
    scheduleStartTime: '08:00',
    scheduleEndTime: '20:00',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    customerClass: BreCustomer,
    locations: [
      {
        slug: 'sjc',
        name: 'San Jose',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '15495 Los Gatos Boulevard',
          line2: null,
          city: 'Los Gatos',
          state: 'CA',
          zip: '95032',
          long: -121.9607408,
          lat: 37.2442195,
        },
      },
    ],
    // TODO: make typescript happy
    orderClass: BreOrder,
    packages: [],
    providerClass: BreProvider,
    providers: BRE_PROVIDER_DATA,
    customerSchema: {
      [FIRST_VERSION_TIMESTAMP]: z.object({ version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP) }),
    },
    forms: {
      orderConfigure: BreOrderForm,
      wizardType: BreOrderForm,
      provider: EmptyForm,
    },
    performables: {
      [BreAerialConfig.id]: {
        config: BreAerialConfig,
        job: BreAerialJob,
        form: BreAerialForm,
      },
    },
  });
}
