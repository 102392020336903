import React from 'react';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TwtRentalIncludes, TwtRentalPhotos } from '~/tenants/twt/package/rental/common';
import { PackageFormNested } from '~/components/performable/PackageForm';
import TwtAerialConfig, { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import TwtAerialAddOns from '~/tenants/twt/performable/aerial/TwtAerialAddOns';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function TwtRentalAerialForm() {
  return (
    <>
      <TwtRentalIncludes aerial />
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <TwtRentalPhotos />
        <PackageFormNested performable={TwtAerialConfig}>
          <ZodFieldHidden name="type" value={TwtAerialType.PHOTO_MINI} />
          <TwtAerialAddOns persona={PersonaType.CUSTOMER} />
        </PackageFormNested>
      </TenantPerformableAddOns>
    </>
  );
}
