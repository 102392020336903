import { ReactNode } from 'react';
import Divider from '~/components/layout/Divider';

export default function PerformableConfiguration(props: { children: ReactNode }) {
  return (
    <>
      <h2 className="text-lg font-medium text-gray-500 pb-3">Configuration</h2>

      <div className="space-y-8 w-full">{props.children}</div>
    </>
  );
}
