import Heading from '~/components/layout/Heading';
import FormGroup from '~/components/form/FormGroup';
import ValibotFormHorizontal from '~/components/valibot/ValibotFormHorizontal';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';

export default function TwtOnboardingForm() {
  return (
    <div className="space-y-4 border-t border-theme-separator pt-8">
      <Heading title="Delivery Address (Optional)">
        We like to send appreciation gifts to our customers from time to time. Please provide your delivery address if
        you would like to receive these gifts.
      </Heading>

      <FormGroup>
        <ValibotFormHorizontal name="address" label="Address">
          <ValibotFieldInput name="address" />
        </ValibotFormHorizontal>
      </FormGroup>
    </div>
  );
}
