import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpxOrderContext, MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import React from 'react';

export default function MpxZillowForm({ context }: TenantPerformableFormProps<MpxOrderContext>) {
  if (context.metadata.type === MpxOrderType.BUILDERS) {
    return <div></div>;
  }

  return (
    <FormHorizontal label="Floorplan">
      <ZodFieldCheckbox name="floorplan" />
    </FormHorizontal>
  );
}
