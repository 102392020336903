import NepPhotographyConfig, {
  NepPhotographyOptionId,
  NepPhotographyTwilightCommercialTime,
} from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import NepJob from '~/tenants/nep/model/NepJob';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { NepOrderType } from '~/tenants/nep/model/NepOrder';

export interface NepPhotographyOptions {
  onsite: number;
  revenue: number;
  photos: number;
  from: number;
  to: number;
  tour: boolean;
}

export const DEMO_PHOTOGRAPHY_OPTIONS: Record<NepPhotographyOptionId, NepPhotographyOptions> = {
  [NepPhotographyOptionId.ONE]: {
    onsite: 30,
    revenue: 150,
    photos: 20,
    from: 0,
    to: 1000,
    tour: false,
  },
  [NepPhotographyOptionId.TWO]: {
    onsite: 40,
    revenue: 200,
    photos: 35,
    from: 1001,
    to: 2000,
    tour: false,
  },
  [NepPhotographyOptionId.THREE]: {
    onsite: 45,
    revenue: 250,
    photos: 45,
    from: 2001,
    to: 3000,
    tour: false,
  },
  [NepPhotographyOptionId.FOUR]: {
    onsite: 45,
    revenue: 300,
    photos: 50,
    from: 3001,
    to: 4000,
    tour: true,
  },
  [NepPhotographyOptionId.FIVE]: {
    onsite: 60,
    revenue: 350,
    photos: 55,
    from: 4001,
    to: 5000,
    tour: true,
  },
  [NepPhotographyOptionId.SIX]: {
    onsite: 60,
    revenue: 400,
    photos: 65,
    from: 5001,
    to: 6000,
    tour: true,
  },
  [NepPhotographyOptionId.SEVEN]: {
    onsite: 90,
    revenue: 450,
    photos: 75,
    from: 6001,
    to: 7000,
    tour: true,
  },
  [NepPhotographyOptionId.EIGHT]: {
    onsite: 90,
    revenue: 500,
    photos: 75,
    from: 7001,
    to: 8000,
    tour: true,
  },
};

export default class NepPhotographyJob extends NepJob<typeof NepPhotographyConfig> {
  get performable() {
    return NepPhotographyConfig;
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.real_twilight) {
      info.push({ name: 'Twilight', value: 'Real' });
    } else if (this.metadata.virtual_twilight) {
      info.push({ name: 'Twilight', value: 'Virtual' });
    }

    if (this.metadata.community) {
      info.push({ name: 'Community Photos', value: 'Yes' });
    }

    return info;
  }

  onsite(): number {
    let onsite = DEMO_PHOTOGRAPHY_OPTIONS[this.metadata.option].onsite;

    if (this.metadata.real_twilight) {
      if (this.order.metadata.type === NepOrderType.COMMERCIAL) {
        onsite += NepPhotographyTwilightCommercialTime;
      } else {
        onsite += 30;
      }
    }

    return onsite;
  }

  defaultValue(): ZodVersionedMetadata<(typeof NepPhotographyConfig)['schema']> {
    let option: undefined | NepPhotographyOptionId = undefined;

    for (const [key, value] of Object.entries(DEMO_PHOTOGRAPHY_OPTIONS)) {
      if (this.order.metadata.sqft >= value.from && this.order.metadata.sqft <= value.to) {
        // TODO: better typing
        option = key as any as NepPhotographyOptionId;
        break;
      }
    }

    if (!option) {
      throw new Error('No option found for sqft range');
    }

    return {
      option,
      version: schema_latest_version(NepPhotographyConfig.schema),
    };
  }

  get multiple() {
    return this.order.metadata.type !== NepOrderType.COMMERCIAL;
  }

  get hourly() {
    return this.order.metadata.type === NepOrderType.COMMERCIAL;
  }

  get configurable() {
    return true;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.order.metadata.type !== NepOrderType.COMMERCIAL) {
      const option = DEMO_PHOTOGRAPHY_OPTIONS[this.metadata.option];

      let amount = new Big(option.revenue);

      lines.push({
        amount,
        description: `Up to ${option.photos} photos`,
        id: 'option',
        discountable: true,
      });
    }

    if (typeof this.metadata.virtual_twilight === 'number' && this.metadata.virtual_twilight > 0) {
      lines.push({
        amount: new Big(this.metadata.virtual_twilight).times(25),
        description: `Virtual Twilight Photos (${this.metadata.virtual_twilight})`,
        id: 'twilight',
      });
    } else if (this.metadata.real_twilight) {
      lines.push({
        amount: new Big(200),
        description: 'Authentic Twilight Photos',
        id: 'twilight',
      });
    }

    if (this.metadata.community) {
      lines.push({
        amount: new Big(60),
        description: 'Custom Community Photos',
        id: 'community',
      });
    }

    if (this.metadata.license) {
      lines.push({
        amount: new Big(10).times(this.metadata.license.length),
        description: `Community Photos (${this.metadata.license.length})`,
        id: 'license',
      });
    }

    return lines;
  }
}
