import { TenantProvider } from '../../common/TenantPersona';
import z from 'zod';
import { MpxCustomerSchema } from '~/tenants/mpx/model/MpxCustomerData';
import MpxAerialConfig from '~/tenants/mpx/performable/aerial/MpxAerialConfig';
import MpxVideoConfig from '~/tenants/mpx/performable/video/MpxVideoConfig';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import MpxMatterportConfig from '~/tenants/mpx/performable/matterport/MpxMatterportConfig';
import MpxFloorplanConfig from '~/tenants/mpx/performable/floorplan/MpxFloorplanConfig';

export enum MpxProviderId {
  EMILY_VILLAGRANA = 1,
  MICHAEL_SCHULTE = 2,
  CHRISTOPHER_COOK = 3,
}

export interface MpxProviderStaticData {
  equipment_mppro2?: true;
  equipment_mppro3?: true;
}

export const MpxProviderSchema = z.object({
  aerial_part_107: z.string().url().optional(),
});

export type MpxProviderMetadata = z.output<typeof MpxCustomerSchema>;

export default class MpxProvider extends TenantProvider<MpxProviderMetadata> {
  get performableIds(): string[] {
    return [
      MpxAerialConfig.id,
      MpxVideoConfig.id,
      MpxPhotosConfig.id,
      MpxMatterportConfig.id,
      MpxFloorplanConfig.id,
    ];
  }
}
