import { TenantJob } from '~/tenants/common/TenantJob';
import { BreOrderContext, BrePerformableConfig } from '~/tenants/bre/model/BreOrderContext';

export default abstract class BreJob<P extends BrePerformableConfig = BrePerformableConfig> extends TenantJob<
  BreOrderContext,
  P
> {
  isDelivery(): boolean {
    return false;
  }

  isPrint(): boolean {
    return false;
  }
}
