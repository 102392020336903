import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberOptional,
  coerceLiteralNumberRequired,
  FIRST_VERSION_TIMESTAMP,
} from '~/lib/zod';

export default createPerformableConfig(
  'cinematic',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      rush: coerceBoolean().optional(),
      narration: z.union([coerceLiteralNumberOptional(90), coerceLiteralNumberOptional(180)]),
    }),
  },
  {
    name: 'Cinematic',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
    images: [],
  },
);
