import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import Bre3dJob from '~/tenants/bre/performable/matterport/BreMatterportJob';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { ProviderStaticConfig } from '~/lib/model';
import { BreProviderId, BreProviderStatic } from '~/tenants/bre/model/BreProvider';

export const BRE_PROVIDER_DATA: Record<BreProviderId, BreProviderStatic & ProviderStaticConfig> = {
  [BreProviderId.JOSEPH_IRASUSTA]: {
    first: 'Joseph',
    last: 'Irasusta',
    color: '#3B5998',
    email: 'joseph.irasusta0416@gmail.com',
    headshot: 'avatar.jpg',
    phone: '+15159749848',
    schedule_start: '04:00',
    schedule_end: '22:00',
    location: 'sjc',
    start_address: {
      line1: '3900 Horner Street',
      city: 'Union City',
      state: 'CA',
      zip: '94587',
      long: -122.0793398,
      lat: 37.5944011,
    },
    performables: {
      photos: true,
      floorplan: true,
      keywe: true,
      social: true,
      cinematic: true,
      aerial: true,
    },
  },
  [BreProviderId.KWASI_ACHIAW]: {
    first: 'Kwasi',
    last: 'Achiaw',
    color: '#D4E13B',
    email: 'kwasi.bvtm@gmail.com',
    phone: '+15159749848',
    schedule_start: '06:00',
    schedule_end: '22:00',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '4680 Hamilton Avenue',
      city: 'San Jose',
      state: 'CA',
      zip: '95130',
      long: -121.9845007,
      lat: 37.2921493,
    },
    performables: {
      cinematic: true,
      photos: true,
      social: true,
      keywe: true,
      portraits: true,
      floorplan: true,
      aerial: true,
    },
  },
  [BreProviderId.NOEL_DASMARINAS]: {
    first: 'Noel',
    last: 'Dasmarinas',
    color: '#F8A7DE',
    phone: '+15159749848',
    email: 'noel.dasmarinas0416@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '4218 Angelina Lane',
      city: 'Stockton',
      state: 'CA',
      zip: '95212',
      long: -121.2648599,
      lat: 38.04748,
    },
    schedule_start: '06:00',
    schedule_end: '22:00',
    performables: {
      keywe: true,
      cinematic: true,
      floorplan: true,
      aerial: true,
      photos: true,
      social: true,
    },
  },
  [BreProviderId.ALICIA_GARCIA]: {
    first: 'Alicia',
    last: 'Garcia',
    color: '#CFB7E9',
    phone: '+15159749848',
    email: 'alicia.garcia0416@gmail.com',
    schedule_start: '08:00',
    schedule_end: '22:00',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '35415 Blackburn Drive',
      city: 'Newark',
      state: 'CA',
      zip: '94560',
      long: -122.042221,
      lat: 37.5514634,
    },
    performables: {
      floorplan: true,
      photos: true,
      social: true,
      keywe: true,
      portraits: true,
    },
  },
  [BreProviderId.DAVID_DINDACK]: {
    first: 'David',
    last: 'Dindack',
    color: '#268C49',
    phone: '+15159749848',
    email: 'davidbeyondremarketing@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '447 Golf Circle West',
      city: 'Manteca',
      state: 'CA',
      zip: '95337',
      long: -121.2368707,
      lat: 37.8039907,
    },
    schedule_start: '06:00',
    schedule_end: '22:00',
    performables: {
      cinematic: true,
      matterport: true,
      floorplan: true,
      photos: true,
      social: true,
      keywe: true,
      aerial: true,
    },
  },
  [BreProviderId.IVAN_MARTINEZ]: {
    first: 'Ivan',
    last: 'Martinez',
    color: '#FF0000',
    phone: '+15159749848',
    email: 'ivanbeyondremarketing@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '9383 Benbow Drive',
      city: 'Gilroy',
      state: 'CA',
      zip: '95020',
      long: -121.5989477,
      lat: 37.0294372,
    },
    schedule_start: '06:00',
    schedule_end: '21:00',
    performables: {
      floorplan: true,
      keywe: true,
      aerial: true,
      photos: true,
      social: true,
    },
  },
  [BreProviderId.EMILY_MCLAUGHRY]: {
    first: 'Emily',
    last: 'McLaughry',
    color: '#9B9B9B',
    phone: '+15159749848',
    email: 'emilybeyondremarketing@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '3828 Fenn Way',
      city: 'Santa Cruz',
      state: 'CA',
      zip: '95062',
      long: -121.9703506,
      lat: 36.9819741,
    },
    schedule_start: '06:00',
    schedule_end: '23:00',
    performables: {
      keywe: true,
      portraits: true,
      floorplan: true,
      aerial: true,
      photos: true,
      cinematic: true,
      social: true,
    },
  },
  [BreProviderId.LAUREN_DINDAK]: {
    first: 'Lauren',
    last: 'Dindak',
    color: '#FF0000',
    email: 'laurenbeyondremarketing@gmail.com',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '447 Golf Circle West',
      city: 'Manteca',
      state: 'CA',
      zip: '95337',
      long: -121.2368707,
      lat: 37.8039907,
    },
    schedule_start: '04:00',
    schedule_end: '22:00',
    performables: {
      keywe: true,
      floorplan: true,
      matterport: true,
    },
  },
  [BreProviderId.CHRIS_RICKETTS]: {
    first: 'Chris',
    last: 'Ricketts',
    color: '#A5C5EE',
    email: 'chris@beyondremarketing.com',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '362 Fantail Way',
      city: 'Gilroy',
      state: 'CA',
      zip: '95020',
      long: -121.5897586,
      lat: 37.0325349,
    },
    schedule_start: '09:00',
    schedule_end: '22:00',
    performables: {
      keywe: true,
      floorplan: true,
      photos: true,
      social: true,
      aerial: true,
    },
  },
  [BreProviderId.ZACH_CRAWFORD]: {
    first: 'Zach',
    last: 'Crawford',
    color: '#8F2C8F',
    phone: '+15159749848',
    email: 'designzach@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '15495 Los Gatos Boulevard',
      city: 'Los Gatos',
      state: 'CA',
      zip: '95032',
      long: -121.9607408,
      lat: 37.2442195,
    },
    schedule_start: '04:00',
    schedule_end: '22:00',
    performables: {
      keywe: true,
      portraits: true,
    },
  },
  [BreProviderId.GLENN_ROSE]: {
    first: 'Glenn',
    last: 'Rose',
    color: '#855433',
    email: 'glennrosephotography@outlook.com',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '3706 Cone Court',
      city: 'Rocklin',
      state: 'CA',
      zip: '95677',
      long: -121.240436,
      lat: 38.7775298,
    },
    schedule_start: '04:00',
    schedule_end: '22:00',
    performables: {
      matterport: true,
      photos: true,
      aerial: true,
    },
  },
  [BreProviderId.BILL_ROBINSON]: {
    first: 'Bill',
    last: 'Robinson',
    color: '#F08595',
    phone: '+15159749848',
    email: 'info@captureit3d.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '4233 Montgomery Street',
      city: 'Oakland',
      state: 'CA',
      zip: '94611',
      long: -122.2516793,
      lat: 37.8298778,
    },
    schedule_start: '04:00',
    schedule_end: '22:00',
    performables: {
      matterport: true,
    },
  },
};

export const TRAVEL_FEES = {
  10: [
    '95004',
    '94014',
    '94015',
    '94016',
    '94017',
    '95140',
    '94601',
    '94602',
    '94603',
    '94604',
    '94609',
    '94613',
    '94614',
    '94615',
    '94617',
    '94618',
    '94620',
    '94622',
    '94623',
    '94624',
    '94659',
    '94660',
    '94661',
    '94666',
  ],
  15: [
    '95023',
    '95024',
    '94080',
    '94083',
    '94518',
    '94519',
    '94520',
    '94521',
    '94522',
    '94523',
    '94524',
    '94525',
    '94527',
    '94529',
    '94595',
    '94596',
    '94597',
    '94598',
    '93901',
    '93905',
    '93906',
    '93907',
    '93915',
    '95012',
    '95045',
    '94556',
    '94563',
    '94570',
    '94575',
    '94608',
    '94662',
    '94701',
    '94702',
    '94703',
    '94704',
    '94705',
    '94706',
    '94707',
    '94708',
    '94709',
    '94710',
    '94712',
    '94720',
    '94528',
    '94516',
  ],
  20: [
    '94019',
    '94020',
    '94021',
    '94074',
    '95004',
    '95005',
    '95006',
    '95007',
    '95010',
    '95018',
    '95019',
    '95039',
    '95041',
    '95067',
    '95073',
    '95076',
    '95077',
    '94530',
    '94547',
    '94549',
    '94553',
    '94569',
    '94572',
    '94517',
    '94801',
    '94802',
    '94803',
    '94804',
    '94807',
    '94808',
    '94820',
    '94850',
  ],
  25: ['95017', '94018', '94038', '94060', '94074', '94920', '94966'],
  30: {
    condition: (context: BreOrderContext) =>
      context.jobs.some(
        (p) =>
          p instanceof BreCinematicJob ||
          p instanceof Bre3dJob ||
          (p instanceof BrePhotosJob && context.metadata.type === BreOrderType.COMMERCIAL),
      ),
    postals: [
      '94102',
      '94103',
      '94104',
      '94105',
      '94107',
      '94108',
      '94110',
      '94111',
      '94112',
      '94114',
      '94115',
      '94116',
      '94117',
      '94118',
      '94119',
      '94120',
      '94121',
      '94122',
      '94123',
      '94124',
      '94125',
      '94126',
      '94127',
      '94128',
      '94129',
      '94130',
      '94131',
      '94132',
      '94133',
      '94134',
      '94137',
      '94139',
      '94140',
      '94141',
      '94142',
      '94143',
      '94144',
      '94145',
      '94146',
      '94147',
      '94151',
      '94158',
      '94159',
      '94160',
      '94161',
      '94163',
      '94164',
      '94172',
      '94177',
      '94188',
      '95062',
      '95003',
      '94806',
      '95060',
      '95003',
      '95001',
      '95061',
      '95063',
      '95064',
      '95065',
      '95075',
      '94805',
      '94564',
      '95066',
      '95307',
      '94123',
      '95821',
    ],
  },
  35: ['94018', '94037', '94038', '94044', '94074', '95039'],
  40: [
    '94509',
    '94531',
    '94565',
    '95304',
    '95376',
    '95377',
    '95378',
    '95391',
    '94505',
    '94511',
    '94513',
    '94514',
    '94548',
    '94561',
    '94505',
    '93926',
  ],
  50: [
    '92943',
    '93921',
    '93922',
    '93923',
    '93933',
    '93940',
    '93942',
    '93943',
    '93944',
    '93950',
    '93953',
    '93955',
    '94920',
    '94965',
    '94966',
    '94590',
    '95833',
    '95628',
  ],
  100: ['94931', '94559'],
  125: ['95476'],
};

export const DELIVERY_FEES = {
  18.5: [95037, 95038, 94002, 94541, 94544, 94545, 94540, 94542, 94543, 94557, 94580, 94010, 94011, 94546, 94552].map(
    String,
  ),
  30: [94550, 94551, 95020, 95021, 94566, 94588, 94568, 94577, 94578, 94579, 94030].map(String),
  40: [
    94102,
    94103,
    94107,
    94108,
    94109,
    94105,
    94111,
    94080,
    94104,
    94110,
    94115,
    94117,
    94118,
    94123,
    94133,
    94112,
    94114,
    94116,
    94121,
    94122,
    94124,
    94127,
    94131,
    94132,
    94177,
    94120,
    94129,
    94134,
    94143,
    94128,
    94119,
    94125,
    94126,
    94130,
    94137,
    94140,
    94141,
    94142,
    94144,
    94145,
    94146,
    94147,
    94159,
    94163,
    94164,
    94188,
    94139,
    94151,
    94160,
    94161,
    94172,
    94083,
    94158,
    94501,
    94502,
    94538,
    94550,
    94566,
    94588,
    94607,
    94704,
    94546,
    94552,
    94560,
    94568,
    94536,
    94539,
    94541,
    94544,
    94545,
    94577,
    94608,
    94702,
    94703,
    94705,
    94706,
    94707,
    94708,
    94709,
    94710,
    94720,
    94537,
    94540,
    94542,
    94555,
    94578,
    94579,
    94587,
    94601,
    94602,
    94603,
    94605,
    94606,
    94609,
    94610,
    94611,
    94612,
    94618,
    94619,
    94621,
    94701,
    94580,
    94586,
    94620,
    94543,
    94557,
    94662,
    94712,
    94604,
    94613,
    94614,
    94615,
    94617,
    94623,
    94624,
    94649,
    94659,
    94660,
    94661,
    94666,
    94622,
    94607,
    94601,
    94602,
    94603,
    94605,
    94606,
    94609,
    94610,
    94611,
    94612,
    94618,
    94619,
    94621,
    94604,
    94613,
    94614,
    94615,
    94617,
    94623,
    94624,
    94649,
    94659,
    94660,
    94661,
    94666,
    94622,
    94506,
    94526,
    94044,
    94066,
    94583,
    94582,
    94080,
    94083,
    94014,
    94015,
    94016,
    94017,
  ].map(String),
  55: [
    95023,
    95024,
    94509,
    94531,
    95376,
    95378,
    95304,
    95377,
    95391,
    94518,
    94520,
    94519,
    94521,
    94522,
    94524,
    94527,
    94529,
    94596,
    94595,
    94597,
    94598,
    95076,
    95077,
    95060,
    95062,
    95065,
    95003,
    95006,
    95019,
    95073,
    95010,
    95066,
    95076,
    95064,
    95061,
    95063,
    95017,
    95001,
    95005,
    95007,
    95018,
    95041,
    95067,
    95077,
    94018,
    93218,
    94547,
  ].map(String),
  80: [94589, 94590, 94591, 94592].map(String),
  175: [95307, 95350, 95351, 95354, 95355, 95356, 95353, 95357, 95358, 95352, 95397, 93924, 93923, 93922, 93921].map(
    String,
  ),
  230: [
    95814,
    95815,
    95825,
    95843,
    95691,
    95608,
    95630,
    95816,
    95817,
    95818,
    95819,
    95820,
    95821,
    95822,
    95823,
    95826,
    95831,
    95833,
    95834,
    95841,
    95842,
    95610,
    95628,
    95670,
    95626,
    95624,
    95758,
    94203,
    94289,
    95812,
    95824,
    95827,
    95828,
    95829,
    95830,
    95832,
    95835,
    95837,
    95838,
    95864,
    95894,
    95605,
    95615,
    95638,
    95639,
    95655,
    95680,
    95693,
    95660,
    95662,
    95673,
    95632,
    95641,
    95690,
    95742,
    95621,
    95611,
    94204,
    94205,
    94206,
    94207,
    94208,
    94209,
    94211,
    94229,
    94230,
    94232,
    94234,
    94235,
    94236,
    94237,
    94239,
    94240,
    94244,
    94245,
    94247,
    94248,
    94249,
    94250,
    94252,
    94254,
    94256,
    94257,
    94258,
    94259,
    94261,
    94262,
    94263,
    94267,
    94268,
    94269,
    94271,
    94273,
    94274,
    94277,
    94278,
    94279,
    94280,
    94282,
    94283,
    94284,
    94285,
    94286,
    94287,
    94288,
    94290,
    94291,
    94293,
    94294,
    94295,
    94296,
    94297,
    94298,
    94299,
    95813,
    95836,
    95840,
    95851,
    95852,
    95853,
    95860,
    95865,
    95866,
    95867,
    95609,
    95652,
    95671,
    95683,
    95899,
    95798,
    95763,
    95741,
    95759,
    95799,
    95811,
    95757,
  ].map(String),
};
