import PicJob from '~/tenants/pic/model/PicJob';
import Pic3dConfig, { Pic3dScanOptionId } from '~/tenants/pic/performable/3d/Pic3dConfig';
import { addressToArea, addressToStreet, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PicOrderType } from '~/tenants/pic/model/PicOrder';
import {
  TenantSubscriptionMetadata,
  TenantSubscriptionStatus,
  TenantSubscriptionType,
} from '~/tenants/common/TenantPersona';
import { DateTime } from 'luxon';

interface Pic3dScanOptions {
  onsite: number; // TODO: need official numbers
  amount: number;
  from: number;
  to: number;
}

export const Pic_3D_OPTIONS: Record<Pic3dScanOptionId, Pic3dScanOptions> = {
  [Pic3dScanOptionId.ONE]: {
    onsite: 30,
    amount: 250,
    from: 0,
    to: 1000,
  },
  [Pic3dScanOptionId.TWO]: {
    onsite: 45,
    amount: 275,
    from: 1001,
    to: 2000,
  },
  [Pic3dScanOptionId.THREE]: {
    onsite: 60,
    amount: 285,
    from: 2001,
    to: 3000,
  },
  [Pic3dScanOptionId.FOUR]: {
    onsite: 60,
    amount: 300,
    from: 3001,
    to: 4000,
  },
  [Pic3dScanOptionId.FIVE]: {
    onsite: 90,
    amount: 350,
    from: 4001,
    to: 5000,
  },
  [Pic3dScanOptionId.SIX]: {
    onsite: 90,
    amount: 400,
    from: 5001,
    to: 6000,
  },
  [Pic3dScanOptionId.SEVEN]: {
    onsite: 120,
    amount: 450,
    from: 6001,
    to: 7000,
  },
  [Pic3dScanOptionId.EIGHT]: {
    onsite: 120,
    amount: 500,
    from: 7001,
    to: 8000,
  },
};

export default class Pic3dJob extends PicJob<typeof Pic3dConfig> {
  get performable() {
    return Pic3dConfig;
  }

  onsite(): number {
    return Pic_3D_OPTIONS[this.metadata.option].onsite;
  }

  defaultValue(): ZodVersionedMetadata<(typeof Pic3dConfig)['schema']> {
    if (this.order.metadata.type === PicOrderType.DRAFT) {
      return {
        option: Pic3dScanOptionId.ONE,
        version: schema_latest_version(Pic3dConfig.schema),
      };
    }

    let option: undefined | Pic3dScanOptionId;

    for (const [key, value] of Object.entries(Pic_3D_OPTIONS)) {
      if (this.order.metadata.sqft >= value.from && this.order.metadata.sqft <= value.to) {
        // TODO: better typing
        option = key as any as Pic3dScanOptionId;
        break;
      }
    }

    if (!option) {
      throw new Error('No option found for sqft range');
    }

    return {
      option,
      version: schema_latest_version(Pic3dConfig.schema),
    };
  }

  get configurable() {
    return false;
  }

  get hourly() {
    return this.order.metadata.type === PicOrderType.COMMERCIAL;
  }

  get multiple() {
    return this.order.metadata.type !== PicOrderType.COMMERCIAL;
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === PicOrderType.COMMERCIAL) {
      return [];
    }

    const option = Pic_3D_OPTIONS[this.metadata.option];

    return [
      {
        amount: new Big(option.amount),
        description: this.performable.name,
        discountable: true,
        id: this.performable.id,
      },
    ];
  }

  subscription(): TenantSubscriptionMetadata {
    return {
      type: TenantSubscriptionType.MATTERPORT,
      name: this.order.address
        ? `${addressToStreet(this.order.address)}, ${addressToArea(this.order.address)}`
        : 'Matterport',
      status: TenantSubscriptionStatus.ACTIVE,
      created: DateTime.now().toJSDate().toISOString(),
      order_id: this.order.id,
      job_id: this.id,
      months: 6,
      cost: 24,
      expires: DateTime.now().plus({ months: 6 }).toJSDate().toISOString(),
      renew: false,
      payments: {},
      url: 'https://example.com',
    };
  }
}
