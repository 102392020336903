import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum BrePhotosType {
  KEYWE = 'keywe',
  STANDARD = 'standard',
  FRONT = 'front',
  EXTERIOR = 'exterior',
}

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
        rush: coerceBoolean().optional(),
        notes: z.string().optional(),
        extra: coerceBoolean().optional(),
        twilight: coerceBoolean().optional(),
        poi: z.array(z.object({ name: z.string(), location: z.string() })).optional(),
      })
      .and(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(BrePhotosType.KEYWE),
          }),
          z.object({
            type: z.literal(BrePhotosType.STANDARD),
            photos: z.number(),
          }),
          z.object({
            type: z.literal(BrePhotosType.EXTERIOR),
          }),
          z.object({
            type: z.literal(BrePhotosType.FRONT),
          }),
        ]),
      ),
  },
  {
    name: 'Photography',
    images: [],
    short: '',
    tenant: TenantId.BEYOND_RE_MARKETING,
  },
);
