import React, { ReactNode, useEffect, useState } from 'react';
import { useZodFormFieldSingle, ZodNestedForm } from '~/components/zod/ZodForm';
import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { PackageFormNested, usePackageFormContext } from '~/components/performable/PackageForm';
import { TenantPackageInclude } from '~/tenants/common/package';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import Twt3dConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { twt_calculate_photo_images, TWT_PHOTO_TWILIGHT } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { TwtOrderContext, TwtOrderType } from '~/tenants/twt/model/TwtOrder';
import TwtFloorplanUpgrade from '~/tenants/twt/performable/floorplan/TwtFloorplanUpgrade';
import TwtPhotosAddOns from '~/tenants/twt/performable/photo/TwtPhotosAddOns';
import { PersonaType } from '~/tenants/common/TenantPersona';
import Heading from '~/components/layout/Heading';
import Sections, { Section } from '~/components/layout/Sections';
import PerformableGroupForm, {
  PerformableGroupNested,
  usePerformableGroup,
} from '~/components/performable/PerformableGroupForm';
import TwtTwilightConfig from '~/tenants/twt/performable/twilight/TwtTwilightConfig';
import InputCheckbox from '~/components/input/InputCheckbox';
import { Money } from '~/components/Money';
import { TWT_TWILIGHT_PRICE } from '~/tenants/twt/performable/twilight/TwtTwilightJob';
import { TWT_VIRTUAL_PRICE } from '~/tenants/twt/performable/virtual/TwtVirtualJob';
import TwtVirtualConfig from '~/tenants/twt/performable/virtual/TwtVirtualConfig';
import TwtVirtualForm, { TWT_VIRTUAL_SKYS } from '~/tenants/twt/performable/virtual/TwtVirtualForm';

function TwtPackageCommunityHidden(props: { enable: boolean }) {
  const [_name, value, setValue] = useZodFormFieldSingle('community');

  useEffect(() => {
    if (props.enable.toString() !== value) {
      setValue(props.enable.toString());
    }
  }, [props.enable]);

  return <ZodFieldHidden name="community" value={value} />;
}

export function TwtPackageCommunityAddOn(props: { aerial?: boolean }) {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6">
      <div className="rounded-lg overflow-hidden flex-shrink-0">
        <LegacyResponsiveImage src="card/public/community.jpg" width={200} />
      </div>
      <div className="space-y-4">
        {/*<PackageFormNested performable={TwtPhotographyConfig}>*/}
        {/*  <TwtPhotosCommunityCustom />*/}
        {/*  <TwtPhotosCommunityLicense />*/}
        {/*</PackageFormNested>*/}
        {/*{props.aerial && (*/}
        {/*  <PackageFormNested performable={TwtAerialConfig}>*/}
        {/*    <div>*/}
        {/*      <div className="flex items-center leading-none select-none">*/}
        {/*        <div className="w-8 flex-shrink-0">*/}
        {/*          <ZodFieldCheckbox id="aerial" name="community" />*/}
        {/*        </div>*/}
        {/*        <label className="text-base font-medium cursor-pointer w-full" htmlFor="aerial">*/}
        {/*          Aerial Community Photos*/}
        {/*        </label>*/}
        {/*        <p className="font-bold text-base">*/}
        {/*          <Money>60</Money>*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div className="pl-8 pt-2 max-sm:pr-4">*/}
        {/*        <p className="text-sm text-gray-600">*/}
        {/*          A high resolution example of directed shots that showcase your desired, local sites.*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </PackageFormNested>*/}
        {/*)}*/}
      </div>
    </div>
  );
}

export function TwtPackagePhotoInclude({ image = 'tenant/twt/residential/1.jpg' }: { image?: string }) {
  const context = usePackageFormContext<TwtOrderContext>();

  let photos = twt_calculate_photo_images(context.orderContext.metadata.sqft ?? 0);

  if ([TwtOrderType.LONG_TERM_RENTAL, TwtOrderType.SHORT_TERM_RENTAL].includes(context.orderContext.metadata.type)) {
    photos = 20;
  }

  return (
    <TenantPackageInclude name={`${photos} Photo Tour`} image={image}>
      {TwtPhotoConfig.short}
    </TenantPackageInclude>
  );
}

export function TwtPackageFloorplanInclude() {
  return (
    <TenantPackageInclude name="Standard 2D Floorplan" image="tenant/twt/floorplan/standard2.png">
      A fully colored floor plan with room names and dimensions
    </TenantPackageInclude>
  );
}

export function TwtPackage3dInclude() {
  return (
    <TenantPackageInclude name={Twt3dConfig.name} image="tenant/twt/matterport/1.jpg">
      {Twt3dConfig.short}
    </TenantPackageInclude>
  );
}

export function TwtPackageFloorplanAddOns() {
  return (
    <PackageFormNested performable={TwtFloorplanConfig}>
      <TwtFloorplanUpgrade persona={PersonaType.CUSTOMER} />
    </PackageFormNested>
  );
}

export function TwtPackagePhotoAddOns(props: { type: TwtOrderType }) {
  return (
    <PackageFormNested performable={TwtPhotoConfig}>
      <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />
      <TwtPhotosAddOns persona={PersonaType.CUSTOMER} type={TwtPhotoType.INTERIOR_EXTERIOR} order={props.type} />
    </PackageFormNested>
  );
}

function Daylight() {
  const group = usePerformableGroup();
  const [, twilight, setTwilight] = useZodFormFieldSingle('twilight');

  return (
    <Section>
      <div className="flex items-center space-x-2 !cursor-pointer">
        <InputCheckbox
          id="sunshine"
          checked={!twilight && !group.hasPerformable(TwtTwilightConfig) && !group.hasPerformable(TwtVirtualConfig)}
          onChange={() => {
            setTwilight(undefined);
            group.removePerformable(TwtTwilightConfig);
            group.removePerformable(TwtVirtualConfig);
          }}
        />
        <label className="font-semibold" htmlFor="sunshine">
          Daylight Shoot <span className="text-theme-faint">(Included)</span>
        </label>
      </div>
      <p className="text-sm">
        With one daytime visit to your property, we will capture both the interior and exterior of the home. In case of
        cloudy or rainy weather, we will apply blue sky replacement free of charge.
      </p>
    </Section>
  );
}

function Virtual() {
  const group = usePerformableGroup();
  const [, twilight, setTwilight] = useZodFormFieldSingle('twilight');

  return (
    <Section>
      <div className="flex items-center space-x-2 !cursor-pointer">
        <InputCheckbox
          id="virtual"
          checked={group.hasPerformable(TwtVirtualConfig)}
          onChange={(checked) => {
            if (checked) {
              setTwilight(undefined);
              group.addPerformable(TwtVirtualConfig, { photos: 1, sky: TWT_VIRTUAL_SKYS[0] });
              group.removePerformable(TwtTwilightConfig);
            } else {
              group.removePerformable(TwtVirtualConfig);
            }
          }}
        />
        <label className="font-semibold" htmlFor="virtual">
          Daylight / Virtual Twilight{' '}
          <span className="text-theme-faint">
            (<Money>{TWT_VIRTUAL_PRICE}</Money>/photo)
          </span>
        </label>
      </div>
      <p className="text-sm">
        In addition to the standard daylight shoot, we magically convert a normal day photo into a twilight image,
        complete with different sky, window and light effects and more.
      </p>
    </Section>
  );
}

function VirtualForm() {
  const group = usePerformableGroup<TwtOrderContext>();

  if (!group.hasPerformable(TwtVirtualConfig)) {
    return <></>;
  }

  return (
    <PerformableGroupForm performable={TwtVirtualConfig}>
      <TwtVirtualForm create persona={PersonaType.CUSTOMER} context={group.order} />
    </PerformableGroupForm>
  );
}

function Together() {
  const group = usePerformableGroup();
  const [, twilight, setTwilight] = useZodFormFieldSingle('twilight');

  return (
    <Section>
      <div className="flex items-center space-x-2 cursor-pointer">
        <InputCheckbox
          id="together"
          checked={Boolean(twilight)}
          onChange={(checked) => {
            group.removePerformable(TwtTwilightConfig);
            group.removePerformable(TwtVirtualConfig);

            setTwilight(checked ? 'true' : undefined);
          }}
        />
        <label className="font-semibold" htmlFor="together">
          Daylight/Twilight Shoot{' '}
          <span className="text-theme-faint">
            (+<Money>{TWT_PHOTO_TWILIGHT}</Money>)
          </span>
        </label>
      </div>
      <p className="text-sm">
        We will get to your property in time to get your daytime photos both inside and outside the home and then
        immediately after, we will capture the exteriors at sunset.
      </p>
      <ZodFieldHidden name="twilight" value={twilight} />
    </Section>
  );
}

function Separate() {
  const group = usePerformableGroup();
  const [, twilight, setTwilight] = useZodFormFieldSingle('twilight');

  return (
    <Section>
      <div className="flex items-center space-x-2 cursor-pointer">
        <InputCheckbox
          id="separate"
          checked={group.hasPerformable(TwtTwilightConfig)}
          onChange={(checked) => {
            if (checked) {
              setTwilight(undefined);
              group.removePerformable(TwtVirtualConfig);
              group.addPerformable(TwtTwilightConfig);
            } else {
              group.removePerformable(TwtTwilightConfig);
            }
          }}
        />
        <label className="font-semibold" htmlFor="separate">
          Separate Daylight and Twilight{' '}
          <span className="text-theme-faint">
            (+<Money>{TWT_TWILIGHT_PRICE}</Money>)
          </span>
        </label>
      </div>
      <p className="text-sm">
        During the day, we will capture your property's interior and exterior photos. Later that day (or on a following
        day of your choosing) we will return to the property at sunset to capture your property's exterior twilight
        images.
      </p>
    </Section>
  );
}

export function TwtPackageTimeOfDay() {
  const group = usePerformableGroup();

  return (
    <div className="border-theme-separator border-t pt-6 mt-6">
      <Sections>
        <Heading title="Type of Twilight" />

        <Sections>
          <PhotoMetadataForm>
            <Daylight />
          </PhotoMetadataForm>
          <PhotoMetadataForm>
            <Virtual />
          </PhotoMetadataForm>
          <VirtualForm />

          <PhotoMetadataForm>
            <Together />
          </PhotoMetadataForm>

          <PhotoMetadataForm>
            <Separate />
          </PhotoMetadataForm>

          {group.hasPerformable(TwtTwilightConfig) && <PerformableGroupForm performable={TwtTwilightConfig} />}
        </Sections>
      </Sections>
    </div>
  );
}

function PhotoMetadataForm(props: { children: ReactNode }) {
  return (
    <PerformableGroupNested performable={TwtPhotoConfig}>
      <ZodNestedForm name="metadata">{props.children}</ZodNestedForm>
    </PerformableGroupNested>
  );
}
