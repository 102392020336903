import { TenantPackageConfig } from '~/tenants/common/registry';
import MpxPhotosConfig from '~/tenants/mpx/performable/photos/MpxPhotosConfig';
import MpxMatterportConfig from '~/tenants/mpx/performable/matterport/MpxMatterportConfig';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';

const MpxMatterportBundleConfig: TenantPackageConfig = {
  id: 'matterport',
  name: 'Photo + Matterport 3D Bundle',
  performables: [MpxPhotosConfig, MpxMatterportConfig],
  eligible: (order) => {
    return order.metadata.type === MpxOrderType.RESIDENTIAL;
  },
  description: 'Save by bundling photos and matterport!',
};

export default MpxMatterportBundleConfig;
