import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberOptional,
  coerceLiteralNumberRequired,
  FIRST_VERSION_TIMESTAMP,
} from '~/lib/zod';
import z from 'zod';

/**
 * Must be 3:2 Ratio
 */

/**
 * Upload RAW images to dropbox with the login
 * zillow@metroplex360.com
 * p/w zillow@metroplex360
 *
 * Uploads must be COMPLETED by 8pm CST the same day of the shoot.
 *
 * In folder Metroplex360 - NPN(MTP) select the current month, then either add to or create a folder
 * with today's date (MMDDYYYY) then create a folder for EACH unit with RAW number of files expected
 * ie Zang Apts unit 245 RAW 54.
 *
 * DO NOT UPLOAD MORE PHOTOS  THAN REQUESTED, you must cull as needed first. This folder is  shared with our editing team and they charge per image.
 */

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
        video: z.coerce.number().optional(),
        photos: z.union([
          // residential
          coerceLiteralNumberOptional(4),
          coerceLiteralNumberOptional(20),

          // multi-family
          coerceLiteralNumberOptional(12),
          coerceLiteralNumberOptional(24),

          // builder
          coerceLiteralNumberOptional(10),

          coerceLiteralNumberOptional(34),
        ]),
        community: coerceBoolean().optional(),
        tour: coerceBoolean().optional(),
      })
      .refine((data) => data.video || data.photos, {
        message: 'You must select at least aerial video or photo.',
      }) as any,
  },
  {
    name: 'Aerial',
    short:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et nulla ornare, tempus erat non, consectetur nunc.',
    tenant: TenantId.METROPLEX_360,
    images: [],
  },
);
