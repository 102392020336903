import { TenantProvider } from '../../common/TenantPersona';
import Nep3dConfig from '~/tenants/nep/performable/3d/Nep3dConfig';
import NepAerialConfig from '~/tenants/nep/performable/aerial/NepAerialConfig';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import NepPhotographyConfig from '~/tenants/nep/performable/photos/NepPhotographyConfig';

export enum NepProviderId {
  SOPHIA = 1,
}

export default class NepProvider extends TenantProvider {
  get performableIds(): string[] {
    return [
      Nep3dConfig.id,
      NepAerialConfig.id,
      NepFloorplansConfig.id,
      NepPhotographyConfig.id,
    ];
  }
}
