import { Big } from 'big.js';
import DemoJob from '~/tenants/demo/model/DemoJob';
import DemoVirtualStagingConfig from '~/tenants/demo/performable/staging/DemoVirtualStagingConfig';
import { TenantOrderLine } from '~/lib/model';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { DemoOrderType } from '~/tenants/demo/model/DemoOrder';

export default class DemoVirtualStagingJob extends DemoJob<typeof DemoVirtualStagingConfig> {
  get performable() {
    return DemoVirtualStagingConfig;
  }

  defaultValue(): ZodVersionedMetadata<(typeof DemoVirtualStagingConfig)['schema']> {
    return {
      photos: 1,
      version: schema_latest_version(DemoVirtualStagingConfig.schema),
    };
  }

  get eligible() {
    return this.order.metadata.type !== DemoOrderType.COMMERCIAL;
  }

  revenueLines(): TenantOrderLine[] {
    let amount = new Big(0);

    for (let i = 1; i <= this.metadata.photos; i += 1) {
      if (i < 5) {
        amount = amount.plus('25');
      } else {
        amount = amount.plus('20');
      }
    }

    return [
      {
        amount,
        description: this.performable.name,
        id: this.performable.id,
      },
    ];
  }
}
