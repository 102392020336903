import { TenantOrder } from '../../common/TenantOrder';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import Nep3dJob from '~/tenants/nep/performable/3d/Nep3dJob';
import NepAerialJob from '~/tenants/nep/performable/aerial/NepAerialJob';
import NepFloorplansJob from '~/tenants/nep/performable/floorplans/NepFloorplansJob';
import NepPhotographyJob from '~/tenants/nep/performable/photos/NepPhotographyJob';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import Nep3dConfig from '~/tenants/nep/performable/3d/Nep3dConfig';
import NepAerialConfig from '~/tenants/nep/performable/aerial/NepAerialConfig';
import NepBrochuresConfig from '~/tenants/nep/performable/brochures/NepBrochuresConfig';
import NepEddmConfig from '~/tenants/nep/performable/eddm/NepEddmConfig';
import NepFloorplansConfig from '~/tenants/nep/performable/floorplans/NepFloorplansConfig';
import NepPhotographyConfig from '~/tenants/nep/performable/photos/NepPhotographyConfig';
import NepVirtualStagingConfig from '~/tenants/nep/performable/staging/NepVirtualStagingConfig';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { DEMO_COMMERCIAL_COST, DEMO_COMMERCIAL_HOURLY_RATE } from '~/tenants/nep/vars';

export enum NepOrderType {
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
}

const types = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(NepOrderType.RESIDENTIAL),
    multifamily: z.boolean().optional(),
  }),
  z.object({
    type: z.literal(NepOrderType.COMMERCIAL),
  }),
]);

export const NepOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      sqft: z.coerce
        .number({
          required_error: 'Please provide the square footage of the property or unit.',
          invalid_type_error: 'Please provide the square footage of the property or unit.',
        })
        .min(1, { message: 'The square footage of the property or unit must be greater than 1.' }),
      acres: z.coerce
        .number({
          required_error: 'Please provide the size (in acres) of the lot.',
          invalid_type_error: 'Please provide the size (in acres) of the lot.',
        })
        .min(0, { message: 'The size (in acres) of the lost must be greater than 0.' }),
    })
    .and(types),
};

export type NepOrderMetadata = ZodVersionedMetadata<typeof NepOrderSchema>;

export type NepPerformableConfig =
  | typeof Nep3dConfig
  | typeof NepAerialConfig
  | typeof NepBrochuresConfig
  | typeof NepEddmConfig
  | typeof NepFloorplansConfig
  | typeof NepPhotographyConfig
  | typeof NepVirtualStagingConfig;

export type NepOrderContext = TenantOrderContext<NepOrderMetadata, NepPerformableConfig>;

export class NepOrder extends TenantOrder<NepOrderContext> {
  info(): Array<DisplayData> {
    return [
      ...super.info(),
      {
        name: 'Type',
        value: this.context.metadata.type === NepOrderType.RESIDENTIAL ? 'Residential' : 'Commercial',
      },
      {
        name: 'Square Feet',
        value: this.context.metadata.sqft.toString(),
      },
      {
        name: 'Acres',
        value: this.context.metadata.acres.toString(),
      },
    ];
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.context.metadata.type === NepOrderType.RESIDENTIAL) {
      if (this.context.address?.distance) {
        if (this.context.address?.distance > 45) {
          lines.push({
            id: 'fuel',
            description: 'Flat Fuel Charge (>45 miles)',
            amount: new Big('25.00'),
          });
        } else {
          lines.push({
            id: 'fuel',
            description: 'Flat Fuel Charge (<=45 miles)',
            amount: new Big('15.00'),
          });
        }
      }

      const hasPhoto = this.jobs.find((p) => p instanceof NepPhotographyJob);
      const hasFloorplans = this.jobs.find((p) => p instanceof NepFloorplansJob);
      const has3d = this.jobs.find((p) => p instanceof Nep3dJob);
      const hasAerial = this.jobs.find((p) => p instanceof NepAerialJob);

      let packageDiscount = new Big(0);
      let packageTotal = new Big(0);

      if (hasPhoto && hasFloorplans) {
        packageTotal = hasPhoto.revenue(true).plus(hasFloorplans.revenue(true));

        if (has3d) {
          packageTotal = packageTotal.plus(has3d.revenue(true));

          if (hasAerial) {
            packageTotal = packageTotal.plus(hasAerial.revenue(true));

            packageDiscount = new Big('0.10');
          } else {
            packageDiscount = new Big('0.07');
          }
        } else {
          packageDiscount = new Big('0.05');
        }
      }

      if (packageDiscount.gt(0)) {
        lines.push({
          id: 'package',
          description: 'Package Discount',
          discount: true,
          amount: packageDiscount.times(packageTotal).times(-1),
        });
      }
    } else if (this.context.metadata.type === NepOrderType.COMMERCIAL) {
      lines.push({
        id: 'minimum',
        description: '1 Hour Minimum',
        amount: new Big(DEMO_COMMERCIAL_HOURLY_RATE),
      });

      const onsite = this.jobs.reduce((sum, job) => sum + job.onsite(), 0);
      const additional = onsite - 60;

      if (additional > 0) {
        lines.push({
          id: 'additional',
          description: 'Additional Time',
          amount: new Big(DEMO_COMMERCIAL_COST(additional)),
        });
      }
    }

    return lines;
  }
}
